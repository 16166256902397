import {MedicalHistory} from '$api/patientList/clinicalDetails';
import {QuestionnaireType} from '$api/patientList/questionnaire';
import FetchError from '$components/FetchError';
import MedicalHistoryCard from './MedicalHistoryCard';
import MedicationHistorySkeleton from './MedicationHistorySkeleton';
import './styles.scss';

interface MedicationHistoryComponentProps {
  isMedicalHistoryLoading: boolean;
  isMedicalHistoryFetchError: boolean;
  medicalHistory: MedicalHistory[];
  onEditIconClick: (questionnaireType: QuestionnaireType) => void;
  onDownloadClick: (questionnaireType: string) => Promise<void>;
}

function MedicationHistoryComponent(props: MedicationHistoryComponentProps) {
  const {
    isMedicalHistoryLoading,
    isMedicalHistoryFetchError,
    medicalHistory,
    onEditIconClick,
    onDownloadClick,
  } = props;

  if (isMedicalHistoryLoading) {
    return <MedicationHistorySkeleton />;
  }

  if (isMedicalHistoryFetchError) {
    return <FetchError />;
  }

  return (
    <div className="medical_history-container">
      {medicalHistory && medicalHistory.length > 0 ? (
        medicalHistory.map(item => {
          const isMedicationCompleted = !!item.completedOn;
          return (
            <MedicalHistoryCard
              key={item.key}
              isMedicationCompleted={isMedicationCompleted}
              medicalHistory={item}
              onEditIconClick={onEditIconClick}
              onDownloadClick={onDownloadClick}
            />
          );
        })
      ) : (
        <div className="medical_history-fallback-text">No results found.</div>
      )}
    </div>
  );
}

export default MedicationHistoryComponent;
