import {Button} from '@mui/material';

function NotificationLoading() {
  return (
    <Button
      className="animate-pulse w-full justify-start normal-case text-left flex gap-4 py-3.5 border-bottom"
      disabled
    >
      <div className="flex items-start justify-center">
        <div className="p-2 bg-slate-200 rounded-lg flex">
          <div className="h-6 w-6" />
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <div className="h-3.5 w-40 rounded-lg bg-slate-200 font-bold primary" />
        <div className="h-3.5 w-1/2 rounded-lg bg-slate-200 font-normal text-primary" />
        <div className="h-3 w-20 rounded-lg bg-slate-200 text-grey-dark font-normal" />
      </div>
    </Button>
  );
}

export default NotificationLoading;
