import {useId, useState} from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import FileUploadIcon from '$assets/svgs/file-upload.svg?react';
import styles from './styles';
import {useDropzone} from 'react-dropzone';
import {
  ACCEPTED_FILES,
  MAX_FILE_SIZE,
  MAX_FILES,
} from '$modules/booking/constant';
import {FileSizeValidationMessage} from '$constants/validationMessage';

interface FileUploadProps {
  name?: string;
  value?: File;
  onBlur: React.FocusEventHandler<HTMLElement>;
  onChange: (file: File | null) => void;
}

function FileUpload(props: FileUploadProps) {
  const {name, value, onBlur, onChange} = props;
  const [error, setError] = useState<string | null>(null);

  const {getInputProps} = useDropzone({
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    accept: ACCEPTED_FILES,
    noDrag: true,
    onDrop(acceptedFiles, fileRejections) {
      if (fileRejections.length) {
        setError(FileSizeValidationMessage.FileSizeExceed);
        onChange(null);
      } else {
        setError(null);
        onChange(acceptedFiles[0]);
      }
    },
  });

  const id = useId();

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Button
        component="label"
        htmlFor={id}
        startIcon={<FileUploadIcon />}
        variant="outlined"
        sx={styles.button}
      >
        upload file
        <Input
          inputProps={getInputProps()}
          id={id}
          name={name}
          sx={styles.input}
          type="file"
          onBlur={onBlur}
        />
      </Button>
      <Typography variant="caption">
        {value?.name || 'PNG, JPG, PDF (Max 5MB)'}
      </Typography>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
}

export default FileUpload;
