import {Button, IconButton} from '@mui/material';
// import FolderIcon from '$assets/svgs/folder.svg?react';
import LeftArrowIcon from '$assets/svgs/chevron-left-arrow.svg?react';
import SearchRefractionIcon from '$assets/svgs/search-refraction.svg?react';
import RigthArrowIcon from '$assets/svgs/chevron-right-arrow.svg?react';
// import FrontdeskButton from '$components/FrontdeskButton';
import FrontdeskSearchbox from '$components/FrontdeskSearchBox';
import PatientListTable from './PatientListTable';
import {
  LastAppointment,
  PATIENT_LIST_PAGE_SIZE,
  PatientListOrderBy,
  PatientStatus,
} from './constant';
import {PatientInfo} from './types';
import '../styles.scss';
import {ArrowLeftIcon} from '$assets/svgs';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '../../../../root/routesConstants';

interface PatientListComponentProps {
  isLoading: boolean;
  isError: boolean;
  message: string;
  archivedOnly: boolean;
  searchText: string;
  currentPage: number;
  patients: PatientInfo[];
  totalPages: number;
  totalRecords: number;
  sortActiveColumn?: string;
  sortDirection?: boolean;
  patientStatus: PatientStatus[];
  lastAppointment?: LastAppointment;
  setPatientStatus: (value: PatientStatus[]) => void;
  setLastAppointment: (value?: LastAppointment) => void;
  setSortActiveColumn: (value?: PatientListOrderBy) => void;
  setSortDirection: React.Dispatch<React.SetStateAction<boolean>>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onSearchTextChange: (value: string) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

function PatientListComponent(props: PatientListComponentProps) {
  const {
    isLoading,
    isError,
    message,
    archivedOnly,
    searchText,
    currentPage,
    patients,
    totalPages,
    totalRecords,
    sortActiveColumn,
    sortDirection,
    patientStatus,
    lastAppointment,
    setPatientStatus,
    setLastAppointment,
    setSortActiveColumn,
    setSortDirection,
    onNextPage,
    onPreviousPage,
    onSearchTextChange,
    setCurrentPage,
  } = props;

  const navigate = useNavigate();

  const currentAdjustedPage = totalPages === 0 ? 0 : currentPage;
  const currentPageString = currentAdjustedPage.toString().padStart(2, '0');
  const totalPagesString = totalPages.toString().padStart(2, '0');

  const currentRangeStart =
    (currentPage - 1) * PATIENT_LIST_PAGE_SIZE + (patients.length ? 1 : 0);
  const currentRangeEnd =
    currentRangeStart + patients.length - (patients.length ? 1 : 0);

  const summary = `Displaying ${currentRangeStart} - ${currentRangeEnd} of ${totalRecords} Patients`;

  return (
    <div className="patient-list-container flex flex-col flex-1 p-10">
      {archivedOnly && (
        <>
          <div className="flex items-center">
            <IconButton
              onClick={() => navigate(RoutePath.FrontDeskPatientList)}
            >
              <ArrowLeftIcon />
            </IconButton>
            <span className="primary-dark font-normal">
              Back to Patient list
            </span>
          </div>
          <hr className="border border-solid border-color mt-6 mb-4" />
        </>
      )}
      <div className="flex justify-between mb-9">
        <h1 className="text-2xl font-semibold primary-dark">
          Patient {archivedOnly ? 'archive' : 'List'}
        </h1>
        {!archivedOnly && (
          <div className="flex gap-4 items-center">
            {/* <FrontdeskButton
              title="Archive"
              startIcon={<FolderIcon />}
              onClick={() => navigate(RoutePath.FrontDeskPatientListArchived)}
            /> */}
            <FrontdeskSearchbox
              value={searchText}
              startIcon={<SearchRefractionIcon />}
              onChange={event => {
                setCurrentPage(1);
                onSearchTextChange(event);
              }}
              placeholder="Search by name"
            />
          </div>
        )}
      </div>
      <PatientListTable
        isLoading={isLoading}
        isError={isError}
        message={message}
        archivedOnly={archivedOnly}
        patients={patients}
        sortActiveColumn={sortActiveColumn}
        sortDirection={sortDirection}
        patientStatus={patientStatus}
        lastAppointment={lastAppointment}
        setPatientStatus={setPatientStatus}
        setLastAppointment={setLastAppointment}
        setSortActiveColumn={setSortActiveColumn}
        setSortDirection={setSortDirection}
      />
      <div className="flex justify-between mt-10">
        <div className="pagination-container flex">
          <Button
            className="p-1 px-2 flex items-center page-change-button"
            onClick={onPreviousPage}
          >
            <LeftArrowIcon />
          </Button>
          <div className="p-1 px-6 flex items-center border-left border-solid border-x border-y-0 border-color">
            <span>
              {currentPageString} / {totalPagesString}
            </span>
          </div>
          <Button
            className="p-1 px-2 flex items-center page-change-button"
            onClick={onNextPage}
          >
            <RigthArrowIcon />
          </Button>
        </div>
        <div>
          <span className="text-sm tertiary">{summary}</span>
        </div>
      </div>
    </div>
  );
}

export default PatientListComponent;
