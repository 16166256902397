import toast from '$utils/toast';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  CancelAppointmentResponseType,
  PatchCancelAppointmentPayloadType,
  patchCancelAppointment,
} from '$api/frontdesk';
import {ToastType} from '$components/Toast/constant';
import {networkErrorToast, technicalGlitchToast} from '$constants/messages';
import queryClient from '$lib/queryClient';

export function useCancelAppointmentHook(
  setCancelAppointmentModal: React.Dispatch<React.SetStateAction<boolean>>
) {
  const {isPending, mutate} = useMutation({
    mutationKey: ['cancel-appointment'],
    mutationFn: patchCancelAppointment,
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ['appointment-detail'],
      });
      return toast(ToastType.Success, 'Cancel appointment', data.message);
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<CancelAppointmentResponseType>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(ToastType.Error, 'Cancel appointment', data.message);
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => setCancelAppointmentModal(prevState => !prevState),
  });

  const sendCancelAppointment = (
    payload: PatchCancelAppointmentPayloadType
  ) => {
    mutate(payload);
  };

  return {
    isPending,
    sendCancelAppointment,
  };
}
