import {type CSSProperties} from 'react';
import {type SxProps} from '@mui/material';
import choicesSectionImage from '$assets/gifs/confirm_your_choices.gif';
import theme from '$modules/booking/theme';
import {scrollbarStyles} from '$styles/index';
import {BookingScreenColors} from './constant';
import {Color} from '$constants/style';

const styles: Record<string, SxProps> = {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(7, 0, 3),
    background: BookingScreenColors.WrapperBackground,

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7, 0),
    },
  },
  sectionWrapper: {
    flex: 1,
    display: 'flex',
    marginTop: theme.spacing(0),

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',

      ...scrollbarStyles,
    },
  },
  sectionWrapperWithStepper: {
    flex: 1,
    display: 'flex',
    marginTop: theme.spacing(5),

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      marginTop: '-4.5rem',

      ...scrollbarStyles,
    },
  },
  section: {
    flex: 1,
    padding: theme.spacing(0, 3),
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      padding: '0 13.5rem',
      overflow: 'hidden',
    },
  },
  scrollSection: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: '50vh',
    },
  },
  title: {
    color: theme.palette.text.tertiary,
    paddingBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.up('lg')]: {
      fontSize: '2.5rem',
    },
  },
  subTitle: {
    color: theme.palette.text.tertiary,
    letterSpacing: '-0.75px',
    padding: theme.spacing(0),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 7),
    },
  },
  subHeading: {
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
  },
  subTitleSeeAll: {
    position: 'relative',
    cursor: 'pointer',
    background: BookingScreenColors.SeeAllGradient,
    letterSpacing: '-0.75px',
    padding: theme.spacing(0),
    textAlign: 'center',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '&::after': {
      position: 'absolute',
      background: BookingScreenColors.SeeAllGradient,
      content: '""',
      height: '1px',
      width: '50px',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '0',
    },

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 7),
    },
  },
  stepsTypeWrap: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      gridGap: theme.spacing(14),
    },
  },

  stepsTypeHead: {
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: '-0.85px',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    gridGap: theme.spacing(6),

    [theme.breakpoints.up('lg')]: {
      padding: '0 13.5rem',
      gridGap: theme.spacing(3),
    },
  },
};

export const registerWithStoneyGateViewStyles: Record<string, SxProps> = {
  radioContainer: {
    justifyContent: 'flex-start',
    maxWidth: '24rem',
  },
  mutedText: {
    color: theme.palette.text.disabled,
    maxWidth: '24rem',
  },
  circleIcon: {
    borderRadius: '50%',
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    right: 1,
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  inputContainer: {
    maxWidth: '24rem',
  },
  otpBox: {
    width: '50px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
  },
  otpInfoText: {
    mb: '20px',
  },
  verifyBtn: {
    width: '170px',
    marginTop: '1rem',
  },
  sendAgainBtn: {
    cursor: 'pointer',
  },
  resetBtn: {
    marginRight: '10px',
  },
  endIcon: {
    color: 'black',
    cursor: 'pointer',
  },
  forgotPasswordText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '0.90rem',
    marginTop: theme.spacing(1),
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export const careSelectionViewStyles: Record<string, SxProps> = {
  menuItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  menuItemTitle: {
    color: BookingScreenColors.MenuItemTitle,
    fontWeight: 400,
    fontSize: '16px',
  },

  menuItemCaption: {
    textWrap: 'wrap',
  },
};

export const consultantSelectionViewStyles: Record<string, CSSProperties> = {
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  menuItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
    overflowX: 'auto',
  },
  menuItemProfileImage: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
  },
  menuItemInitial: {
    backgroundColor: BookingScreenColors.MenuItemBackground,
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemBody: {
    flex: 1,
    display: 'flex',
    gap: '8px',
  },
  menuItemDetails: {
    flex: 1,
  },
  menuItemBioContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemBioText: {
    color: Color.Primary2Darkest,
    fontWeight: 300,
    textDecoration: 'none',
  },
  fontThin: {
    fontWeight: 300,
    textWrap: 'wrap',
  },
};

export const bookingSelectionViewStyles: Record<string, SxProps> = {
  section: {
    flex: 1,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      maxHeight: '80vh',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 13.5rem',
      overflowX: 'hidden',
      overflowY: 'auto',

      ...scrollbarStyles,
    },
  },
};

export const choicesConfirmationViewStyles: Record<string, SxProps> = {
  screenSection: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },
  bookingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    background: 'linear-gradient(118.28deg, #FFF1D5 1.91%, #F3F3FF 77.03%)',
    padding: theme.spacing(7.5, 2.5),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      gridGap: '80px',
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },

    [theme.breakpoints.up('xl')]: {
      gridGap: '160px',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& .site-logo': {
      width: '52px',
      height: '100%',
    },

    '& .hosptial-logo': {
      width: '178px',
      height: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },

  hospitalLogoWrap: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(5),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },
  imageSection: {
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'block',
      maxWidth: '430px',
      width: '430px',
      backgroundImage: `url(${choicesSectionImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
};

export const transitionScreenStyles: Record<string, SxProps> = {
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: BookingScreenColors.WrapperBackground,
  },
  container: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  gradientLayer: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',

    paddingLeft: '2rem',
    paddingTop: '1.5rem',
    paddingRight: '2rem',
    paddingBottom: '1.5rem',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8rem',
      paddingTop: '3.5rem',
      paddingRight: '8rem',
      paddingBottom: '3.5rem',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 700,

    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
  },
  description: {
    fontSize: '1rem',
    fontWeight: 400,

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.75rem',
    },
  },
  image: {
    height: '100vh',
    width: '100vw',
  },
};

export const patientCarerDetailsViewStyles: Record<string, SxProps> = {
  subTitle: {
    color: theme.palette.text.primary,
  },
};

export const AppSummaryViewStyles: Record<string, SxProps> = {
  screenSection: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  bookingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    background: 'linear-gradient(118.28deg, #FFF1D5 1.91%, #F3F3FF 77.03%)',
    padding: theme.spacing(7.5, 2.5),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  appSummaryWrap: {
    display: 'flex',
    gridGap: theme.spacing(5),
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(1),
      overflowX: 'hidden',
      overflowY: 'auto',
      ...scrollbarStyles,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& .site-logo': {
      width: '52px',
      height: '100%',
    },

    '& .hosptial-logo': {
      width: '178px',
      height: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },

  appSummarySection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridGap: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(6),
      ...scrollbarStyles,
    },
  },

  summaryActionBtnWrap: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },

  summaryHead: {
    color: theme.palette.primary.main,
    fontSize: '2rem',

    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem',
    },
  },

  summaryTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },

  bookingActionBtn: {
    textTransform: 'none',

    '&.delete-button': {
      '& svg': {
        color: theme.palette.error.main,
      },
    },
  },
  appSummaryVideoSection: {
    display: 'none',
    position: 'relative',
    overflow: 'hidden',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: '39rem',
      maxWidth: '39rem',
    },
  },
  hospitalLogoWrap: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    boxShadow: '0rem 0.25rem 1rem rgba(0, 0, 0, 0.1)',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    maxWidth: '37.5rem',
    height: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '1.6rem',
    color: '#333333',
    padding: '0.1875rem 0.9375rem',
  },
  modalPurpleTitle: {
    fontWeight: 600,
    fontSize: '1.6rem',
    color: Color.Purple600,
    padding: '0.1875rem 0.9375rem',
  },
  modalDescription: {
    fontSize: '1rem',
    padding: '0 1.1875rem',
    marginBottom: '2.8125rem',
    color: '#666666',
  },
  modalBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 'auto',
    marginBottom: '0.375rem',
    '& button': {
      marginLeft: '1.25rem',
      minWidth: '8.75rem',
      borderRadius: '0.5rem',
      fontSize: '1.1rem',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    color: '#666666',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  cancelBtn: {
    textTransform: 'none',
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  deleteBtn: {
    textTransform: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  bookingModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  bookingModalWrapper: {
    backgroundColor: Color.White,
    boxShadow: '0rem 0.25rem 1rem rgba(0, 0, 0, 0.1)',
    padding: '1.5rem',
    width: '100%',
    maxWidth: '42rem',
    borderRadius: '0.5rem',
    '&:focus': {
      outline: 'none',
    },
  },

  bookingModalTitle: {
    fontWeight: 600,
    fontSize: '1.6rem',
    color: Color.Purple600,
    marginBottom: '0.75rem',
  },

  bookingModalDescription: {
    fontSize: '1.125rem',
    color: Color.TextPrimary,
  },

  bookingModalBoldDescription: {
    fontWeight: 600,
  },

  bookingModalActionButtonContainer: {
    display: 'flex',
    gap: '1.5rem',
    marginTop: '2.25rem',
  },

  bookingModalActionButtonSecondary: {
    backgroundColor: Color.Grey200,
  },
};

export const patientContactDetailViewStyles: Record<string, SxProps> = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(0, 3),
    gridGap: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(6),
      padding: '0 13.5rem',
    },
    ...scrollbarStyles,
  },
  inputContainer: {
    maxWidth: '24rem',
  },
};

export const patientInsuranceDetailViewStyles: Record<string, SxProps> = {
  inputContainer: {
    maxWidth: '24rem',
  },
  paymentCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.White,
    padding: '0.5rem 0.75rem',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: '#d9d9d9',
  },
};

export const lastStepViewStyles: Record<string, SxProps> = {
  inputContainer: {
    maxWidth: '24rem',
  },
};

export default styles;
