export const enum ValidationMessage {
  EmailInvalid = 'Please enter a valid email address',
  EmailExceedCharacterLimit = 'Email address must not exceed 350 characters',
  EmailRequired = 'Email address is required',
  EmailMisMatch = 'The email and confirm email does not match',
  PhoneNumberRequired = 'Phone number is required',
  PhoneNumberInvalid = 'Please enter a valid phone number',
  PreferredContactMethodRequired = 'Preferred contact method is required',
  MembershipRequired = 'Membership number is required',
  MembershipInvalid = 'Please enter a valid membership number',
  SelectOption = 'Please select one of the options',
  PaymentModeRequried = 'Payment mode is required',
  InsuranceRequired = 'Insurance is required',
}

export enum MedicationValidationMessage {
  NameRequired = 'Name is a required field',
  DosageRequired = 'Dosage is a required field',
  FrequencyRequired = 'Frequency is a required field',
  InstructionRequired = 'Instruction is a required field',
}

export enum FileSizeValidationMessage {
  FileSizeExceed = 'File size should not exceed 5MB',
}
