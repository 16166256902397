import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Loading from '$components/Loading';
import {invalidLinkToast} from '$constants/messages';
import ResetPasswordComponent from './component';
import {useResetPassword, useValidateResetPasswordToken} from './hooks';
import {usePatientResetPassword} from '$modules/booking/hooks';
import {ResetPasswordFormData, resetPasswordSchema} from './schema';
import {RoutePath} from '$constants/routes';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const role = searchParams.get('role');

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {sendValidateResetPasswordToken, isValidateResetPasswordTokenPending} =
    useValidateResetPasswordToken();

  const {disableSubmit, isResetPasswordPending, sendResetPasswordRequest} =
    useResetPassword();

  const {
    disableSubmit: disablePatientSubmit,
    isPending: isPatientResetPasswordPending,
    sendPatientResetPasswordRequest,
  } = usePatientResetPassword();
  const handleSubmit = (values: ResetPasswordFormData) => {
    if (!token) return;
    const payload = {
      token,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    if (role === 'patient') {
      sendPatientResetPasswordRequest(payload);
    } else {
      sendResetPasswordRequest(payload);
    }
  };

  const formik = useFormik<ResetPasswordFormData>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (token) {
      // sendValidateResetPasswordToken({token});
    } else {
      invalidLinkToast();
      navigate(RoutePath.loginRoute);
    }
  }, [sendValidateResetPasswordToken, navigate, token]);

  if (isValidateResetPasswordTokenPending) {
    return <Loading />;
  }

  return (
    <ResetPasswordComponent
      disableSubmit={disableSubmit || disablePatientSubmit}
      isResetPasswordPending={
        isResetPasswordPending || isPatientResetPasswordPending
      }
      formValues={formik.values}
      formErrors={formik.errors}
      formTouched={formik.touched}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      handleSubmit={formik.handleSubmit}
      showPassword={showPassword}
      showConfirmPassword={showConfirmPassword}
      setShowPassword={setShowPassword}
      setShowConfirmPassword={setShowConfirmPassword}
    />
  );
}

export default ResetPassword;
