import React from 'react';
import {Button, Divider} from '@mui/material';
import {ArrowLeftIcon} from '$assets/svgs';
import './styles.scss';

interface QuestionnaireHeaderProps {
  handleBack: () => void;
}

const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = ({
  handleBack,
}) => {
  return (
    <header className="questionnairepage-details__header">
      <div className="questionnairepage-details__header__navigation">
        <Button
          startIcon={<ArrowLeftIcon />}
          className="questionnairepage-details__button--back"
          onClick={handleBack}
        >
          Back to clinical data
        </Button>
        <Divider className="mt-3 mx-10" />
      </div>
    </header>
  );
};

export default QuestionnaireHeader;
