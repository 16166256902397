import {MedicationValidationMessage} from '$constants/validationMessage';
import {object, string, InferType} from 'yup';

const medicationSchema = object({
  name: string().required(MedicationValidationMessage.NameRequired),
  dosage: string().required(MedicationValidationMessage.DosageRequired),
  frequency: string().required(MedicationValidationMessage.FrequencyRequired),
  instruction: string(),
});

export type MedicationFormData = InferType<typeof medicationSchema>;
export default medicationSchema;
