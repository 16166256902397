import ClockRewindIcon from '$assets/svgs/clock-rewind.svg?react';
import SunriseIcon from '$assets/svgs/sunrise.svg?react';
import SunsetIcon from '$assets/svgs/sunset.svg?react';
import MoonStarIcon from '$assets/svgs/moon-star.svg?react';
import {BookingPreferredTime, Consultant} from './types';

export const BOOKING_PREFERRED_TIMES: {
  title: string;
  id: BookingPreferredTime;
  icon: React.ReactNode;
}[] = [
  {
    id: BookingPreferredTime.All,
    title: 'All times are fine',
    icon: <ClockRewindIcon />,
  },
  {
    id: BookingPreferredTime.Morning,
    title: '8:00 AM - 12:00 PM',
    icon: <SunriseIcon />,
  },
  {
    id: BookingPreferredTime.Afternoon,
    title: '01:00 PM - 05:30 PM',
    icon: <SunsetIcon />,
  },
  {
    id: BookingPreferredTime.Evening,
    title: '06:00 PM - 07:30 PM',
    icon: <MoonStarIcon />,
  },
];

export const enum BookingScreenColors {
  WrapperBackground = 'linear-gradient(118.28deg, #FFF1D5 1.91%, #F3F3FF 77.03%)',
  SeeAllGradient = 'linear-gradient(180deg, #6100FF 0%, #FE7235 100%)',

  StepperBottomBarGradient = 'linear-gradient(90deg, #7625DC 0%, #B2B4FF 343.21%)',

  MenuItemTitle = '#344054',
  MenuItemBackground = '#D4D7DE',
}

export const TITLE_ITEMS = [
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Mx',
  'Ind',
  'M',
  'Misc',
  'Dr',
  'Prof',
  'Rev',
  'Hon',
];

export const GENDER_ITEMS = [
  'Male',
  'Female',
  'Cisgender (Cis)',
  'Transgender (Trans)',
  'Non-binary',
  'Genderqueer',
  'Genderfluid',
  'Agender',
  'Bigender',
];

export const NO_PREFERENCE_CONSULTANT: Consultant = {
  id: NaN,
  fullName: "I don't have a preferred consultant",
  profileUrl: '',
  doctorDetails: null,
};

export const PATIENT_CONTACT_DETAIL_CHECKBOX_LABEL =
  'I consent to my Personal Data being used for information to be sent to me which is relevant to me and/or my treatment. This may include updates on new clinical products, services or patients events regarding SEH. For further information on how your data is used, Read our Privacy Policy';

export const MAX_FILES = 1;
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const ACCEPTED_FILES = {
  'image/*': ['.jpg', '.jpeg', '.png'],
  'application/pdf': ['.pdf'],
};
