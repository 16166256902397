import {Navigate, createBrowserRouter} from 'react-router-dom';
import {RoutePath} from '$constants/routes';
import IndexRoute from '$modules/index';
import LoginRoute from '$modules/login';
import ResetPasswordRoute from '$modules/login/ResetPassword';
import AdminRoute from '$modules/admin';
import ProtectedRoute from '$components/ProtectedRoute';
import {UserRoleName} from '$modules/admin/constant';
import StaffCalendarRoute from '$modules/frontdesk/Calendar/CalendarView';
import StaffLayout from '$modules/frontdesk/layout';
import AppointmentsContainer from '$modules/frontdesk/Calendar/Appointments';
import PendingContainer from '$modules/frontdesk/Calendar/PendingRequests';
import BookingDetailsContainer from '$modules/frontdesk/Calendar/BookingDetails';
import ConfirmBooking from '$modules/frontdesk/Calendar/ConfirmBooking';
import RescheduleBooking from '$modules/frontdesk/Calendar/RescheduleBooking';
import BookingScreen from '$modules/booking';
import PatientListView from '$modules/frontdesk/PatientList/PatientListView';
import WelcomeScreen from '$modules/patient/invite/WelcomeScreen';
import PatientPlatformLayout from '$modules/patient/layout';
import PatientRegister from '$modules/patient/invite/Register';
import PatientLogin from '$modules/patient/invite/Login';
import OTPVerfication from '$modules/patient/invite/OTPVerfication';
import CreatePassword from '$modules/patient/invite/CreatePassword';
import PatientOnboardingWelcome from '$modules/patient/onboarding/Welcome';
import ForgotPassword from '$modules/patient/invite/ForgotPassword';
import EditBookingContainer from '$modules/frontdesk/Calendar/EditBooking';
import PatientDetailNotes from '$modules/frontdesk/PatientList/PatientDetails/Notes';
import PatientDetailsContainer from '$modules/frontdesk/PatientList/PatientDetails';
import Questionnaire from '$modules/frontdesk/PatientList/PatientDetails/ClinicalData/MedicationHistory/Questionnaire';
import Notifications from '$modules/frontdesk/Notifications';
import DeleteAppointmentRoute from '$modules/booking/DeleteAppointment';
import AppointmentDeleteSuccessRoute from '$modules/booking/DeleteAppointment/DeleteSuccess';

export const router = createBrowserRouter([
  {
    path: RoutePath.indexRoute,
    element: <IndexRoute />,
  },
  {
    path: RoutePath.bookingRoute,
    element: <BookingScreen />,
  },
  {
    path: RoutePath.loginRoute,
    element: <LoginRoute />,
  },
  {
    path: RoutePath.resetPasswordRoute,
    element: <ResetPasswordRoute />,
  },
  {
    path: RoutePath.deleteAppointmentRoute,
    element: <DeleteAppointmentRoute />,
  },
  {
    path: RoutePath.deleteSuccessRoute,
    element: <AppointmentDeleteSuccessRoute />,
  },
  {
    path: RoutePath.adminRoute,
    element: (
      <ProtectedRoute roles={[UserRoleName.Admin]}>
        <AdminRoute />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutePath.staff.index,
    element: (
      <ProtectedRoute roles={[UserRoleName.Doctor, UserRoleName.Frontdesk]}>
        <StaffLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={RoutePath.staff.calendar.index} />,
      },
      {
        path: RoutePath.staff.notification,
        element: <Notifications />,
      },
      {
        path: RoutePath.staff.calendar.index,
        children: [
          {
            path: '',
            element: <StaffCalendarRoute />,
          },
          {
            path: RoutePath.staff.calendar.appointments,
            element: <AppointmentsContainer />,
          },
          {
            path: RoutePath.staff.calendar.pendingRequests,
            element: <PendingContainer />,
          },
          {
            path: RoutePath.staff.calendar.newPatientRequests,
            element: <PendingContainer newPatientRequests />,
          },
          {
            path: RoutePath.staff.calendar.bookingDetails + '/:id',
            element: <BookingDetailsContainer />,
          },
          {
            path: RoutePath.staff.calendar.confirmBooking + '/:id',
            element: <ConfirmBooking />,
          },
          {
            path: RoutePath.staff.calendar.rescheduleBooking + '/:id',
            element: <RescheduleBooking />,
          },
          {
            path: RoutePath.staff.calendar.editBooking + '/:id',
            element: <EditBookingContainer />,
          },
        ],
      },
      {
        path: RoutePath.staff.patientList.index,
        children: [
          {
            path: '',
            element: <PatientListView />,
          },
          {
            path: ':patientId',

            children: [
              {
                path: '',
                element: <PatientDetailsContainer />,
              },
              {
                path: RoutePath.staff.patientList.questionnaire,
                element: <Questionnaire />,
              },
              {
                path: 'notes',
                element: <PatientDetailNotes overviewSelectedNoteId={null} />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.patient.index,
    element: <PatientPlatformLayout />,
    children: [
      {
        path: RoutePath.patient.index,
        element: <WelcomeScreen />,
      },
      {
        path: RoutePath.patient.login,
        element: <PatientLogin />,
      },
      {
        path: RoutePath.patient.register,
        element: <PatientRegister />,
      },
      {
        path: RoutePath.patient.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: RoutePath.patient.otpVerification,
        element: <OTPVerfication />,
      },
      {
        path: RoutePath.patient.createPassword,
        element: <CreatePassword />,
      },
      {
        path: RoutePath.patient.onBoarding.index,
        element: (
          <ProtectedRoute
            roles={[UserRoleName.Patient]}
            fallbackRoute={RoutePath.patient.index}
          >
            <PatientOnboardingWelcome />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
