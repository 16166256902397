import {PostLoginResponseData} from '$api/bookingFlow';
import {Updater} from 'use-immer';
import {PatientCarerDetailsValidatedFormData} from './components/PatientCarerDetailForm/schema';
import {PatientContactDetailsFormData} from './components/PatientContactDetailForm/schema';
import {PatientInsuranceDetailFormData} from './components/PatientInsuranceDetailForm/schema';
import {GPOrOpticianReferralValidatedFormData} from './components/GPOrOpticianReferralForm/schema';
import {Slot} from '$modules/frontdesk/type';

export enum BookingView {
  TransitionView,
  BookingSelectionView,
  AppointmentSelectionView,
  CareSelectionView,
  ConsultantSelectionView,
  DateSelectionView,
  ChoicesConfirmationView,
  RegisterWithStoneygateView,
  PatientCarerDetailsView,
  GPOrOpticianReferralView,
  PatientContactDetailView,
  PatientInsuranceDetailView,
  LastStepView,
  AppSummaryView,
}

export enum BookingStepperStage {
  Appointment,
  PatientDetails,
  Insurance,
}

export enum BookingPreferredTime {
  All = 'All time',
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}

export interface Booking {
  id: number;
  title: string;
  description: string;
  image: string;
  enable: boolean;
  display_name?: string;
}

export interface Appointment {
  id: number;
  title: string;
  description: string;
  image: string;
  enable: boolean;
}

export interface Care {
  id: number;
  title: string;
  description: string;
  enable: boolean;
}

export interface Consultant {
  id: number;
  profileUrl: string | null;
  fullName: string;
  doctorDetails: {
    bioLink: string;
    consultationFee: number;
    followupFee: number;
    specializations: {
      id: number;
      title: string;
    }[];
    description: string;
  } | null;
}

export interface Relationship {
  id: number;
  name: string;
}

export interface Payment {
  id: number;
  name: string;
}

export interface PaymentCard {
  id: number;
  name: string;
}

export interface Insurance {
  id: number;
  name: string;
}

export interface City {
  code: string;
  name: string;
}

export interface Country {
  code: string;
  name: string;
  countryCode: string;
}

export interface BookingData {
  booking?: Booking;
  appointment?: Appointment;
  care?: Care;
  consultant?: Consultant;
  date?: Date;
  time?: {id: BookingPreferredTime; title: string};
  slot?: Slot;
  registered?: {
    isRegistered: boolean;
    email?: string;
    patientId?: number;
    patientMobileNumber?: string;
    isEmailvalidated?: boolean;
  };
  patientCarerDetails?: PatientCarerDetailsValidatedFormData;
  gpOrOpticianReferral?: GPOrOpticianReferralValidatedFormData;
  patientContactDetail?: PatientContactDetailsFormData;
  patientInsuranceDetail?: PatientInsuranceDetailFormData;
  password?: string;
  appointmentId?: number;
}

export type BookingDataUpdater = Updater<BookingData>;

export interface BookingChoices {
  booking?: Booking;
  appointment?: Appointment;
  care?: Care;
  consultant?: Consultant;
  date?: Date;
  time?: {id: string; title: string};
  registered?: {isRegistered: boolean; email?: string};
  patientCarerDetails?: PatientCarerDetailsValidatedFormData;
  gpOrOpticianReferral?: GPOrOpticianReferralValidatedFormData;
  patientContactDetail?: PatientContactDetailsFormData;
  patientInsuranceDetail?: PatientInsuranceDetailFormData;
  payment?: Payment;
  insurance?: Insurance;
  lastStep?: {password: string};
  loginData?: PostLoginResponseData;
  createAppointment?: {id: number; addressId: number; patientId: number};
}

export type BookingSelectionViewChoices = BookingData;

export type AppointmentSelectionViewChoices = BookingData &
  Required<Pick<BookingData, 'booking'>>;

export type CareSelectionViewChoices = BookingData &
  Required<Pick<BookingData, 'booking' | 'appointment'>>;

export type ConsultantSelectionViewChoices = BookingData &
  Required<Pick<BookingData, 'booking' | 'appointment' | 'care'>>;

export type DateSelectionViewChoices = BookingData &
  Required<
    Pick<BookingData, 'booking' | 'appointment' | 'care' | 'consultant'>
  >;

export type ChoicesConfirmationViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      'booking' | 'appointment' | 'care' | 'consultant' | 'date' | 'slot'
    >
  >;

export type RegisterWithStoneygateViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      'booking' | 'appointment' | 'care' | 'consultant' | 'date' | 'slot'
    >
  >;

export type PatientCarerDetailsViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
    >
  >;

export type GPOrOpticianReferralViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
      | 'patientCarerDetails'
    >
  >;

export type PatientContactDetailViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
      | 'patientCarerDetails'
      | 'gpOrOpticianReferral'
    >
  >;

export type PatientInsuranceDetailViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
      | 'patientCarerDetails'
      | 'gpOrOpticianReferral'
      | 'patientContactDetail'
    >
  >;

export type LastStepViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
      | 'patientCarerDetails'
      | 'gpOrOpticianReferral'
      | 'patientContactDetail'
      | 'patientInsuranceDetail'
    >
  >;

export type AppSummaryViewChoices = BookingData &
  Required<
    Pick<
      BookingData,
      | 'booking'
      | 'appointment'
      | 'care'
      | 'consultant'
      | 'date'
      | 'slot'
      | 'registered'
      | 'patientCarerDetails'
      | 'gpOrOpticianReferral'
      | 'patientContactDetail'
      | 'patientInsuranceDetail'
      | 'password'
      | 'appointmentId'
    >
  >;

export enum BookingChoicesActionType {
  setBookingAppointmentCare = 'SET_BOOKING_APPOINTMENT_CARE',
  setBooking = 'SET_BOOKING',
  unsetBooking = 'UNSET_BOOKING',
  setAppointment = 'SET_APPOINTMENT',
  unsetAppointment = 'UNSET_APPOINTMENT',
  setCare = 'SET_CARE',
  unsetCare = 'UNSET_CARE',
  setConsultant = 'SET_CONSULTANT',
  unsetConsultant = 'UNSET_CONSULTANT',
  setDate = 'SET_DATE',
  unsetDate = 'UNSET_DATE',
  setTime = 'SET_TIME',
  unsetTime = 'UNSET_TIME',
  setRegistered = 'SET_REGISTERED',
  unsetRegistered = 'UNSET_REGISTERED',
  setPatientCarerDetails = 'SET_PATIENT_CARER_DETAILS',
  unsetPatientCarerDetails = 'UNSET_PATIENT_CARER_DETAILS',
  setGPOrOpticianReferral = 'SET_GP_OR_OPTICIAN_REFERRAL',
  unsetGPOrOpticianReferral = 'UNSET_GP_OR_OPTICIAN_REFERRAL',
  setPatientContactDetail = 'SET_CONTACT_DETAIL',
  unsetPatientContactDetail = 'UNSET_CONTACT_DETAIL',
  setPatientInsuranceDetails = 'SET_PATIENT_INSURANCE_DETAILS',
  unsetPatientInsuranceDetails = 'UNSET_PATIENT_INSURANCE_DETAILS',
  setLastStep = 'SET_LAST_STEP',
  unsetLastStep = 'UNSET_LAST_STEP',
  setLoginData = 'SET_LOGIN_DATA',
  unsetLoginData = 'UNSET_LOGIN_DATA',
  setCreateAppointment = 'SET_CREATE_APPOINTMENT',
  reset = 'RESET',
}

export type BookingChoicesAction =
  | {type: BookingChoicesActionType.setBooking; payload: Booking}
  | {type: BookingChoicesActionType.unsetBooking}
  | {type: BookingChoicesActionType.setAppointment; payload: Appointment}
  | {type: BookingChoicesActionType.unsetAppointment}
  | {type: BookingChoicesActionType.setCare; payload: Care}
  | {type: BookingChoicesActionType.unsetCare}
  | {type: BookingChoicesActionType.setConsultant; payload: Consultant}
  | {type: BookingChoicesActionType.unsetConsultant}
  | {
      type: BookingChoicesActionType.setBookingAppointmentCare;
      payload: {booking: Booking; appointment: Appointment; care: Care};
    }
  | {type: BookingChoicesActionType.setDate; payload: Date}
  | {type: BookingChoicesActionType.unsetDate}
  | {
      type: BookingChoicesActionType.setTime;
      payload: {id: string; title: string};
    }
  | {type: BookingChoicesActionType.unsetTime}
  | {
      type: BookingChoicesActionType.setRegistered;
      payload: {isRegistered: boolean; email?: string};
    }
  | {type: BookingChoicesActionType.unsetRegistered}
  | {
      type: BookingChoicesActionType.setPatientCarerDetails;
      payload: PatientCarerDetailsValidatedFormData;
    }
  | {type: BookingChoicesActionType.unsetPatientCarerDetails}
  | {
      type: BookingChoicesActionType.setGPOrOpticianReferral;
      payload: GPOrOpticianReferralValidatedFormData;
    }
  | {type: BookingChoicesActionType.unsetGPOrOpticianReferral}
  | {
      type: BookingChoicesActionType.setPatientContactDetail;
      payload: PatientContactDetailsFormData;
    }
  | {type: BookingChoicesActionType.unsetPatientContactDetail}
  | {
      type: BookingChoicesActionType.setPatientInsuranceDetails;
      payload: PatientInsuranceDetailFormData;
    }
  | {
      type: BookingChoicesActionType.unsetPatientInsuranceDetails;
    }
  | {type: BookingChoicesActionType.setLastStep; payload: {password: string}}
  | {type: BookingChoicesActionType.unsetLastStep}
  | {
      type: BookingChoicesActionType.setLoginData;
      payload: PostLoginResponseData;
    }
  | {type: BookingChoicesActionType.unsetLoginData}
  | {
      type: BookingChoicesActionType.setCreateAppointment;
      payload: {id: number; addressId: number; patientId: number};
    }
  | {type: BookingChoicesActionType.reset};

export interface AppointmentDetailNavigatePayload {
  careType: string;
  date: Date | string;
}
