import EyeOpIcon from '$assets/svgs/eye-op.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import {RoutePath} from '$constants/routes';
import {RouteSection, getRoute} from '$utils/route';
import {useNavigate} from 'react-router-dom';

function WelcomeScreen() {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate(getRoute(RouteSection.PatientInvite, RoutePath.patient.register));
  };

  const handleEnterClick = () => {
    navigate(getRoute(RouteSection.PatientInvite, RoutePath.patient.login));
  };

  return (
    <div className="flex-1 flex flex-col pb-6">
      <div className="h-1/3 w-full flex flex-col overflow-hidden justify-center xl:hidden cloth-background">
        <EyeOpIcon className="absolute top-8 left-8" />
      </div>
      <div className="h-2/3 xl:h-full flex flex-col justify-end items-center overflow-auto p-6 gap-3">
        <div>
          <h1 className="text-center text-3xl font-bold text-secondary mb-5">
            Welcome to Eye Op patient platform!
          </h1>
          <p className="text-center font-normal text-base text-tertiary mb-5">
            Use the email you entered during the booking process or the number
            received from us.
          </p>
        </div>

        <div className="w-full flex flex-col gap-6">
          <FrontdeskButton
            className="w-full !p-3 !font-semibold"
            title="Register"
            onClick={handleRegisterClick}
          />
          <FrontdeskButton
            className="w-full !p-3 font-semibold"
            variant="secondary"
            title="Sign in"
            onClick={handleEnterClick}
          />
        </div>
      </div>
    </div>
  );
}

export default WelcomeScreen;
