import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import {FormikErrors, FormikTouched} from 'formik';
import Header from '../Header';
import {ResetPasswordFormData} from './schema';
import {SetStateAction} from 'react';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';

interface CreatePasswordComponentProps {
  continueDisabled: boolean;
  continueLoading: boolean;
  formValues: ResetPasswordFormData;
  formErrors: FormikErrors<ResetPasswordFormData>;
  formTouched: FormikTouched<ResetPasswordFormData>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  showPassword: boolean;
  showConfirmPassword: boolean;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  setShowConfirmPassword: React.Dispatch<SetStateAction<boolean>>;
}

function CreatePasswordComponent(props: CreatePasswordComponentProps) {
  const {
    continueDisabled,
    continueLoading,
    formValues,
    formErrors,
    formTouched,
    showPassword,
    showConfirmPassword,
    handleSubmit,
    handleChange,
    handleBlur,
    setShowPassword,
    setShowConfirmPassword,
  } = props;

  return (
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="mb-8 px-6">
        <h1 className="font-bold text-2xl mb-3 text-secondary">
          Create a new password{' '}
        </h1>
        <p className="font-normal text-base text-tertiary">
          Make sure it's different from your previous one.
        </p>
      </div>
      <form className="flex-1 flex flex-col px-6">
        <div className="flex-1 flex flex-col gap-6">
          <div>
            <label className="mb-2 font-normal text-base text-tertiary">
              New password
            </label>
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={formValues.password}
              placeholder="Enter password"
              endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
              endIconClick={() => setShowPassword(v => !v)}
              touched={formTouched.password}
              error={Boolean(formErrors.password)}
              helperText={
                formErrors.password && formTouched.password
                  ? formErrors.password
                  : ''
              }
            />
          </div>
          <div>
            <label className="font-normal text-base text-tertiary">
              Confirm password
            </label>
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={formValues.confirmPassword}
              placeholder="Repeat password"
              endIcon={showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}
              endIconClick={() => setShowConfirmPassword(v => !v)}
              touched={formTouched.confirmPassword}
              error={Boolean(formErrors.confirmPassword)}
              helperText={
                formErrors.confirmPassword && formTouched.confirmPassword
                  ? formErrors.confirmPassword
                  : ''
              }
            />
          </div>
        </div>
        <div className="pb-8">
          <FrontdeskButton
            title="Create password"
            className="w-full !p-3 !font-semibold"
            disabled={continueDisabled}
            loading={continueLoading}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
}

export default CreatePasswordComponent;
