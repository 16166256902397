import {Button, Tooltip, Typography} from '@mui/material';
import ChipComponent from '$components/ChipComponent';
import FrontdeskButton from '$components/FrontdeskButton';
import {
  ArrowLeftIcon,
  BankIcon,
  CalendarPlusIcon,
  CheckIcon,
  ContactDetailIcon,
  UsersEditIcon,
} from '$assets/svgs';
import {useNavigate, useParams} from 'react-router-dom';
import DatePicker from '$components/DatePicker';
import {EditBookingFormData} from './schema';
import SelectInput from '$components/SelectInput';
import {Care, Consultant, PreferredTime, SubCare} from './types';
import './styles.scss';
import {EDIT_BOOKING_INSURANCE_OPTIONS} from '$modules/frontdesk/constant';
import {City, Country, Insurance, Payment} from '$modules/booking/types';
import Input from '$components/Input';
import {GENDER_ITEMS} from '$modules/booking/constant';
import PhoneNumberInput from '$modules/booking/components/PhoneNumberInput';
import {getChipStatusColors} from '$modules/frontdesk/utils';
import BookingToConfirmIcon from '$assets/svgs/BookingToConfirm.svg?react';
import useTimezone from '../../../../hooks/useTimezone';
import {formatFullName} from '$utils/helper';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';
import {usePreferredPointOfContact} from '$modules/booking/hooks';
import FetchError from '$components/FetchError';
import {FormikErrors, FormikTouched} from 'formik';
import {Color} from '$constants/style';
import EditBookingSkeleton from './EditBookingSkeleton';
import {StatusIconLabelMap} from '../types';
import BookmarkCheckIcon from '$assets/svgs/bookmark-check.svg?react';
import AlertCircelIcon from '$assets/svgs/alert-circle.svg?react';
import {dateToDDMMYYYY} from '$utils/date';
import {BookingDetails} from '$modules/frontdesk/type';

interface EditBookingComponentProps {
  bookingDetails: BookingDetails;
  values: EditBookingFormData;
  errors: FormikErrors<EditBookingFormData>;
  touched: FormikTouched<EditBookingFormData>;
  preferredTimes: PreferredTime[];
  consultants: Consultant[];
  careTypes: Care[];
  subCareTypes: SubCare[];
  insuranceTypes: Insurance[];
  paymentMethods: Payment[];
  cities: City[];
  countries: Country[];
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleFieldChange: (
    field: string,
    value: number | string | boolean | Date
  ) => void;
  handleDateChange: (date: Date | null) => void;
  renderConsultantMenuItem: (item: Consultant) => React.ReactNode;
  renderConsultantValue: (value: string) => React.ReactNode;
  renderPreferredTimeMenuItem: (item: PreferredTime) => React.ReactNode;
  renderPreferredTimeValue: (value: string) => React.ReactNode;
  renderSubCareTypeMenuItem: (item: SubCare) => React.ReactNode;
  renderSubCareTypeValue: (value: string) => React.ReactNode;
  renderCareTypeMenuItem: (item: Care) => React.ReactNode;
  renderCareTypeValue: (value: string) => React.ReactNode;
  renderSelfPayingValue: (value: string) => React.ReactNode;
  renderSelfPayingItem: (item: string) => React.ReactNode;
  renderInsuranceMenuItem: (item: Insurance) => React.ReactNode;
  renderInsuranceValue: (value: string) => React.ReactNode;
  renderPaymentMethodMenuItem: (item: Payment) => React.ReactNode;
  renderPaymentMethodValue: (value: string) => React.ReactNode;
  handleSelfPayingChange: (value: string) => void;
  handlePaymentMethodChange: (value: string) => void;
  renderGenderMenuItem: (item: string) => React.ReactNode;
  renderGenderValue: (item: string) => React.ReactNode;
  renderSelfBookingValue: (value: string) => React.ReactNode;
  renderSelfBookingItem: (item: string) => React.ReactNode;
  renderGPOrOpticianReferralValue: (value: string) => React.ReactNode;
  renderGPOrOpticianReferralItem: (item: string) => React.ReactNode;
  renderPreferredPointOfContactItem: (item: string) => React.ReactNode;
  renderPreferredPointOfContactValue: (value: string) => React.ReactNode;
  renderCityValue: (value: string) => JSX.Element;
  renderCityItem: (item: {code: string; name: string}) => JSX.Element;
  renderCountryValue: (value: string) => JSX.Element;
  renderCountryItem: (item: {code: string; name: string}) => JSX.Element;
  handleSubmit: () => void;
  isModifyMutatePending: boolean;
}
const EditBookingComponent: React.FC<EditBookingComponentProps> = ({
  bookingDetails,
  values,
  errors,
  touched,
  consultants,
  subCareTypes,
  insuranceTypes,
  paymentMethods,
  cities,
  countries,
  handleChange,
  handleBlur,
  handleFieldChange,
  renderConsultantMenuItem,
  renderConsultantValue,
  renderSubCareTypeMenuItem,
  renderSubCareTypeValue,
  renderInsuranceValue,
  renderInsuranceMenuItem,
  renderSelfPayingItem,
  renderSelfPayingValue,
  renderPaymentMethodMenuItem,
  renderPaymentMethodValue,
  handlePaymentMethodChange,
  handleSelfPayingChange,
  renderGenderMenuItem,
  renderGenderValue,
  renderSelfBookingValue,
  renderSelfBookingItem,
  renderGPOrOpticianReferralValue,
  renderGPOrOpticianReferralItem,
  renderPreferredPointOfContactItem,
  renderPreferredPointOfContactValue,
  renderCityItem,
  renderCityValue,
  renderCountryItem,
  renderCountryValue,
  handleSubmit,
  isModifyMutatePending,
}) => {
  const params = useParams();
  const appointmentId = Number(params.id);

  const navigate = useNavigate();
  const timezone = useTimezone();

  const {preferredPointOfContact, isLoading, isError} =
    usePreferredPointOfContact();

  const {
    appointment: {
      patient: {first_name: firstName = '', last_name: lastName = ''} = {},
    } = {},
  } = values;
  const {confirm_at: confirmAt, status} = bookingDetails.appointment;
  const {bgColor, textColor} = getChipStatusColors(status || 'pending');

  const patientName = `${firstName} ${lastName}` || 'Name loading...';
  const statusIconLabelMap: StatusIconLabelMap = {
    confirm: {
      label: `Booking Confirmed ${
        confirmAt ? 'on ' + dateToDDMMYYYY(new Date(confirmAt)) : ''
      }`,
      icon: <BookmarkCheckIcon className="mt-1" />,
    },
    cancel: {
      label: 'Booking Cancelled',
      icon: <AlertCircelIcon className="mt-1" />,
    },
    pending: {
      label: 'Booking to confirm',
      icon: <BookingToConfirmIcon className="mt-1" />,
    },
    complete: {
      label: `Booking Confirmed ${
        confirmAt ? 'on ' + dateToDDMMYYYY(new Date(confirmAt)) : ''
      }`,
      icon: <BookmarkCheckIcon className="mt-1" />,
    },
  };

  const handleBackClick = () => {
    navigate(
      getRoute(
        RouteSection.Calendar,
        RoutePath.staff.calendar.bookingDetails,
        appointmentId
      )
    );
  };

  const nameElement =
    patientName.length > 30 ? (
      <Tooltip title={patientName}>
        <span>{formatFullName(patientName)}</span>
      </Tooltip>
    ) : (
      <span>{patientName}</span>
    );

  if (isLoading) return <EditBookingSkeleton />;
  if (isError) return <FetchError />;

  const preferredContactOptions = preferredPointOfContact.map(
    item => item.name
  );

  const country = values?.appointment?.patient?.address?.country;

  return (
    <div className="edit-booking">
      <header className="edit-booking__header">
        <Button
          startIcon={<ArrowLeftIcon />}
          className="edit-booking__button--back"
          onClick={handleBackClick}
        >
          Back
        </Button>
      </header>
      <main className="edit-booking__body">
        <h1 className="edit-booking__body__heading">Edit booking details</h1>
        <div className="edit-booking__body__sub-heading">
          <div className="edit-booking__patient-name">{nameElement}</div>
          <ChipComponent
            hasIcon
            className={status === 'pending' ? 'chip-gradient' : ''}
            label={statusIconLabelMap[status]?.label}
            bgColor={bgColor}
            textColor={textColor}
            icon={statusIconLabelMap[status]?.icon}
          />
        </div>
        <div className="edit-booking__form">
          <div className="edit-booking__form__appointment-and-financial-details">
            <div className="details-container appointment-details ">
              <div className="container-heading-container">
                <CalendarPlusIcon />
                <h2 className="container-heading-text">Appointment Details</h2>
              </div>
              <div className="field-container">
                <div className="label-text">Requested date</div>
                <DatePicker
                  isDisabled={true}
                  disablePast
                  name="appointment.date"
                  placeholder="Select a date"
                  value={new Date(values.appointment.date)}
                  onChange={date => handleFieldChange('date', date!)}
                  timezone={timezone}
                />
              </div>
              <div className="field-container">
                <div className="label-text">Appointment Type</div>

                {/*
                //Currently editing appointment type is not in scope.
                <SelectInput
                  name="appointment.care_type_id"
                  items={careTypes}
                  renderItem={renderCareTypeMenuItem}
                  renderValue={renderCareTypeValue}
                  value={String(values.appointment.care_type_id)}
                  onBlur={handleBlur}
                  onChange={value => handleFieldChange('care_type_id', +value)}
                /> */}
                <Input
                  name="appointment.care_type_id"
                  value="Surgery consultation"
                  onChange={() => {}}
                  disabled
                />
              </div>
              <div className="field-container">
                <div className="label-text">Surgery Type</div>
                <SelectInput
                  name="appointment.sub_care_type_id"
                  items={subCareTypes}
                  renderItem={renderSubCareTypeMenuItem}
                  renderValue={renderSubCareTypeValue}
                  value={String(values.appointment.sub_care_type_id)}
                  onBlur={handleBlur}
                  onChange={value =>
                    handleFieldChange('sub_care_type_id', +value)
                  }
                  error={
                    touched.appointment?.sub_care_type_id &&
                    Boolean(errors.appointment?.sub_care_type_id)
                  }
                />
                {touched.appointment?.sub_care_type_id &&
                  errors.appointment?.sub_care_type_id && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.sub_care_type_id}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Consultant</div>
                <SelectInput
                  name="appointment.doctor_id"
                  items={consultants}
                  renderValue={renderConsultantValue}
                  renderItem={renderConsultantMenuItem}
                  value={String(values.appointment.doctor_id)}
                  onBlur={handleBlur}
                  onChange={value => handleFieldChange('doctor_id', +value)}
                  error={
                    touched.appointment?.doctor_id &&
                    Boolean(errors.appointment?.doctor_id)
                  }
                />
                {touched.appointment?.doctor_id &&
                  errors.appointment?.doctor_id && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.doctor_id}
                    </p>
                  )}
              </div>
            </div>
            <div className="details-container financial-details">
              <div className="container-heading-container">
                <BankIcon />
                <h2 className="container-heading-text">Financial Details</h2>
              </div>
              <div className="field-container">
                <div className="label-text">Self paying</div>
                <SelectInput
                  name="appointment.self_paying"
                  value={values.appointment.self_paying ? 'Yes' : 'No'}
                  items={EDIT_BOOKING_INSURANCE_OPTIONS}
                  renderValue={renderSelfPayingValue}
                  renderItem={renderSelfPayingItem}
                  onBlur={handleBlur}
                  onChange={handleSelfPayingChange}
                  error={
                    touched.appointment?.self_paying &&
                    Boolean(errors.appointment?.self_paying)
                  }
                />
                {touched.appointment?.self_paying &&
                  errors.appointment?.self_paying && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.self_paying}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">
                  {values.appointment.self_paying
                    ? 'Payment Method'
                    : 'Insurance provider'}
                </div>
                {values.appointment.self_paying ? (
                  <>
                    <SelectInput
                      name="appointment.payment_mode"
                      items={paymentMethods}
                      placeholder="Select payment method"
                      renderValue={renderPaymentMethodValue}
                      renderItem={renderPaymentMethodMenuItem}
                      value={'0'}
                      onBlur={handleBlur}
                      onChange={handlePaymentMethodChange}
                      error={
                        touched.appointment?.payment_mode &&
                        Boolean(errors.appointment?.payment_mode)
                      }
                    />
                    {touched.appointment?.payment_mode &&
                      errors.appointment?.payment_mode && (
                        <p className="edit-booking-error-text ">
                          {errors.appointment?.payment_mode}
                        </p>
                      )}
                  </>
                ) : (
                  <>
                    <SelectInput
                      name="appointment.insurance_id"
                      items={insuranceTypes}
                      placeholder="Select insurer"
                      renderValue={renderInsuranceValue}
                      renderItem={renderInsuranceMenuItem}
                      value={String(values.appointment.insurance_id)}
                      onBlur={handleBlur}
                      onChange={handlePaymentMethodChange}
                      error={
                        touched.appointment?.insurance_id &&
                        Boolean(errors.appointment?.insurance_id)
                      }
                    />
                    {touched.appointment?.insurance_id &&
                      errors.appointment?.insurance_id && (
                        <p className="edit-booking-error-text ">
                          {errors.appointment?.insurance_id}
                        </p>
                      )}
                  </>
                )}
              </div>
              {!values.appointment.self_paying && (
                <div className="field-container">
                  <div className="label-text">Membership number</div>
                  <Input
                    name="appointment.membership_no"
                    placeholder="Membership number"
                    value={String(values.appointment.membership_no)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.appointment?.membership_no}
                    error={
                      touched.appointment?.membership_no &&
                      Boolean(errors.appointment?.membership_no)
                    }
                    helperText={
                      touched.appointment?.membership_no &&
                      errors.appointment?.membership_no
                        ? errors.appointment?.membership_no
                        : ''
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="edit-booking__form__patient-details">
            <div className="details-container">
              <div className="container-heading-container">
                <UsersEditIcon />
                <h2 className="container-heading-text">Patient Details</h2>
              </div>
              <div className="field-container">
                <div className="label-text">Gender</div>
                <SelectInput
                  name="appointment.patient.gender"
                  value={values.appointment.patient.gender}
                  items={GENDER_ITEMS}
                  renderValue={renderGenderValue}
                  renderItem={renderGenderMenuItem}
                  onBlur={handleBlur}
                  onChange={value => handleFieldChange('patient.gender', value)}
                  error={
                    touched.appointment?.patient?.gender &&
                    Boolean(errors.appointment?.patient?.gender)
                  }
                />
                {touched.appointment?.patient?.gender &&
                  errors.appointment?.patient?.gender && (
                    <p className="">{errors.appointment?.patient?.gender}</p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Date of birth</div>
                <DatePicker
                  disableFuture
                  name="appointment.patient.dob"
                  placeholder="Date of birth"
                  value={new Date(values.appointment.patient.dob)}
                  onChange={date => handleFieldChange('patient.dob', date!)}
                  timezone={timezone}
                />
                {touched.appointment?.patient?.dob &&
                  errors.appointment?.patient?.dob && (
                    <Typography
                      variant="caption"
                      color={Color.ErrorMain}
                      fontWeight={400}
                    >
                      {/* Formik issue https://github.com/jaredpalmer/formik/issues/3683 */}
                      <>{errors.appointment?.patient?.dob}</>
                    </Typography>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Self booking</div>
                <SelectInput
                  name="appointment.self_booking"
                  value={values.appointment.self_booking ? 'Yes' : 'No'}
                  items={['Yes', 'No']}
                  renderValue={renderSelfBookingValue}
                  renderItem={renderSelfBookingItem}
                  onBlur={handleBlur}
                  onChange={value =>
                    handleFieldChange(
                      'self_booking',
                      value === 'Yes' ? true : false
                    )
                  }
                  error={
                    touched.appointment?.self_booking &&
                    Boolean(errors.appointment?.self_booking)
                  }
                />
                {touched.appointment?.self_booking &&
                  errors.appointment?.self_booking && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.self_booking}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">GP or optician referral</div>
                <SelectInput
                  name="appointment.referred_by_physician"
                  value={
                    values.appointment.referred_by_physician ? 'Yes' : 'No'
                  }
                  items={['Yes', 'No']}
                  renderValue={renderGPOrOpticianReferralValue}
                  renderItem={renderGPOrOpticianReferralItem}
                  onBlur={handleBlur}
                  onChange={value =>
                    handleFieldChange(
                      'referred_by_physician',
                      value === 'Yes' ? true : false
                    )
                  }
                  error={
                    touched.appointment?.referred_by_physician &&
                    Boolean(errors.appointment?.referred_by_physician)
                  }
                />
                {touched.appointment?.referred_by_physician &&
                  errors.appointment?.referred_by_physician && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.referred_by_physician}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Address</div>
                <Input
                  name="appointment.patient.address.line1"
                  placeholder="Enter address"
                  value={values.appointment.patient.address.line1}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    touched.appointment?.patient?.address?.line1 &&
                    Boolean(errors.appointment?.patient?.address?.line1)
                  }
                  helperText={
                    touched.appointment?.patient?.address?.line1 &&
                    errors.appointment?.patient?.address?.line1
                      ? errors.appointment?.patient?.address?.line1
                      : ''
                  }
                  touched={touched.appointment?.patient?.address?.line1}
                />
              </div>
              <div className="field-container">
                <div className="label-text">Post code</div>
                <Input
                  name="appointment.patient.address.postcode"
                  placeholder="Enter postcode"
                  value={values.appointment.patient.address.postcode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    touched.appointment?.patient?.address?.postcode &&
                    Boolean(errors.appointment?.patient?.address?.postcode)
                  }
                  helperText={
                    touched.appointment?.patient?.address?.postcode &&
                    errors.appointment?.patient?.address?.postcode
                      ? errors.appointment?.patient?.address?.postcode
                      : ''
                  }
                  touched={touched.appointment?.patient?.address?.postcode}
                />
              </div>
              <div className="field-container">
                <div className="label-text">Country</div>
                <SelectInput
                  name="appointment.patient.address.country"
                  placeholder="Country"
                  value={values.appointment.patient.address.country}
                  items={countries}
                  renderValue={renderCountryValue}
                  renderItem={renderCountryItem}
                  onBlur={handleBlur}
                  onChange={value =>
                    handleFieldChange('patient.address.country', value)
                  }
                  error={
                    touched.appointment?.patient?.address?.country &&
                    Boolean(errors.appointment?.patient?.address?.country)
                  }
                />
                {touched.appointment?.patient?.address?.country &&
                  errors.appointment?.patient?.address?.country && (
                    <p className="edit-booking-error-text">
                      {errors.appointment?.patient?.address?.country}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Town or city</div>
                <SelectInput
                  name="appointment.patient.address.city"
                  placeholder="Enter town or city"
                  value={values.appointment.patient.address.city}
                  items={cities}
                  renderValue={renderCityValue}
                  renderItem={renderCityItem}
                  onBlur={handleBlur}
                  onChange={value =>
                    handleFieldChange('patient.address.city', value)
                  }
                  error={
                    touched.appointment?.patient?.address?.city &&
                    Boolean(errors.appointment?.patient?.address?.city)
                  }
                />
                {touched.appointment?.patient?.address?.city &&
                  errors.appointment?.patient?.address?.city && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.patient?.address?.city}
                    </p>
                  )}
              </div>
              <div className="container-heading-container mt-6">
                <ContactDetailIcon />
                <h2 className="container-heading-text">Contact Details</h2>
              </div>
              <div className="field-container">
                <div className="label-text">Email address</div>
                <Input
                  name="appointment.contact_email"
                  placeholder="Email address"
                  value={values.appointment.contact_email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    touched.appointment?.contact_email &&
                    Boolean(errors.appointment?.contact_email)
                  }
                  helperText={
                    touched.appointment?.contact_email &&
                    errors.appointment?.contact_email
                      ? errors.appointment?.contact_email
                      : ''
                  }
                  touched={touched.appointment?.contact_email}
                />
              </div>
              <div className="field-container">
                <div className="label-text">Phone number</div>
                <PhoneNumberInput
                  name="appointment.contact_mobile_number"
                  value={values.appointment.contact_mobile_number}
                  onBlur={handleBlur}
                  country={country}
                  onChange={event => {
                    if (event.target.value.length <= 13) handleChange(event);
                  }}
                  error={
                    touched.appointment?.contact_mobile_number &&
                    Boolean(errors.appointment?.contact_mobile_number)
                  }
                />
                {touched.appointment?.contact_mobile_number &&
                  errors.appointment?.contact_mobile_number && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.contact_mobile_number}
                    </p>
                  )}
              </div>
              <div className="field-container">
                <div className="label-text">Preferred point of contact</div>
                <SelectInput
                  name="appointment.preferred_point_of_contact"
                  value={values.appointment.preferred_point_of_contact}
                  items={preferredContactOptions}
                  renderValue={renderPreferredPointOfContactValue}
                  renderItem={renderPreferredPointOfContactItem}
                  onBlur={handleBlur}
                  error={
                    touched.appointment?.preferred_point_of_contact &&
                    Boolean(errors.appointment?.preferred_point_of_contact)
                  }
                  onChange={value =>
                    handleFieldChange('preferred_point_of_contact', value)
                  }
                />
                {touched.appointment?.preferred_point_of_contact &&
                  errors.appointment?.preferred_point_of_contact && (
                    <p className="edit-booking-error-text ">
                      {errors.appointment?.preferred_point_of_contact}
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="edit-booking__footer">
        <FrontdeskButton
          title="Cancel"
          variant="secondary"
          onClick={handleBackClick}
        />
        <FrontdeskButton
          disabled={isModifyMutatePending}
          loading={isModifyMutatePending}
          startIcon={<CheckIcon />}
          title="Save changes"
          onClick={() => handleSubmit()}
        />
      </footer>
    </div>
  );
};

export default EditBookingComponent;
