import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';
import {getBookingTypes} from '$api/bookingFlow';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import AppointmentTypeSection from './AppointmentTypeSection';
import {BookingData, BookingDataUpdater} from '../../types';

interface BookingTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function BookingTypeSection(props: BookingTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useQuery({
    queryKey: ['booking-types'],
    queryFn: getBookingTypes,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <FetchError />;
  }

  const handleBookingChange: RadioGroupProps['onChange'] = e => {
    const item = data.data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.booking = item || undefined;
    });
  };

  const bookingTypeButtons = data.data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.enable}
    />
  ));

  return (
    <>
      <FormControl>
        <FormLabel id="appointment-type1">
          <Typography variant="h4">What would you like to book?</Typography>
        </FormLabel>
        <RadioGroup
          value={formChoices.booking?.id}
          name="radio-buttons-group"
          onChange={handleBookingChange}
        >
          {bookingTypeButtons}
        </RadioGroup>
      </FormControl>
      {formChoices.booking && (
        <AppointmentTypeSection
          formChoices={formChoices}
          setFormChoices={setFormChoices}
        />
      )}
    </>
  );
}

export default BookingTypeSection;
