import axios, {AxiosError} from 'axios';
import {API_URL} from '$constants/env';
import {createAcceptHeaderValue} from '$utils/axios';
import store from '$redux/store';
import {loadAuth, resetAuth} from '$redux/authSlice';
import LocalStorage from '$utils/localStore';
import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';
import {PostLoginResponseData as PostPatientLoginResponseData} from './bookingFlow';
import {PostLoginResponseData as PostUserLoginResponseData} from './login';
import {UserAuthData} from '$redux/authSlice/types';
import {UserRoleName} from '$modules/admin/constant';

const subDomain = window.location.hostname.split('.')[0];

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: createAcceptHeaderValue(1),
    'Sub-Domain': subDomain,
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = store.getState().auth.token;
  if (token) config.headers.Authorization = token;

  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async (error: unknown) => {
    if (error instanceof AxiosError) {
      const responseData = error.response?.data;
      const responseMessage = responseData?.message;

      const isTokenInvalid =
        error.response?.status === 401 &&
        responseMessage?.toLowerCase().includes('token is invalid');

      if (isTokenInvalid) {
        const refreshToken = store.getState().auth?.refreshToken;
        const refreshSuccess = await refreshAuth(refreshToken);

        if (!refreshSuccess) {
          LocalStorage.removeItem(LocalStorage.AUTH_USER_DATA);
          store.dispatch(resetAuth());
          toast(
            ToastType.Error,
            'Session expired',
            'Your session has expired. Please log in again.',
            'session-expired'
          );
        }
      }

      return Promise.reject(error);
    }
  }
);

async function refreshAuth(refreshToken: string | null) {
  if (!refreshToken) return false;

  try {
    const response = await fetch(`${API_URL}/sessions/refresh`, {
      method: 'POST',
      body: JSON.stringify({refresh_token: refreshToken}),
      headers: {
        Accept: createAcceptHeaderValue(1),
        'Sub-Domain': subDomain,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = (await response.json()) as
        | PostUserLoginResponseData
        | PostPatientLoginResponseData;

      await loadAuthFromRefreshResponse(responseData);
      return true;
    } else return false;
  } catch (error) {
    console.error('Error refreshing auth:', error);
    return false;
  }
}

async function loadAuthFromRefreshResponse(
  responseData: PostUserLoginResponseData | PostPatientLoginResponseData
) {
  const data = responseData.data;

  if ('user' in data) {
    const user = data.user;

    const userData: UserAuthData = {
      id: user.id,
      fullName: user.full_name,
      hospitalId: user.hospital_id,
      email: user.email,
      roleName: user.role_name,
      mobileNumber: user.mobile_number,
      profileUrl: user.profile_url,
      timezone: user.hospital_details.timezone,
    };

    store.dispatch(
      loadAuth({
        token: data.token,
        refreshToken: data.refresh_token,
        user: userData,
      })
    );
  }

  if ('patient' in data) {
    const patient = data.patient;

    const userData: UserAuthData = {
      id: patient.id,
      fullName: patient.full_name,
      hospitalId: null,
      email: patient.email,
      roleName: UserRoleName.Patient,
      mobileNumber: patient.mobile_number,
      profileUrl: null,
      timezone: null,
    };

    store.dispatch(
      loadAuth({
        token: data.token,
        refreshToken: data.refresh_token,
        user: userData,
      })
    );
  }
}

export default axiosInstance;
