import React, {SetStateAction} from 'react';
import {Button} from '@mui/material';
import AppointmentTypeSelectionComponent from './component';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import {FormState} from '../types';
import {useBookingTypeHook} from '../hooks/useBookingTypeHook';
import {OfflineBookingStep} from '../constant';

interface AppointmentTypeSelectionContainerProps {
  setActiveStep: React.Dispatch<SetStateAction<OfflineBookingStep>>;
  offlineBookingFormData: FormState;
  setOfflineBookingFormData: React.Dispatch<SetStateAction<FormState>>;
  offlineBookingType: string;
}

const AppointmentTypeSelectionContainer: React.FC<
  AppointmentTypeSelectionContainerProps
> = ({
  setActiveStep,
  offlineBookingFormData,
  setOfflineBookingFormData,
  offlineBookingType,
}) => {
  const {isPending, data} = useBookingTypeHook();

  if (isPending) return <Loading />;
  if (!data) return <FetchError />;

  const handleBackBtnClick = () => {
    if (offlineBookingType === 'new') {
      setActiveStep(OfflineBookingStep.ContactDetails);
    } else if (offlineBookingType === 'surgery') {
      setActiveStep(OfflineBookingStep.SelectOrBookPatient);
    }
  };

  return (
    <>
      <AppointmentTypeSelectionComponent
        appointmentType={data.data}
        setActiveStep={setActiveStep}
        offlineBookingFormData={offlineBookingFormData}
        setOfflineBookingFormData={setOfflineBookingFormData}
        offlineBookingType={offlineBookingType}
      />
      <div className="appointment-dialog-footer">
        <Button className="back-btn" onClick={handleBackBtnClick}>
          Back
        </Button>
      </div>
    </>
  );
};

export default AppointmentTypeSelectionContainer;
