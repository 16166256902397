import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './styles';

interface ActionCardProps {
  description: string;
  icon: React.ReactNode;
  title: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

function ActionCard(props: ActionCardProps) {
  const {
    description,
    icon,
    title,
    active = false,
    disabled = false,
    onClick,
  } = props;

  return (
    <Box
      sx={styles.card}
      onClick={onClick}
      className={disabled ? 'disabled' : active ? 'active' : ''}
    >
      <span className={`booking-card-icon ${disabled ? 'disabled' : ''}`}>
        {icon}
      </span>
      <Box>
        <Typography variant="h3" sx={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
}

export default ActionCard;
