import {Question} from '$api/patientList/questionnaire';
import {Fragment} from 'react';
import QuestionCard from './questionCard';
import {FormikProps, FormikValues} from 'formik';
import QuestionnaireHeader from '$components/QuestionnaireHeader';
import QuestionnaireFooter from '$components/QuestionnaireFooter';
import './styles.scss';

interface MedicalHistoryQuestionnaireComponentProps {
  formik: FormikProps<FormikValues>;
  currentPage: number;
  startIndex: number;
  questionsToDisplay: Question[];
  totalPages: number;
  handleFieldChange: (fieldId: string, value: string) => void;
  handleDateChange: (fieldId: string, date: Date | null) => void;
  handlePageChange: (page: number) => void;
  renderYearItem: (item: string) => JSX.Element;
  renderYearValue: (value: string, placeholder: string) => JSX.Element;
  handleFooterSubmit: () => Promise<void>;
  handleBack: () => void;
  disableSubmit: boolean;
}

function MedicalHistoryQuestionnaireComponent(
  props: MedicalHistoryQuestionnaireComponentProps
) {
  const {
    formik,
    currentPage,
    handleFieldChange,
    handleDateChange,
    handleFooterSubmit,
    handlePageChange,
    questionsToDisplay,
    renderYearItem,
    renderYearValue,
    startIndex,
    totalPages,
    handleBack,
    disableSubmit,
  } = props;

  return (
    <div className="medicalquestionnairepage-details">
      <QuestionnaireHeader handleBack={handleBack} />

      <div className="medicalquestionnairepage-details__content">
        <main className="medicalquestionnaire__main">
          <h1 className="medicalquestionnaire__main-heading">
            Medical history questionnaire
          </h1>
          {currentPage === 1 && (
            <div className="mb-6">
              <p className="text-xl text-[#475467] font-semibold">
                Have you ever had, or are you currently being treated for, any
                of the following?
              </p>
            </div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
              {questionsToDisplay.map((question, idx) => (
                <Fragment key={idx}>
                  <QuestionCard
                    errors={formik.errors}
                    touched={formik.touched}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    handleFieldChange={handleFieldChange}
                    handleDateChange={handleDateChange}
                    values={formik.values}
                    fieldName={question.id.toString()}
                    id={idx}
                    question={question}
                    startIndex={startIndex}
                    renderYearItem={renderYearItem}
                    renderYearValue={renderYearValue}
                  />
                  {(idx + 1) % 3 === 0 &&
                    idx + 1 !== questionsToDisplay.length && (
                      <hr className="hidden md:block col-span-1 md:col-span-2 lg:col-span-3 border-b-1 border-gray-400 opacity-15" />
                    )}
                </Fragment>
              ))}
            </div>
          </form>
        </main>
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFooterSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
      />
    </div>
  );
}

export default MedicalHistoryQuestionnaireComponent;
