import MonitorPlayIcon from '$assets/svgs/MonitorPlay.svg?react';

function PatientOnboardingWelcome() {
  return (
    <div className="flex-1 flex flex-col">
      <div className="h-1/2 bg-neutral-200 flex items-center justify-center primary">
        <MonitorPlayIcon />
      </div>
      <div className="h-1/2 p-6">
        <h1 className="font-bold text-2xl mb-3 text-secondary">
          Welcome to Eye Op!
        </h1>
        <p className="font-normal text-base text-tertiary">
          Welcome to our platform! We're here to support you every step of the
          way after your eye surgery.
        </p>
      </div>
    </div>
  );
}

export default PatientOnboardingWelcome;
