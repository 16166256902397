import {PostLoginErrorResponseData} from '$api/bookingFlow';
import {putAppointment} from '$api/frontdesk';
import {ToastType} from '$components/Toast/constant';
import {RoutePath} from '$constants/routes';
import {handleErrorResponse} from '$utils/axios';
import {getRoute, RouteSection} from '$utils/route';
import toast from '$utils/toast';
import {useMutation} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';

const useModifyAppointment = (appointmentId: number) => {
  const navigate = useNavigate();
  const {isPending, mutate: modifyMutate} = useMutation({
    mutationKey: ['edit-appointment'],
    mutationFn: putAppointment,
    onSuccess: () => {
      toast(ToastType.Success, 'Appointment edited successfully', '');
      navigate(
        getRoute(
          RouteSection.Calendar,
          RoutePath.staff.calendar.bookingDetails,
          appointmentId
        )
      );
    },
    onError: error =>
      handleErrorResponse<PostLoginErrorResponseData>(
        error,
        'Edit Appointment'
      ),
  });

  return {isPending, modifyMutate};
};

export default useModifyAppointment;
