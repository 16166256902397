import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';
import {getCareTypes} from '$api/bookingFlow';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import {BookingData, BookingDataUpdater} from '../../types';

interface CareTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function CareTypeSection(props: CareTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useQuery({
    queryKey: ['care-types', formChoices.appointment!.id],
    queryFn: () => getCareTypes(formChoices.appointment!.id),
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const handleAppointmentChange: RadioGroupProps['onChange'] = e => {
    const item = data.data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.care = item || undefined;
    });
  };

  const careTypeButtons = data.data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.enable}
    />
  ));

  return (
    <FormControl>
      <FormLabel id="appointment-type1">
        <Typography variant="h4">
          What surgery are you interested in?
        </Typography>
      </FormLabel>
      <RadioGroup
        value={formChoices.care?.id}
        name="radio-buttons-group"
        onChange={handleAppointmentChange}
      >
        {careTypeButtons}
      </RadioGroup>
    </FormControl>
  );
}

export default CareTypeSection;
