import {Updater} from 'use-immer';
import {BookingData, BookingView} from '../types';
import {
  isAppointmentSelectionViewChoices,
  isCareSelectionViewChoices,
  isConsultantSelectionViewChoice,
  isDateSelectionViewChoice,
  isGPOrOpticianReferralViewViewChoice,
  isLastStepViewChoices,
  isPatientCarerDetailsViewChoice,
  isPatientContactDetailViewChoice,
  isPatientInsuranceDetailViewChoice,
  isRegisterWithStoneygateViewChoice,
} from '../utils';
import AppointmentSelectionView from '../views/AppointmentSelectionView';
import BookingSelectionView from '../views/BookingSelectionView';
import CareSelectionView from '../views/CareSelectionView';
import ConsultantSelectionView from '../views/ConsultantSelectionView';
import DateSelectionView from '../views/DateSelectionView';
import LastStepView from '../views/LastStepView';
import PatientCarerDetailsView from '../views/PatientCarerDetailsView';
import PatientContactDetailView from '../views/PatientContactDetailView';
import PatientInsuranceDetailView from '../views/PatientInsuranceDetailView';
import RegisterWithStoneygateView from '../views/RegisterWithStoneygateView';
import GPOrOpticianReferralView from '../views/GPOrOpticianReferralView';

interface SwitchActiveLayoutViewProps {
  bookingData: BookingData;
  setBookingData: Updater<BookingData>;
  activeView: BookingView;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
}

function SwitchActiveLayoutView(props: SwitchActiveLayoutViewProps) {
  const {bookingData, setBookingData, activeView, setActiveView} = props;

  switch (activeView) {
    case BookingView.BookingSelectionView:
      return (
        <BookingSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.AppointmentSelectionView:
      if (!isAppointmentSelectionViewChoices(bookingData)) break;
      return (
        <AppointmentSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.CareSelectionView:
      if (!isCareSelectionViewChoices(bookingData)) break;
      return (
        <CareSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.ConsultantSelectionView:
      if (!isConsultantSelectionViewChoice(bookingData)) break;
      return (
        <ConsultantSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.DateSelectionView:
      if (!isDateSelectionViewChoice(bookingData)) break;
      return (
        <DateSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.RegisterWithStoneygateView:
      if (!isRegisterWithStoneygateViewChoice(bookingData)) break;
      return (
        <RegisterWithStoneygateView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.PatientCarerDetailsView:
      if (!isPatientCarerDetailsViewChoice(bookingData)) break;
      return (
        <PatientCarerDetailsView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.GPOrOpticianReferralView:
      if (!isGPOrOpticianReferralViewViewChoice(bookingData)) break;
      return (
        <GPOrOpticianReferralView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.PatientContactDetailView:
      if (!isPatientContactDetailViewChoice(bookingData)) break;
      return (
        <PatientContactDetailView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );

    case BookingView.PatientInsuranceDetailView:
      if (!isPatientInsuranceDetailViewChoice(bookingData)) break;
      return (
        <PatientInsuranceDetailView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    case BookingView.LastStepView:
      if (!isLastStepViewChoices(bookingData)) break;
      return (
        <LastStepView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
    default:
      return (
        <BookingSelectionView
          bookingChoices={bookingData}
          setActiveView={setActiveView}
          setBookingChoices={setBookingData}
        />
      );
  }

  return (
    <BookingSelectionView
      bookingChoices={bookingData}
      setActiveView={setActiveView}
      setBookingChoices={setBookingData}
    />
  );
}

export default SwitchActiveLayoutView;
