import React from 'react';
import {Radio, RadioGroup, FormControlLabel, TextField} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useFormik, FormikValues} from 'formik';
import {usePatientQuestionnaire} from '../hooks';
import {
  Question,
  QuestionnaireType,
  QuestionConditionType,
  QuestionType,
} from '$api/patientList/questionnaire';
import './styles.scss';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import MultiTextInput from '$components/MultiTextInput';
import {
  generateInitialValues,
  generateQuestionnairePayload,
  generateValidationSchema,
  getSubQuestionnaireId,
  getErrorMessage,
} from '../utils';
import QuestionnaireHeader from '$components/QuestionnaireHeader';
import QuestionnaireFooter from '$components/QuestionnaireFooter';

interface DailyLivingAndHospitalExperienceProps {
  patientId: number;
  questionnaire: Question[];
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

const DailyLivingAndHospitalExperience: React.FC<
  DailyLivingAndHospitalExperienceProps
> = ({
  patientId,
  questionnaire,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleBack,
}) => {
  const location = useLocation();
  const currentQuestionnaireType: QuestionnaireType =
    location.state?.type || QuestionnaireType.HospitalExperience;
  let startIndex = 0;
  let endIndex = 0;

  if (currentQuestionnaireType === QuestionnaireType.HospitalExperience) {
    if (currentPage === 1) {
      startIndex = 0;
      endIndex = 4;
    } else if (currentPage === 2) {
      startIndex = 4;
      endIndex = 9;
    }
  } else {
    startIndex = (currentPage - 1) * itemsPerPage;
    endIndex = startIndex + itemsPerPage;
  }

  const questionsToDisplay = questionnaire.slice(startIndex, endIndex);
  const totalPages =
    currentQuestionnaireType === QuestionnaireType.HospitalExperience
      ? 2
      : Math.ceil(questionnaire.length / itemsPerPage);

  const headingMap: {[key: string]: string} = {
    hospital_experience: 'Hospital experience questionnaire',
    daily_living: 'Daily living questionnaire',
  };

  const heading = headingMap[currentQuestionnaireType];

  const initialValues = generateInitialValues(questionnaire);
  const validationSchema = generateValidationSchema(questionsToDisplay);

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmitQuestionnaire(values);
    },
  });

  const {submitPatientQuestionnaire, disableSubmit, error, isLoading} =
    usePatientQuestionnaire({
      patientId,
      historyType: currentQuestionnaireType,
    });

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    const payload = generateQuestionnairePayload(values);
    submitPatientQuestionnaire(payload);
  };

  if (isLoading) return <Loading />;
  if (error) return <FetchError />;

  const renderQuestion = (question: Question) => {
    switch (question.question_type) {
      case QuestionType.Radio:
        return (
          <div>
            <RadioGroup
              row
              name={`${question.id}`}
              value={formik.values[`${question.id}`] || ''}
              onChange={formik.handleChange}
              className="radio-group"
            >
              {question.options?.map((option: string) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            {question.sub_questions &&
              question.sub_questions.length > 0 &&
              question.sub_questions.map(subQuestion => {
                const shouldShowSubQuestion =
                  (formik.values[question.id.toString()] &&
                    subQuestion.condition === QuestionConditionType.All) ||
                  subQuestion.condition === formik.values[`${question.id}`];
                if (!shouldShowSubQuestion) return null;
                return (
                  <div className="daily-living-hospital-experience-sub-question-container">
                    <p>{subQuestion.question}</p>
                    <TextField
                      multiline
                      placeholder={subQuestion.placeholder || ''}
                      name={getSubQuestionnaireId({question, subQuestion})}
                      value={
                        formik.values[
                          getSubQuestionnaireId({question, subQuestion})
                        ] || ''
                      }
                      onChange={formik.handleChange}
                      className="daily-living-hospital-experience-sub-question-text-field"
                    />
                    <p className="text-red-600 justify-center items-center">
                      {getErrorMessage(
                        formik.errors[
                          getSubQuestionnaireId({question, subQuestion})
                        ]
                      )}
                    </p>
                  </div>
                );
              })}
          </div>
        );
      case QuestionType.MultiText:
        return <MultiTextInput name={`${question.id}`} formik={formik} />;
      default:
        return (
          <div>
            <TextField
              multiline
              placeholder={question.placeholder || ''}
              name={`${question.id}`}
              value={formik.values[`${question.id}`] || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="question-text-field-hospital-daily-living"
            />
          </div>
        );
    }
  };

  const handleFooterSubmit = async () => {
    const errors = await formik.validateForm();
    const isFormValid = Object.keys(errors).length === 0;
    const isLastPage = currentPage === totalPages;

    if (isFormValid) {
      if (isLastPage) {
        formik.handleSubmit();
      } else {
        handlePageChange(currentPage + 1);
      }
    }
  };

  return (
    <div className="questionnairepage-details">
      <QuestionnaireHeader handleBack={handleBack} />
      <div className="questionnairepage-details__content">
        <main className="questionnaire__main">
          <div className="questionnaire__main-heading">{heading}</div>
          <form onSubmit={formik.handleSubmit}>
            {questionsToDisplay.map((question: Question, index: number) => (
              <div
                key={question.id}
                className="daily-living-hospital-experience-question-container"
              >
                <h3>{`${startIndex + index + 1}. ${question.question}`}</h3>
                {renderQuestion(question)}
              </div>
            ))}
          </form>
        </main>
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFooterSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
      />
    </div>
  );
};

export default DailyLivingAndHospitalExperience;
