import {MedicalHistory} from '$api/patientList/clinicalDetails';
import {QuestionnaireType} from '$api/patientList/questionnaire';
import {
  CheckedEllipseIcon,
  DownloadIcon,
  EllipseIcon,
  PencilIcon,
} from '$assets/svgs';
import {dateToDDMMYYYY} from '$utils/date';
import {Checkbox, IconButton} from '@mui/material';
import {Color} from '$constants/style';
import './styles.scss';

interface MedicalHistoryCardProps {
  isMedicationCompleted: boolean;
  medicalHistory: MedicalHistory;
  onEditIconClick: (questionnaireType: QuestionnaireType) => void;
  onDownloadClick: (questionnaireType: string) => Promise<void>;
}

function MedicalHistoryCard(props: MedicalHistoryCardProps) {
  const {
    isMedicationCompleted,
    medicalHistory,
    onEditIconClick,
    onDownloadClick,
  } = props;

  return (
    <div key={medicalHistory.key} className="card-container">
      <div className="card-header">
        <Checkbox
          disabled
          checked={isMedicationCompleted}
          icon={<EllipseIcon />}
          checkedIcon={<CheckedEllipseIcon />}
        />
        <div className="card-header-title">
          <h3 className="title">{medicalHistory.title}</h3>
          {isMedicationCompleted && (
            <p className="sub-title">
              Completed on {dateToDDMMYYYY(medicalHistory.completedOn)}
            </p>
          )}
        </div>
      </div>
      <div>
        <IconButton
          disabled={!isMedicationCompleted}
          aria-label="download"
          size="small"
          onClick={() => onDownloadClick(medicalHistory.key)}
        >
          <DownloadIcon
            color={isMedicationCompleted ? Color.PrimaryMain : Color.Grey200}
          />
        </IconButton>
        <IconButton
          onClick={() => onEditIconClick(medicalHistory.key)}
          aria-label="edit"
          size="small"
        >
          <PencilIcon color={Color.PrimaryMain} />
        </IconButton>
      </div>
    </div>
  );
}

export default MedicalHistoryCard;
