import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';

import {getCareTypes} from '$api/bookingFlow';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import RenderSVG from '$components/RenderSVG';
import SelectInput from '$components/SelectInput';

import ActionCard from '../components/ActionCard';
import Footer from '../components/Footer';
import styles, {careSelectionViewStyles} from '../styles';
import {
  BookingData,
  BookingView,
  Care,
  CareSelectionViewChoices,
} from '../types';
import {Updater} from 'use-immer';

interface CareSelectionViewProps {
  bookingChoices: CareSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function CareSelectionView(props: CareSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const {data, isLoading} = useQuery({
    queryKey: ['care-types', bookingChoices.appointment],
    queryFn: () => getCareTypes(bookingChoices.appointment.id),
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const items = data.data;

  const handleCareTypeChange = (value: string) => {
    setBookingChoices(draft => {
      if (!value) {
        draft.care = undefined;
      } else {
        draft.care = items.find(it => it.id.toString() === value);
      }
    });
  };

  const handleBackClick = () => {
    setActiveView(BookingView.AppointmentSelectionView);
  };

  const handleContinueClick = () => {
    setActiveView(BookingView.ConsultantSelectionView);
  };

  const renderMenuItem = (item: Care) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={careSelectionViewStyles.menuItemContainer}
        disabled={!item.enable}
      >
        <Typography
          variant="subtitle1"
          sx={careSelectionViewStyles.menuItemTitle}
        >
          {item.title}
        </Typography>
        <Typography
          variant="caption"
          sx={careSelectionViewStyles.menuItemCaption}
        >
          {item.description}
        </Typography>
      </MenuItem>
    );
  };

  const renderValue = (value: string) => {
    if (!value) return 'Select a surgery';

    const item = items.find(it => it.id.toString() === value);
    if (!item) {
      handleCareTypeChange('');
      return '';
    }

    return item.title;
  };

  return (
    <Box sx={styles.sectionWrapper} flexDirection="column" gap={5}>
      <Stack gap={6} sx={styles.section}>
        <Box>
          <Typography variant="h2" sx={styles.title} textAlign="center">
            What type of care are you looking for?
          </Typography>
        </Box>
        <ActionCard
          active
          icon={
            bookingChoices.appointment.image ? (
              <RenderSVG svg={bookingChoices.appointment.image} />
            ) : null
          }
          title={bookingChoices.appointment.title}
          description={bookingChoices.appointment.description}
        />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h3" sx={styles.stepsTypeHead}>
            What surgery are you interested in?
          </Typography>
          <SelectInput
            items={items}
            renderValue={renderValue}
            placeholder="Select a surgery"
            renderItem={renderMenuItem}
            value={bookingChoices.care?.id.toString() || ''}
            onChange={handleCareTypeChange}
          />
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer
          disableContinue={bookingChoices.care === undefined}
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
        />
      </Box>
    </Box>
  );
}

export default CareSelectionView;
