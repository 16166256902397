import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 10),
    },

    '& .site-logo': {
      width: '52px',
      height: '100%',
    },

    '& .hosptial-logo': {
      width: '178px',
      height: '100%',
    },
  },
  desktopLogo: {
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  mobileLogo: {
    '& .hosptial-mobile-logo': {
      width: '46px',
      height: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
};

export default styles;
