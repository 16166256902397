import {AppointmentType} from './constant';
import {
  AppointmentDetail,
  AppointmentInfo,
  BookingDetails,
  CalendarEvent,
} from './type';

export function appointmentInfoToCalendarEvent(
  info: AppointmentInfo
): CalendarEvent {
  const {appointment} = info;

  return {
    appointmentId: appointment.id,
    patientId: appointment.patientId,
    patientName: appointment.patientName,
    status: appointment.status,
    title: appointment.type,
    isConfirmed: appointment.status === AppointmentType.Confirmed,
    start: appointment.startTime,
    end: appointment.endTime,
  };
}

export const extractAppointmentDetails = (details: BookingDetails) => {
  const {
    id,
    status,
    appointment_type,
    care_type,
    sub_care_type,
    preferred_time,
    doctor,
    date,
    check_in_time,
    check_out_time,
    confirm_at,
    start_time,
  } = details.appointment;

  return {
    appointmentId: id,
    appointment_type_title: appointment_type.title,
    care_type_title: care_type.title,
    sub_care_type_title: sub_care_type.title,
    preferred_time: preferred_time,
    doctor_full_name: doctor?.full_name || 'No preference',
    date: date,
    status: status,
    check_in_time,
    check_out_time,
    confirm_at,
    start_time,
  };
};

export const extractFinancialDetails = (details: BookingDetails) => {
  const {self_paying, insurance, membership_no, payment_mode} =
    details.appointment;
  return {
    self_paying,
    insurance,
    membership_no,
    payment_mode,
  };
};

export const extractContactDetails = (details: BookingDetails) => {
  const {contact_email, contact_mobile_number, preferred_point_of_contact} =
    details.appointment;

  return {
    email: contact_email,
    mobile_number: contact_mobile_number,
    preferred_point_of_contact,
  };
};

export const extractPatientDetails = (details: BookingDetails) => {
  const {gender, dob, full_name} = details.appointment.patient;
  const {self_booking, referred_by_physician, reason} = details.appointment;
  const {line1, line2, postcode, city, city_name, country, country_name} =
    details.appointment.patient.address;

  return {
    gender,
    dob,
    self_booking: self_booking ? 'Yes' : 'No',
    referred_by_physician: referred_by_physician ? 'Yes' : 'No',
    line1,
    line2: line2 || '',
    postcode,
    city,
    country,
    city_name,
    country_name,
    reason,
    full_name,
  };
};
export const formatDateUK = (date: Date) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const day = days[date.getDay()];
  const dateNumber = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const time = `${hours}:${minutes}`;

  return {
    day,
    date: dateNumber,
    time,
  };
};

export const formatAppointmentDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
  };

  return date.toLocaleDateString(undefined, options);
};

export function appointmentDetailToCalendarEvent(
  details: AppointmentDetail,
  override?: Partial<CalendarEvent>
): CalendarEvent {
  const {appointment, appointmentType, patient} = details;

  return {
    appointmentId: appointment.id,
    patientId: patient.id,
    patientName: patient.firstName + ' ' + patient.lastName,
    title: appointmentType.title,
    status: appointment.status,
    isConfirmed: appointment.status === AppointmentType.Confirmed,
    start: appointment.startTime!,
    end: appointment.endTime!,
    ...override,
  };
}

export function timeAgo(date: Date): string {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals: {[key: string]: number} = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hr: 3600,
    min: 60,
    sec: 1,
  };

  for (const interval in intervals) {
    const value = Math.floor(seconds / intervals[interval]);
    if (value > 0) {
      return value === 1 ? `${value} ${interval}` : `${value} ${interval}s`;
    }
  }
  return 'just now';
}

export const parseSnakeCase = (status: string) => {
  const tenseMap: {[key: string]: string} = {
    confirm: 'Confirmed',
    cancel: 'Cancelled',
    to_be_confirm: 'To be confirmed',
    complete: 'Complete',
  };
  return tenseMap[status] || status;
};

export const getChipStatusColors = (status: string) => {
  switch (status) {
    case 'confirm':
      return {bgColor: '#03820F', textColor: '#ffffff'};
    case 'complete':
      return {bgColor: '#03820F', textColor: '#ffffff'};
    case 'cancel':
      return {bgColor: '#D92D20', textColor: '#ffffff'};
    case 'to_be_confirm':
      return {bgColor: '#FEF0C7', textColor: '#1D2939'};
    default:
      return {bgColor: '#D92D20', textColor: '#ffffff'};
  }
};

export const truncateText = (text: string, maxLength: number = 20) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};
