import {useNavigate} from 'react-router-dom';
import {IconButton} from '@mui/material';
import ArrowCircleRight from '$assets/svgs/arrow-circle-right.svg?react';

function Header() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="py-6 px-4">
      <IconButton onClick={handleBackClick}>
        <ArrowCircleRight className="primary-dark-color" />
      </IconButton>
    </div>
  );
}

export default Header;
