import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.scss';

interface FrontdeskButtonProps {
  title: string;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-filled' | 'danger';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function FrontdeskButton(props: FrontdeskButtonProps) {
  const {
    variant = 'primary',
    title,
    startIcon,
    endIcon,
    active,
    loading,
    disabled,
    onClick,
    className,
  } = props;

  return (
    <Button
      disabled={disabled}
      className={`front-desk-button-container flex gap-1 ${className} ${variant} ${
        disabled ? 'disabled' : ''
      } ${active ? 'active' : ''}`}
      startIcon={
        loading ? (
          <CircularProgress size="14px" className="text-white" />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default FrontdeskButton;
