import {useState} from 'react';
import {useFormik} from 'formik';
import {Navigate, useSearchParams} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {usePatientPassword} from '../hooks';
import CreatePasswordComponent from './component';
import {ResetPasswordFormData, resetPasswordSchema} from './schema';
import useAuthUser from '../../../../hooks/useAuthUser';

function CreatePassword() {
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userAuth = useAuthUser();

  const token = searchParams.get('token');

  const {
    disableSubmit,
    isPending,
    sendSetPasswordRequest,
    sendResetPasswordRequest,
  } = usePatientPassword();

  const handleSubmit = (data: ResetPasswordFormData) => {
    if (token) {
      sendResetPasswordRequest(token, data.password);
    } else {
      sendSetPasswordRequest(data.password);
    }
  };

  const formik = useFormik<ResetPasswordFormData>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleSubmit,
  });

  if (!userAuth && !token) {
    return <Navigate to={getRoute(RouteSection.PatientInvite)} />;
  }

  return (
    <CreatePasswordComponent
      continueDisabled={disableSubmit}
      continueLoading={isPending}
      formValues={formik.values}
      formErrors={formik.errors}
      formTouched={formik.touched}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      handleSubmit={formik.handleSubmit}
      showPassword={showPassword}
      showConfirmPassword={showConfirmPassword}
      setShowPassword={setShowPassword}
      setShowConfirmPassword={setShowConfirmPassword}
    />
  );
}

export default CreatePassword;
