import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gridGap: theme.spacing(3),
  },
  footerButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: theme.spacing(3),
  },

  footerActionButton: {
    padding: theme.spacing(1, 4),
    maxWidth: '50%',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      flex: 'unset',
      padding: theme.spacing(1, 6),
    },
  },
};

export default styles;
