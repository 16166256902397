import React from 'react';
import '../BookingDetails/styles.scss';
import {RESHEDULEAPP_SKELETON_ITEM_COUNT} from '$modules/frontdesk/constant';

const RescheduleAppointmentSkeleton: React.FC = () => {
  const skeletonItems = Array.from({length: RESHEDULEAPP_SKELETON_ITEM_COUNT});
  return (
    <div className="w-full h-full p-6 flex flex-col edit_booking_background">
      <div className="flex items-center mt-4">
        <div className="h-6 w-6 rounded-full mr-2 animate-pulse edit_booking_background"></div>
        <div className="h-6 w-24 rounded animate-pulse edit_booking_background"></div>
      </div>
      <div className="h-7 w-64 bg-gray-300 rounded animate-pulse mt-3 mb-4 ml-5"></div>
      <div className="space-y-10 flex-grow ml-5">
        <div className="space-y-3 mt-4">
          <div className="h-4 w-32 bg-gray-300 rounded animate-pulse"></div>
          <div className="flex items-center">
            <div className="h-8 w-48 bg-gray-300 rounded animate-pulse"></div>
          </div>
        </div>
        <div className="space-y-3 mt-4">
          <div className="h-4 w-28 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-8 w-48 bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="space-y-3">
          <div className="h-4 w-24 bg-gray-300 rounded animate-pulse"></div>
          <div className="flex space-x-2 overflow-x-auto pb-2">
            {skeletonItems.map((_, index) => (
              <div
                key={index}
                className="h-8 w-16 bg-gray-300 rounded-md flex-shrink-0 animate-pulse"
              ></div>
            ))}
          </div>
        </div>

        <div className="mt-auto pt-7 mr-5">
          <div className="grid grid-cols-8 gap-2">
            {skeletonItems.map((_, index) => (
              <div
                key={index}
                className="h-8 bg-gray-300 rounded-md animate-pulse"
              ></div>
            ))}
          </div>
          <div className="mt-14 h-60 bg-gray-300 rounded animate-pulse"></div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-1 ml-5 mr-5">
        <div className="h-8 w-20 bg-gray-300 rounded animate-pulse"></div>
        <div className="h-8 w-20 bg-gray-300 rounded animate-pulse"></div>
      </div>
    </div>
  );
};

export default RescheduleAppointmentSkeleton;
