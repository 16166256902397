import {Question, Questionnaires} from '$api/patientList/questionnaire';
import {FormikErrors, FormikValues} from 'formik';
import * as Yup from 'yup';

// Function to generate Yup schema dynamically for questionnaires
export const generateValidationSchema = (questions: Question[]) => {
  const schemaFields: {[key: string]: Yup.AnySchema} = {};

  const addQuestionToSchema = (
    question: Question,
    prefix: string = '',
    parentKey?: string
  ) => {
    const questionKey = `${prefix}${question.id}`;
    switch (question.question_type) {
      case 'radio':
        schemaFields[questionKey] = Yup.string()
          .required('This field is required')
          .optional();
        break;
      case 'textField':
        if (parentKey) {
          schemaFields[questionKey] = Yup.string().when(parentKey, {
            is: 'Yes',
            then: () => Yup.string().required('This field is required'),
            otherwise: () => Yup.string().notRequired(),
          });
        } else {
          schemaFields[questionKey] = Yup.string()
            .required('This field is required')
            .optional();
        }
        break;
      case 'selectYear':
        if (parentKey) {
          schemaFields[questionKey] = Yup.string().when(parentKey, {
            is: 'Yes',
            then: () => Yup.string().required('This field is required'),
            otherwise: () => Yup.string().notRequired(),
          });
        } else {
          schemaFields[questionKey] = Yup.string()
            .required('This field is required')
            .optional();
        }
        break;
      case 'multiText':
        schemaFields[questionKey] = Yup.array()
          .of(Yup.string().required('This field is required'))
          .optional();
        break;
      case 'date':
        schemaFields[questionKey] = Yup.date()
          .required('This field is required')
          .optional();
        break;
      default:
        break;
    }

    // Recursively add sub-questions to the schema
    if (question.sub_questions && question.sub_questions.length > 0) {
      question.sub_questions.forEach(subQuestion =>
        addQuestionToSchema(subQuestion, `${questionKey}_`, questionKey)
      );
    }
  };

  questions.forEach(question => addQuestionToSchema(question));

  return Yup.object().shape(schemaFields);
};

// Function to generate initial values for questionnaires dynamically
export const generateInitialValues = (questions: Question[]) => {
  const initialValues: {[key: string]: string | string[]} = {};

  const addQuestionToInitialValues = (
    question: Question,
    prefix: string = ''
  ) => {
    const questionKey = `${prefix}${question.id}`;
    if (question.question_type === 'multiText') {
      if (Array.isArray(question.answer)) {
        initialValues[questionKey] = question.answer;
      } else {
        initialValues[questionKey] = question.answer || '';
      }
    } else {
      initialValues[questionKey] = question.answer || '';
    }

    // Recursively add sub-questions to the initial values
    if (question.sub_questions && question.sub_questions.length > 0) {
      question.sub_questions.forEach(subQuestion =>
        addQuestionToInitialValues(subQuestion, `${questionKey}_`)
      );
    }
  };

  questions.forEach(question => addQuestionToInitialValues(question));

  return initialValues;
};

export const getErrorMessage = (
  error:
    | string
    | string[]
    | FormikErrors<unknown>
    | FormikErrors<unknown>[]
    | undefined
): string => {
  if (typeof error === 'string') {
    return error;
  } else if (Array.isArray(error)) {
    return error.join(', ');
  } else if (typeof error === 'object') {
    return JSON.stringify(error);
  }
  return '';
};

export const generateQuestionnairePayload = (
  values: FormikValues
): Questionnaires[] =>
  Object.entries(values).map(([key, value]) => {
    const idParts = key.split('_');
    const questionId = Number(idParts[idParts.length - 1]);

    return {id: questionId, answer: value};
  });

export const getSubQuestionnaireId = ({
  question,
  subQuestion,
}: {
  question: Question;
  subQuestion: Question;
}): string => {
  return question.id + '_' + subQuestion.id;
};
