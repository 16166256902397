import {InferType, boolean, mixed, object, string} from 'yup';
import {ValidationMessage} from '$constants/validationMessage';
import {MEMBERSHIP_VALIDATION_REGEX} from '$constants/regex';

export const patientInsuranceDetailFormSchema = object({
  selfPaying: boolean().required(ValidationMessage.SelectOption).nullable(),
  paymentMode: string()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .notRequired(),
  insuranceId: mixed().when('selfPaying', {
    is: false,
    then: schema => schema.required(ValidationMessage.InsuranceRequired),
    otherwise: schema => schema.notRequired(),
  }),
  membershipNumber: string()
    .trim()
    .when(['selfPaying', 'insuranceId'], {
      is: (selfPaying: boolean, insuranceId: string) =>
        !selfPaying && insuranceId,
      then: schema =>
        schema
          .required(ValidationMessage.MembershipRequired)
          .matches(
            MEMBERSHIP_VALIDATION_REGEX,
            ValidationMessage.MembershipInvalid
          ),
      otherwise: schema => schema.notRequired(),
    }),
});

export type PatientInsuranceDetailFormData = InferType<
  typeof patientInsuranceDetailFormSchema
>;
