export const enum RoutePath {
  Index = '/',
  Booking = '/booking',
  Login = '/login',
  ResetPassword = '/reset-password',
  Admin = '/admin',
  FrontDeskCalendar = '/calendar',
  FrontDeskConfirmBooking = '/calendar/confirm-booking',
  FrontDeskAppointmentList = '/calendar/appointments',
  FrontDeskPendingRequests = '/calendar/pending-requests',
  FrontDeskBookingDetails = '/calendar/booking-details',
  FrontDeskRescheduleBooking = '/calendar/reschedule-booking',
  FrontDeskPatientList = '/patient-list',
  FrontDeskPatientListArchived = '/patient-list/archived',
}
