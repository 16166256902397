import './styles.scss';
import {useDropzone} from 'react-dropzone';
import {FolderPlusIcon} from '$assets/svgs';
import {FormikErrors} from 'formik';
import {DocumentUploadFormData} from './schema';
import {
  ACCEPTED_FILES,
  MAX_FILE_SIZE,
  MAX_FILES,
} from '$modules/booking/constant';
import {FileSizeValidationMessage} from '$constants/validationMessage';

interface DropareaProps {
  name: string;
  values: DocumentUploadFormData;
  handleFieldChange: (fieldId: string, value: File) => void;
  errors: FormikErrors<DocumentUploadFormData>;
  setErrors: (errors: FormikErrors<DocumentUploadFormData>) => void;
}

function Droparea(props: DropareaProps) {
  const {errors, setErrors, handleFieldChange, values, name} = props;

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    accept: ACCEPTED_FILES,
    onDrop(acceptedFiles, fileRejections) {
      if (fileRejections.length) {
        setErrors({
          ...errors,
          document: FileSizeValidationMessage.FileSizeExceed,
        });
      } else {
        setErrors({
          ...errors,
          document: '',
        });
        handleFieldChange(name, acceptedFiles[0]);
      }
    },
  });

  const hasError = errors.document;

  return (
    <div className="flex flex-col justify-center items-center">
      <section className="container">
        <div
          {...getRootProps({
            className: `document-dropzone ${hasError ? 'error' : ''}`,
          })}
        >
          <FolderPlusIcon />
          <input {...getInputProps()} />
          <div className="document-dropzone-text">
            {values.document ? (
              <p className="document-dropzone-title">
                {(values.document as File).name}
              </p>
            ) : (
              <>
                <p
                  className={`document-dropzone-title ${hasError ? 'error' : ''}`}
                >
                  Click or drag and drop your document
                </p>
                <p
                  className={`document-dropzone-subtitle ${hasError ? 'error' : ''}`}
                >
                  PNG, JPG, PDF (Max 5MB)
                </p>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Droparea;
