import {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';

import {getBookingTypes} from '$api/bookingFlow';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RenderSVG from '$components/RenderSVG';
import theme from '$modules/booking/theme';

import ActionCard from '../components/ActionCard';
import styles, {bookingSelectionViewStyles} from '../styles';
import {BookingData, BookingSelectionViewChoices, BookingView} from '../types';
import {Updater} from 'use-immer';
import Footer from '../components/Footer';

interface BookingSectionViewProps {
  bookingChoices: BookingSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function BookingSectionView(props: BookingSectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [showAllDescription, setShowAllDescription] = useState(false);

  const {data, isLoading} = useQuery({
    queryKey: ['booking-types'],
    queryFn: getBookingTypes,
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const actionCards = data.data.map(info => {
    const handleClick = () => {
      setBookingChoices(draft => {
        draft.booking = info;
      });

      setActiveView(BookingView.AppointmentSelectionView);
    };

    return info.title !== 'Surgery' ? (
      <ActionCard
        key={info.id}
        active={bookingChoices.booking?.id === info.id}
        icon={info.image ? <RenderSVG svg={info.image} /> : null}
        title={info.title}
        description={info.description}
        onClick={handleClick}
        disabled={!info.enable}
      />
    ) : null;
  });

  return (
    <Box sx={styles.sectionWrapper} flexDirection="column" gap={5}>
      <Stack gap={6} sx={bookingSelectionViewStyles.section}>
        <Box>
          <Typography variant="h2" sx={styles.title} textAlign="center">
            What would you like to book?
          </Typography>
          {isLg || showAllDescription ? (
            <Typography variant="body1" sx={styles.subTitle} textAlign="center">
              At Stoneygate, we offer a variety of services to accommodate all
              our patients' needs. Choose the option that best fits your
              requirements.
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={styles.subTitle}
                textAlign="center"
              >
                At Stoneygate, we offer a variety...{' '}
              </Typography>
              <Typography
                variant="body1"
                sx={styles.subTitleSeeAll}
                textAlign="center"
                onClick={() => setShowAllDescription(true)}
              >
                see all
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={styles.scrollSection}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          {actionCards}
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer />
      </Box>
    </Box>
  );
}

export default BookingSectionView;
