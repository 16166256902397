import {useFormik} from 'formik';
import LoginComponent from './component';
import {PatientLoginFormData, loginFormSchema} from './schema';
import {usePatientLogin} from '../hooks';
import {useNavigate} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';

function Login() {
  const navigate = useNavigate();
  const {sendSignInRequest, disableSubmit, isPending} = usePatientLogin();

  const handleSubmit = (data: PatientLoginFormData) => {
    sendSignInRequest(data);
  };

  const formik = useFormik<PatientLoginFormData>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: handleSubmit,
  });

  const handleForgotPasswordClick = () => {
    navigate(
      getRoute(RouteSection.PatientInvite, RoutePath.patient.forgotPassword)
    );
  };

  return (
    <LoginComponent
      onForgotPassword={handleForgotPasswordClick}
      disableSubmit={disableSubmit}
      isSignInLoading={isPending}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
    />
  );
}

export default Login;
