import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LeftAngleArrowIcon from '$assets/svgs/left-angle-arrow.svg?react';
import RightArrowIcon from '$assets/svgs/right-arrow.svg?react';
import {Color} from '$constants/style';
import styles from './styles';
import DisclaimerComponent from '../DisclaimerComponent';

interface FooterProps {
  disableBack?: boolean;
  disableContinue?: boolean;
  continueLoading?: boolean;
  onBackClick?: () => void;
  onContinueClick?: () => void;
  continueButtonLabel?: string;
}

function Footer(props: FooterProps) {
  const {
    disableBack = false,
    disableContinue = false,
    continueLoading = false,
    onBackClick,
    onContinueClick,
    continueButtonLabel,
  } = props;

  return (
    <Box sx={styles.footerContainer}>
      <Box sx={styles.footerButtonContainer}>
        {onBackClick && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<LeftAngleArrowIcon />}
            sx={styles.footerActionButton}
            disabled={disableBack}
            onClick={onBackClick}
          >
            Back
          </Button>
        )}
        {onContinueClick && (
          <Button
            variant="contained"
            color="primary"
            endIcon={
              continueLoading ? (
                <CircularProgress
                  size="1rem"
                  sx={{color: Color.BlackOpacity40}}
                />
              ) : (
                <RightArrowIcon />
              )
            }
            sx={styles.footerActionButton}
            disabled={disableContinue}
            onClick={onContinueClick}
          >
            {continueButtonLabel ? continueButtonLabel : 'Continue'}
          </Button>
        )}
      </Box>

      <DisclaimerComponent />
    </Box>
  );
}

export default Footer;
