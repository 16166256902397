import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './styles';
import parentStyles from '../../styles';
import {NO_PREFERENCE_CONSULTANT} from '../../constant';
import {ChoicesConfirmationViewChoices} from '../../types';
import {dateToDDMMYYYY, formatTimeAMPM} from '$utils/date';

interface AppointmentChoicesProps {
  bookingChoices: ChoicesConfirmationViewChoices;
}

function AppointmentChoices(props: AppointmentChoicesProps) {
  const {bookingChoices} = props;

  const dateString = dateToDDMMYYYY(bookingChoices.date);

  const consultantNoPreference =
    bookingChoices.consultant.fullName === NO_PREFERENCE_CONSULTANT.fullName;
  const consultantName = consultantNoPreference
    ? ''
    : bookingChoices.consultant.fullName;

  const startTimeString = formatTimeAMPM(bookingChoices?.slot?.startTime);
  const endTimeString = formatTimeAMPM(bookingChoices?.slot?.endTime);

  const timeString = `${startTimeString} - ${endTimeString}`;

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={parentStyles.bookingSubHeading}>
        Confirm your choices
      </Typography>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            What would you like to book?
          </Typography>
          <Typography variant="subtitle2" sx={styles.subTitle}>
            {bookingChoices.booking.title}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            What type of care are you looking for?
          </Typography>
          <Typography variant="subtitle2" sx={styles.subTitle}>
            {bookingChoices.appointment.title}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Consultant</Typography>
          <Typography variant="subtitle2" sx={styles.subTitle}>
            {consultantNoPreference ? '-' : consultantName}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Date</Typography>
          <Typography variant="subtitle2" sx={styles.subTitle}>
            {dateString}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Appointment time</Typography>
          <Typography variant="subtitle2" sx={styles.subTitle}>
            {timeString}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default AppointmentChoices;
