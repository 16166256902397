import {InferType, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const loginFormSchema = object().shape(
  {
    email: string()
      .trim()
      .max(350, ValidationMessage.EmailExceedCharacterLimit)
      .email(ValidationMessage.EmailInvalid)
      .required(ValidationMessage.EmailRequired),
    password: string().trim().required(ValidationMessage.PasswordRequired),
  },
  [['email', 'password']]
);

export type PatientLoginFormData = Required<InferType<typeof loginFormSchema>>;
