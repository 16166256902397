import theme from '$modules/booking/theme';
import {SxProps} from '@mui/material';
import MUITextareaAutosize from '@mui/material/TextareaAutosize';
import styled from '@mui/material/styles/styled';
import {TextAreaProps} from '.';

export const TextareaAutosize = styled(MUITextareaAutosize)<TextAreaProps>(({
  theme,
}) => {
  return `
  flex: 1;

  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: ${theme.palette.text.tertiary};
  background: #FCFCFD;
  border: 1px solid ${theme.palette.grey[200]};
  opacity: 1;
  pointer-events: auto;

  resize: none;

  &:hover {
    border-color: ${theme.palette.primary.main}
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  // Placeholder color
  &::placeholder {
    color: ${theme.palette.text.disabled};
  }
`;
});

export const Container = styled('div')`
  position: relative;
  display: flex;
`;

export const IconWrap = styled('div')(
  ({theme}) => `
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.error.main};
`
);

const styles: Record<string, SxProps> = {
  disabledTextArea: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  errorTextArea: {
    border: `1px solid ${theme.palette.error.main}`,

    '&:hover': {
      borderColor: theme.palette.error.main,
    },

    '&:focus': {
      borderColor: theme.palette.error.main,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingY: '8px',
    position: 'absolute',
    right: '8px',
    color: theme.palette.error.main,
  },
};

export default styles;
