import {ValidationMessage} from '$constants/messages';
import {object, string, InferType} from 'yup';

export const emailSchema = string()
  .trim()
  .max(350, ValidationMessage.EmailExceedCharacterLimit)
  .email(ValidationMessage.EmailInvalid)
  .required(ValidationMessage.EmailRequired);

export const loginSchema = object({
  email: emailSchema,
  password: string().required(ValidationMessage.PasswordRequired),
});

export type LoginFormData = InferType<typeof loginSchema>;
