import {ToastType} from '$components/Toast/constant';
import toast from '$utils/toast';

export const enum ValidationMessage {
  NHSNumberInvalid = 'Enter a valid NHS number',
  NHSNumberLength = 'NHS number must be exactly 10 digits',
  EmailInvalid = 'Please enter a valid email address',
  EmailExceedCharacterLimit = 'Email address must not exceed 350 characters',
  EmailRequired = 'Email address is required',
  PasswordInvalid = 'Please enter a valid password',
  PasswordRequired = 'Password is required',
  FirstNameRequired = 'First name is required',
  FirstNameExceedCharacterLimit = 'First name must not exceed 150 characters',
  FirstNameInvalid = 'Please enter a valid first name',
  LastNameRequired = 'Last name is required',
  LastNameExceedCharacterLimit = 'Last name must not exceed 150 characters',
  LastNameInvalid = 'Please enter a valid last name',
  NameInvalidCharacter = "Name must contain alphabets and can include ., _,' or -.",
  PostcodeInvalid = 'Please enter a valid UK postcode',
  PostcodeExceedCharacterLimit = 'Postcode must not exceed 7 characters',
  PostcodeRequired = 'Postcode is required',
  AddressLine1Required = 'Address line 1 is required',
  AddressLine1ExceedCharacterLimit = 'Address line 1 must not exceed 150 characters',
  AddressLine2ExceedCharacterLimit = 'Address line 2 must not exceed 150 characters',
  CityRequired = 'Town or city is required',
  CityExceedCharacterLimit = 'Town or city name must not exceed 50 characters',
  CountryRequired = 'Country is required',
  CountryExceedCharacterLimit = 'Country name must not exceed 60 characters',
  PhoneNumberRequired = 'Phone number is required',
  PhoneNumberInvalid = 'Please enter a valid phone number',
  GenderRequired = 'Gender is required',
  TitleRequired = 'Title is required',
  RelationshipRequired = 'Relationship with patient is required',
  DateOfBirthRequired = 'Date of birth is required',
  DateOfBirthFutureDate = 'Date of birth cannot be future date',
  BookingReasonRequired = 'Booking reason is required',
  ReferralFileRequired = 'Referral file is required',
  ReferralFileSize = 'File size must not exceed 5MB',
  SelectOption = 'Please select one of the options',
  ConfirmPasswordMatch = 'Password and confirm password does not match',
  ConfirmResetPasswordMatch = 'New password and confirm password do not match',
  ConfirmPasswordRequired = 'Confirm password is required',
  PreferredContactMethodRequired = 'Preferred contact method is required',
  UserRoleRequired = 'User role is required',
  MembershipRequired = 'Membership is required',
  MembershipInvalid = 'Membership is invalid',
  RegistrationDateRequired = 'Registration date is required',
  DocumentNameRequired = 'Document name is required',
  DocumentPerformedByRequired = 'Performed by is required',
  DocumentRequired = 'Document is required',
  InsuranceRequired = 'Insurance is required',
}

export const technicalGlitchToast = () =>
  toast(
    ToastType.Error,
    'Ops! We encountered a technical glitch',
    'Please try submitting your request shortly. Sorry for the inconvenience!'
  );

export const networkErrorToast = () =>
  toast(
    ToastType.Error,
    'Network Error',
    'Please check internet connection and try again'
  );

export const invalidLinkToast = () =>
  toast(
    ToastType.Error,
    'Invalid link',
    'Your verification link is expired or Invalid',
    'invalid-link-toast'
  );
