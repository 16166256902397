import React from 'react';
import '../../../../styles/_frontdesk.scss';
import {
  BankIcon,
  CalendarPlusIcon,
  ContactDetailIcon,
  UsersEditIcon,
} from '$assets/svgs';

const EditBookingSkeleton: React.FC = () => {
  return (
    <div
      className="w-full !h-full p-6 space-y-6 flex flex-col justify-between"
      style={{backgroundColor: '#f6f6ff'}}
    >
      <div className="mt-14 space-y-2 ml-6">
        <div className="page-title">Edit booking details</div>
        <div className="flex items-center space-x-3">
          <div className="h-7 bg-gray-300 rounded w-1/5 animate-pulse"></div>
          <div className="h-8 w-20 bg-gray-300 rounded animate-pulse"></div>
        </div>
      </div>

      <div className="flex-grow">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 ml-6 mr-4">
          <div className="space-y-8">
            <div className="p-8 bg-white rounded-xl shadow space-y-4 w-full h-fit flex flex-col">
              <div className="w-full h-fit flex gap-1">
                <CalendarPlusIcon />
                <h2 className="page-title__card-title">Appointment Details</h2>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
                <div className="h-4 bg-gray-300 rounded w-1/2 animate-pulse"></div>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="h-4 bg-gray-300 rounded w-1/3 animate-pulse"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
              </div>
            </div>
            <div className="p-8 bg-white rounded-xl shadow space-y-4 w-full h-fit flex flex-col">
              <div className="flex flex-col space-y-2">
                <div className="w-full h-fit flex gap-1">
                  <BankIcon />
                  <h2 className="page-title__card-title">Financial Details</h2>
                </div>
                <div className="h-4 bg-gray-300 rounded w-1/2 animate-pulse"></div>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="h-4 bg-gray-300 rounded w-1/3 animate-pulse"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
              </div>
            </div>
          </div>

          <div className="p-8 bg-white rounded-xl shadow space-y-4 w-full flex flex-col h-full">
            <div className="w-full h-fit flex gap-1">
              <UsersEditIcon />
              <h2 className="page-title__card-title">Pateint Details</h2>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="h-4 bg-gray-300 rounded w-1/2 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-2/5 animate-pulse"></div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="h-4 bg-gray-300 rounded w-7/10 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-3/5 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-2/5 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
            </div>
            <div className="flex flex-col space-y-2"></div>
            <div className="flex flex-col space-y-2">
              <div className="w-full h-fit flex gap-1">
                <ContactDetailIcon />
                <h2 className="page-title__card-title">Contact Details</h2>
              </div>
              <div className="h-4 bg-gray-300 rounded w-7/10 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-3/5 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-2/5 animate-pulse"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center mt-6">
        <div className="flex space-x-8 ml-8 mb-8">
          <div className="h-10 w-24 bg-gray-300 rounded animate-pulse"></div>
          <div className="h-10 w-24 bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div>
          <div className="h-10 w-24 bg-gray-300 rounded animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default EditBookingSkeleton;
