function SectionLoading() {
  return (
    <div className="animate-pulse flex flex-col mb-8">
      <div className="w-1/4 bg-slate-200 h-6 rounded-md mb-6"></div>
      <div className="w-full bg-slate-200 h-20 rounded-md mb-4"></div>
      <div className="w-full bg-slate-200 h-20 rounded-md mb-4"></div>
    </div>
  );
}

export default SectionLoading;
