import {FormikErrors, FormikTouched} from 'formik';
import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import Header from '../Header';
import {PatientRegisterFormData} from './schema';

interface RegisterComponentProps {
  disableSubmit: boolean;
  isContinueLoading: boolean;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  values: PatientRegisterFormData;
  errors: FormikErrors<PatientRegisterFormData>;
  touched: FormikTouched<PatientRegisterFormData>;
}

function RegisterComponent(props: RegisterComponentProps) {
  const {
    disableSubmit,
    isContinueLoading,
    handleBlur,
    handleChange,
    onSubmit,
    values,
    errors,
    touched,
  } = props;

  return (
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="mb-8 px-6">
        <h1 className="font-bold text-2xl mb-3 text-secondary">
          Welcome to Eye Op patient platform!
        </h1>
        <p className="font-normal text-base text-tertiary">
          Use the email you entered during the booking process.
        </p>
      </div>
      <form className="flex-1 flex flex-col px-6">
        <div className="flex-1">
          <label className="mb-2 font-normal text-base text-tertiary">
            Email
          </label>
          <Input
            name="email"
            placeholder="Email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.email}
            error={Boolean(errors.email)}
            helperText={errors.email && touched.email ? errors.email : ''}
          />
        </div>
        <div className="pb-8">
          <FrontdeskButton
            title="Continue"
            className="w-full !p-3 !font-semibold"
            disabled={disableSubmit}
            loading={isContinueLoading}
            onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
}

export default RegisterComponent;
