import {useLocation, useParams} from 'react-router-dom';
import EditBookingComponent from './component';
import {useFormik} from 'formik';
import {EditBookingFormData, editBookingFormSchema} from './schema';
import {usePreferredTimeHook} from '../hooks/usePreferredTimeHook';
import FetchError from '$components/FetchError';
import {Box, MenuItem, Typography} from '@mui/material';
import {consultantSelectionViewStyles} from '$modules/booking/styles';
import {useAppointmentTypeHook} from '../OfflineBooking/hooks/useAppointmentTypeHook';
import {useCareTypeHook} from '../OfflineBooking/hooks/useCareTypeHook';
import {Consultant, Insurance, Payment} from '$modules/booking/types';
import {getNameInitials} from '$modules/booking/utils';
import {Care, PreferredTime, SubCare} from './types';
import {Color} from '$constants/style';
import {useInsuranceTypeHook} from '../OfflineBooking/hooks/useInsuranceTypeHook';
import {usePaymentMethodHook} from '../OfflineBooking/hooks/usePaymentMethodHook';
import useModifyAppointment from '../hooks/useEditAppointmentHook';
import {useConsultants} from '$modules/frontdesk/hooks';
import {useCities, useCountries} from '$modules/booking/hooks';
import EditBookingSkeleton from './EditBookingSkeleton';

const EditBookingContainer = () => {
  const params = useParams();
  const appointmentId = Number(params.id);
  const location = useLocation();
  const {isPending: isModifyMutatePending, modifyMutate} =
    useModifyAppointment(appointmentId);
  const bookingDetails = location.state;
  const {
    appointment: {
      sub_care_type,
      appointment_type,
      care_type,
      date,
      doctor,
      preferred_time,
      referred_by_physician,
      reason,
      self_booking,
      self_paying,
      payment_mode,
      insurance,
      membership_no,
      preferred_point_of_contact,
      country_code,
      contact_mobile_number,
      contact_email,
      patient,
      care_taker,
    },
  } = bookingDetails;

  const {
    id: patientId,
    prefix,
    first_name,
    last_name,
    dob,
    gender,
    country_code: patientCountryCode,
    mobile_number,
    email,
    address,
  } = patient;

  const {id: addressId, line1, line2, city, country, postcode} = address;

  const formik = useFormik<EditBookingFormData>({
    initialValues: {
      appointment: {
        sub_care_type_id: sub_care_type?.id || null,
        appointment_type_id: appointment_type?.id || null,
        care_type_id: care_type?.id || null,
        date: date || '',
        doctor_id: doctor?.id || null,
        preferred_time: preferred_time || 'All time',
        referred_by_physician: referred_by_physician || false,
        reason: reason || '',
        self_booking: self_booking || false,
        self_paying: self_paying || false,
        payment_mode: payment_mode || '',
        insurance_id: insurance?.id || null,
        membership_no: membership_no || '',
        preferred_point_of_contact: preferred_point_of_contact || '',
        country_code: country_code || '',
        contact_mobile_number: contact_mobile_number || '',
        contact_email: contact_email || '',
        patient: {
          id: patientId || null,
          prefix: prefix || '',
          first_name: first_name || '',
          last_name: last_name || '',
          dob: dob || '',
          gender: gender || '',
          country_code: patientCountryCode || '',
          mobile_number: mobile_number || '',
          email: email || '',
          address: {
            id: addressId || null,
            line1: line1 || '',
            line2: line2 || '',
            city: city || '',
            country: country || '',
            postcode: postcode || '',
          },
        },
        care_taker: care_taker
          ? {
              id: care_taker?.id || null,
              first_name: care_taker?.first_name || '',
              last_name: care_taker?.last_name || '',
              country_code: care_taker?.country_code || '',
              mobile_number: care_taker?.mobile_number || '',
              email: care_taker?.email || '',
              relationship: care_taker?.relationship || null,
            }
          : null,
      },
    },
    validationSchema: editBookingFormSchema,
    onSubmit: values => {
      const payload = {appointmentId, body: values};
      modifyMutate(payload);
    },
  });

  const {isPending: isPreferredTimePending, data: preferredTimes} =
    usePreferredTimeHook();
  const {isPending: isCareTypePending, data: careTypes} =
    useAppointmentTypeHook(appointment_type.id);
  const {isPending: isSubCareTypePending, data: subCareTypes} = useCareTypeHook(
    care_type.id
  );
  const {
    isLoading: isConsultantPending,
    consultants,
    isError: isConsultantError,
  } = useConsultants({
    subCareId: formik?.values?.appointment?.sub_care_type_id,
    appointmentTypeId: formik?.values?.appointment?.appointment_type_id,
  });
  const {isPending: isInsuranceTypePending, data: insuranceTypes} =
    useInsuranceTypeHook();
  const {isPending: isPaymentMethodTypePending, data: paymentMethods} =
    usePaymentMethodHook();

  const {countries} = useCountries();
  const {cities} = useCities(
    formik.values?.appointment?.patient?.address?.country
  );

  const isPending =
    isPreferredTimePending ||
    isCareTypePending ||
    isSubCareTypePending ||
    isConsultantPending ||
    isInsuranceTypePending ||
    isPaymentMethodTypePending;
  const isError =
    !preferredTimes ||
    !careTypes ||
    !subCareTypes ||
    !consultants ||
    !insuranceTypes ||
    !paymentMethods ||
    isConsultantError;

  if (isPending) return <EditBookingSkeleton />;
  if (isError) return <FetchError />;

  const {setFieldValue, setFieldTouched} = formik;

  const handleDateChange = (date: Date | null) => {
    formik.setFieldValue('appointment.date', date);
  };

  const handleSelfPayingChange = (value: string) => {
    setFieldValue('appointment.self_paying', value === 'Yes' ? true : false);
  };

  const handlePaymentMethodChange = (value: string) => {
    if (!value) {
      if (formik.values.appointment.self_paying) {
        setFieldValue('appointment.payment_mode', '');
      } else {
        setFieldValue('appointment.insurance_id', '');
      }
      return;
    }
    if (!formik.values.appointment.self_paying) {
      const item = insuranceTypes.data.find(it => it.id.toString() === value);
      if (item) {
        setFieldValue('appointment.insurance_id', item.id.toString());
        setFieldValue('appointment.payment_mode', '');
      } else {
        setFieldValue('appointment.insurance_id', '');
      }
    } else {
      const item = paymentMethods.data.find(it => it.id.toString() === value);
      if (item) {
        setFieldValue('appointment.payment_mode', item.name);
        setFieldValue('appointment.insurance_id', '');
        setFieldValue('appointment.membership_no', '');
      } else {
        setFieldValue('appointment.payment_mode', '');
      }
    }
  };

  const handleFieldChange = (
    field: string,
    value: number | string | boolean | Date
  ) => {
    if (field === 'preferred_time') {
      const preferredTime = preferredTimes.data.find(ele => ele.id === value);
      const preferredTimeName = preferredTime?.name || 'All time';
      formik.setFieldValue('appointment.preferred_time', preferredTimeName);
    } else {
      field = `appointment.${field}`;
      setFieldValue(field, value);

      if (field === 'appointment.patient.address.country') {
        setFieldValue('appointment.patient.address.city', '');
        setFieldTouched('appointment.patient.address.city', false);
      }
    }
  };

  const renderPreferredTimeMenuItem = (item: PreferredTime) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.display_name}</span>
      </MenuItem>
    );
  };

  const renderPreferredTimeValue = (value: string) => {
    if (!value) return 'Select preferred time';
    const item = preferredTimes?.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.display_name}</span>
      </Box>
    );
  };

  const renderCareTypeMenuItem = (item: Care) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.title}</span>
      </MenuItem>
    );
  };

  const renderCareTypeValue = (value: string) => {
    if (!value) return 'Select appointment type';
    const item = careTypes.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.title}</span>
      </Box>
    );
  };

  const renderSubCareTypeMenuItem = (item: SubCare) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.menuItemContainer}
      >
        <span>{item.title}</span>
      </MenuItem>
    );
  };

  const renderSubCareTypeValue = (value: string) => {
    if (!value) return 'Select surgery type';
    const item = subCareTypes.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return (
      <Box sx={consultantSelectionViewStyles.menuItemContainer}>
        <span>{item.title}</span>
      </Box>
    );
  };

  const renderConsultantMenuItem = (item: Consultant) => {
    return (
      <MenuItem
        key={item.id}
        value={item.id.toString()}
        sx={consultantSelectionViewStyles.valueContainer}
      >
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </MenuItem>
    );
  };

  const renderConsultantValue = (value: string) => {
    if (!value) return 'Select a consultant';
    const item = consultants.find(it => it.id.toString() === value);
    if (!item) {
      handleFieldChange('doctor_id', '');
      return '';
    }

    return (
      <Box sx={consultantSelectionViewStyles.valueContainer}>
        {item.profileUrl ? (
          <img
            src={item.profileUrl}
            style={consultantSelectionViewStyles.menuItemProfileImage}
          />
        ) : (
          <span style={consultantSelectionViewStyles.menuItemInitial}>
            {getNameInitials(item.fullName)}
          </span>
        )}
        <span>{item.fullName}</span>
      </Box>
    );
  };

  const renderSelfPayingValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };
  const renderSelfPayingItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderSelfBookingValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };
  const renderSelfBookingItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderPreferredPointOfContactValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };
  const renderPreferredPointOfContactItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderGPOrOpticianReferralValue = (value: string) => {
    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select an option' : value}
      </Typography>
    );
  };

  const renderGPOrOpticianReferralItem = (item: string) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  };

  const renderInsuranceMenuItem = (item: Insurance) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderInsuranceValue = (value: string) => {
    if (!value || isNaN(+value)) return 'Select insurer';
    const item = insuranceTypes.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return item.name;
  };

  const renderPaymentMethodMenuItem = (item: Payment) => {
    return (
      <MenuItem key={item.id} value={item.id.toString()}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </MenuItem>
    );
  };

  const renderPaymentMethodValue = (value: string) => {
    if (!value || isNaN(+value)) return 'Select payment method';
    const item = paymentMethods.data.find(it => it.id.toString() === value);
    if (!item) {
      return '';
    }
    return item.name;
  };

  const renderGenderMenuItem = (item: string) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  );

  const renderGenderValue = (value: string) => (
    <Typography color={value ? undefined : Color.TextPrimayPlaceholder}>
      {value}
    </Typography>
  );

  const renderCountryValue = (value: string) => {
    const item = countries.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value ? value : 'Select country'}
      </Typography>
    );
  };

  const renderCountryItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const renderCityValue = (value: string) => {
    const item = cities.find(it => it.code === value);
    if (item) value = item.name;

    return (
      <Typography
        color={value === '' ? Color.TextPrimayPlaceholder : undefined}
      >
        {value === '' ? 'Select City' : value}
      </Typography>
    );
  };

  const renderCityItem = (item: {code: string; name: string}) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  return (
    <EditBookingComponent
      bookingDetails={bookingDetails}
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      preferredTimes={preferredTimes.data}
      consultants={consultants}
      cities={cities}
      countries={countries}
      careTypes={careTypes.data}
      subCareTypes={subCareTypes.data}
      insuranceTypes={insuranceTypes.data}
      paymentMethods={paymentMethods.data}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      handleFieldChange={handleFieldChange}
      handleDateChange={handleDateChange}
      renderConsultantMenuItem={renderConsultantMenuItem}
      renderConsultantValue={renderConsultantValue}
      renderPreferredTimeMenuItem={renderPreferredTimeMenuItem}
      renderPreferredTimeValue={renderPreferredTimeValue}
      renderCareTypeMenuItem={renderCareTypeMenuItem}
      renderCareTypeValue={renderCareTypeValue}
      renderSubCareTypeMenuItem={renderSubCareTypeMenuItem}
      renderSubCareTypeValue={renderSubCareTypeValue}
      renderInsuranceValue={renderInsuranceValue}
      renderInsuranceMenuItem={renderInsuranceMenuItem}
      renderSelfPayingItem={renderSelfPayingItem}
      renderSelfPayingValue={renderSelfPayingValue}
      renderPaymentMethodMenuItem={renderPaymentMethodMenuItem}
      renderPaymentMethodValue={renderPaymentMethodValue}
      handlePaymentMethodChange={handlePaymentMethodChange}
      handleSelfPayingChange={handleSelfPayingChange}
      renderGenderMenuItem={renderGenderMenuItem}
      renderGenderValue={renderGenderValue}
      renderGPOrOpticianReferralItem={renderGPOrOpticianReferralItem}
      renderGPOrOpticianReferralValue={renderGPOrOpticianReferralValue}
      renderSelfBookingItem={renderSelfBookingItem}
      renderSelfBookingValue={renderSelfBookingValue}
      renderPreferredPointOfContactValue={renderPreferredPointOfContactValue}
      renderPreferredPointOfContactItem={renderPreferredPointOfContactItem}
      renderCityItem={renderCityItem}
      renderCityValue={renderCityValue}
      renderCountryItem={renderCountryItem}
      renderCountryValue={renderCountryValue}
      handleSubmit={formik.handleSubmit}
      isModifyMutatePending={isModifyMutatePending}
    />
  );
};

export default EditBookingContainer;
