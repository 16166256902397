import {useFormik} from 'formik';
import ForgotPasswordComponent from './component';
import {ForgotPasswordFormData, forgotPasswordFormSchema} from './schema';
import {useForgotPassword} from '../hooks';

function ForgotPassword() {
  const {sendForgotPasswordRequest, disableSubmit, isPending} =
    useForgotPassword();

  const handleSubmit = (data: ForgotPasswordFormData) => {
    sendForgotPasswordRequest({email: data.email});
  };

  const formik = useFormik<ForgotPasswordFormData>({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordFormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <ForgotPasswordComponent
      disableSubmit={disableSubmit}
      isContinueLoading={isPending}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
    />
  );
}

export default ForgotPassword;
