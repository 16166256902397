import {Updater} from 'use-immer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';

import {getAppointmentTypes} from '$api/bookingFlow';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RenderSVG from '$components/RenderSVG';

import ActionCard from '../components/ActionCard';
import Footer from '../components/Footer';
import styles from '../styles';
import {
  AppointmentSelectionViewChoices,
  BookingData,
  BookingView,
} from '../types';
import {useEffect} from 'react';
import LocalStorage from '$utils/localStore';

interface AppointmentSelectionViewProps {
  bookingChoices: AppointmentSelectionViewChoices;
  setActiveView: React.Dispatch<React.SetStateAction<BookingView>>;
  setBookingChoices: Updater<BookingData>;
}

function AppointmentSelectionView(props: AppointmentSelectionViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const {data, isLoading} = useQuery({
    queryKey: ['appointment-types', bookingChoices.booking.id],
    queryFn: () => getAppointmentTypes(bookingChoices.booking.id),
  });

  useEffect(() => {
    LocalStorage.removeItem(LocalStorage.BOOKING_BLOCKED_SLOT);
    LocalStorage.removeItem(LocalStorage.BOOKING_BLOCKED_DATE);
  }, []);

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const actionCards = data.data.map(info => {
    const handleClick = () => {
      setBookingChoices(draft => {
        draft.appointment = info;
      });

      setActiveView(BookingView.CareSelectionView);
    };

    return (
      <ActionCard
        key={info.id}
        active={bookingChoices.appointment?.id === info.id}
        icon={info.image ? <RenderSVG svg={info.image} /> : null}
        title={info.title}
        description={info.description}
        onClick={handleClick}
        disabled={!info.enable}
      />
    );
  });

  const handleBackClick = () => {
    setActiveView(BookingView.BookingSelectionView);
  };

  return (
    <Box sx={styles.sectionWrapper} flexDirection="column" gap={5}>
      <Stack gap={6} sx={styles.section}>
        <Box>
          <Typography variant="h2" sx={styles.title} textAlign="center">
            What type of care are you looking for?
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={styles.scrollSection}
          flexDirection="column"
          gap={3}
        >
          {actionCards}
        </Box>
      </Stack>
      <Box sx={styles.footerSection}>
        <Footer onBackClick={handleBackClick} />
      </Box>
    </Box>
  );
}

export default AppointmentSelectionView;
