import {useState} from 'react';
import OTPVerficationComponent from './component';
import {useVerifyOTP} from '../hooks';
import {Navigate, useLocation} from 'react-router-dom';
import {RouteSection, getRoute} from '$utils/route';
import {RoutePath} from '$constants/routes';

function OTPVerfication() {
  const {state} = useLocation();
  const email = state?.email as string | undefined;

  const [otpCode, setOTPCode] = useState<number>();
  const {sendOtpRequest, disableSubmit, isPending} = useVerifyOTP();

  const handleOTPSubmit = () => {
    if (email && otpCode) {
      sendOtpRequest(email, otpCode);
    }
  };

  const handleOTPChange = (code: string) => {
    setOTPCode(Number(code));
  };

  const validateOTPChar = (text: string) => {
    return !Number.isNaN(Number(text));
  };

  if (!email) {
    return (
      <Navigate
        to={getRoute(RouteSection.PatientInvite, RoutePath.patient.register)}
      />
    );
  }

  return (
    <OTPVerficationComponent
      otpCode={otpCode}
      continueDisabled={disableSubmit}
      continueLoading={isPending}
      onContinueClick={handleOTPSubmit}
      onOTPChange={handleOTPChange}
      validateOTPChar={validateOTPChar}
    />
  );
}

export default OTPVerfication;
