import React from 'react';
import {Button} from '@mui/material';
import FrontdeskButton from '$components/FrontdeskButton';
import './styles.scss';
import {BUTTON_TEXTS} from '$modules/frontdesk/PatientList/constant';

interface QuestionnaireFooterProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  handleSubmit: () => void;
  isFormValid: boolean;
  disableSubmit: boolean;
}

const QuestionnaireFooter: React.FC<QuestionnaireFooterProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
  handleSubmit,
  isFormValid,
  disableSubmit,
}) => {
  return (
    <footer className="questionnairepage-details__footer">
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <div className="questionnairepage-details__footer-buttons">
        {currentPage > 1 && (
          <Button
            variant="outlined"
            disabled={disableSubmit}
            onClick={() => handlePageChange(currentPage - 1)}
            className="questionnairepage-details__back-button"
          >
            {BUTTON_TEXTS.BACK}
          </Button>
        )}
        <FrontdeskButton
          title={currentPage === totalPages ? 'Submit' : 'Continue'}
          disabled={!isFormValid || disableSubmit}
          onClick={handleSubmit}
        />
      </div>
    </footer>
  );
};

export default QuestionnaireFooter;
