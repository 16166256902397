import {Box, Button, Modal, Typography} from '@mui/material';
import {AppSummaryViewStyles as styles} from '../../styles';

interface LeaveBookingModalProps {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

function LeaveBookingModal(props: LeaveBookingModalProps) {
  const {open, onClose, onLeave} = props;

  return (
    <Modal open={open} onClose={onClose} sx={styles.bookingModal}>
      <Box sx={styles.bookingModalWrapper} alignItems="flex-start">
        <Box>
          <Typography sx={styles.bookingModalTitle}>
            Do you want to leave the booking?
          </Typography>
          <Typography sx={styles.bookingModalDescription}>
            If you go back to the homepage now, all your entered{' '}
            <Typography
              component="span"
              sx={styles.bookingModalBoldDescription}
            >
              booking information will be lost.
            </Typography>
          </Typography>
          <Typography sx={styles.bookingModalDescription}>
            Are you sure you want to leave this page?
          </Typography>
        </Box>
        <Box sx={styles.bookingModalActionButtonContainer}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Stay on this page
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={styles.bookingModalActionButtonSecondary}
            onClick={onLeave}
          >
            Go to Homepage
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default LeaveBookingModal;
