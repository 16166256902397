import {InferType, object, string} from 'yup';
import {ValidationMessage} from '$constants/messages';

export const registerPatientFormSchema = object().shape({
  email: string()
    .trim()
    .max(350, ValidationMessage.EmailExceedCharacterLimit)
    .email(ValidationMessage.EmailInvalid)
    .required(ValidationMessage.EmailRequired),
});

export type PatientRegisterFormData = Required<
  InferType<typeof registerPatientFormSchema>
>;
