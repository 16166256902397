import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {useQuery} from '@tanstack/react-query';
import {getAppointmentTypes} from '$api/bookingFlow';
import FetchError from '$components/FetchError';
import Loading from '$components/Loading';
import RadioBox from '$components/RadioBox';
import CareTypeSection from './CareTypeSection';
import {BookingData, BookingDataUpdater} from '../../types';

interface AppointmentTypeSectionProps {
  formChoices: BookingData;
  setFormChoices: BookingDataUpdater;
}

function AppointmentTypeSection(props: AppointmentTypeSectionProps) {
  const {formChoices, setFormChoices} = props;

  const {data, isLoading} = useQuery({
    queryKey: ['appointment-types', formChoices.booking!.id],
    queryFn: () => getAppointmentTypes(formChoices.booking!.id),
  });

  if (isLoading) return <Loading />;
  if (!data) return <FetchError />;

  const handleAppointmentChange: RadioGroupProps['onChange'] = e => {
    const item = data.data.find(it => it.id.toString() === e.target.value);
    setFormChoices(draft => {
      draft.appointment = item || undefined;
    });
  };

  const appointmentTypeButtons = data.data.map(item => (
    <RadioBox
      key={item.id}
      value={item.id.toString()}
      label={item.title}
      disabled={!item.enable}
    />
  ));

  return (
    <>
      <FormControl>
        <FormLabel id="appointment-type1">
          <Typography variant="h4">
            What kind of appointment would you like to book?
          </Typography>
        </FormLabel>
        <RadioGroup
          value={formChoices.appointment?.id}
          name="radio-buttons-group"
          onChange={handleAppointmentChange}
        >
          {appointmentTypeButtons}
        </RadioGroup>
      </FormControl>
      {formChoices.booking && formChoices.appointment && (
        <CareTypeSection
          formChoices={formChoices}
          setFormChoices={setFormChoices}
        />
      )}
    </>
  );
}

export default AppointmentTypeSection;
