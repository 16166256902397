import {ToastType} from '$components/Toast/constant';
import {networkErrorToast} from '$constants/messages';
import toast from '$utils/toast';

export const downloadAttachment = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      return networkErrorToast();
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = () => {
      const link = document.createElement('a');
      link.href = reader.result as string;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.readAsDataURL(blob);
  } catch {
    return toast(
      ToastType.Error,
      'Download Attachment',
      'Failed to download attachment'
    );
  }
};

export const viewAttachment = (documentUrl: string) => {
  if (documentUrl) {
    window.open(documentUrl, '_blank');
  }
};
