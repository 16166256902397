import {useState} from 'react';
import {useParams} from 'react-router-dom';
import Allergies from './Allergies';
import MedicationList from './MedicationList';
import MedicationHistory from './MedicationHistory/MedicationHistory';
import './styles.scss';
import {BookmarkIcon, EditIcon, HospitalIcon} from '$assets/svgs';
import {usePatientClinicalDetailsHook} from '../hooks/usePatientClinicalDetailsHook';
import FetchError from '$components/FetchError';
import ClinicalDetailsDialog from './ClinicalDetailsDialog';
import Documents from './Documents';
import ClinicalDetailsSkeleton from './ClinicalDetailsSkeleton';
import DocumentSkeleton from './Documents/DocumentSkeleton';
import AllergySkeleton from './Allergies/AllergySkeleton';

const ClinicalDataComponent = () => {
  const params = useParams();
  const patientId = Number(params.patientId);
  const [clinicalDetailsDialogOpen, setClinicalDetailsDialogOpen] =
    useState<boolean>(false);

  const handleClinicalDetailsDialog = () => {
    setClinicalDetailsDialogOpen(!clinicalDetailsDialogOpen);
  };

  const {
    isPending,
    isError,
    data: patientClinicalDetails,
  } = usePatientClinicalDetailsHook(patientId);
  if (isError) return <FetchError />;

  return (
    <>
      <div className="clinical-data overflow-auto">
        <h1 className="clinical-data__heading">Clinical Data</h1>
        <div className="clinical-data__body overflow-auto">
          {isPending ? (
            <ClinicalDetailsSkeleton />
          ) : (
            <div className="clinical-data__clinical-details col-span-2 row-span-2">
              <div className="header">
                <div className="card-title-container">
                  <HospitalIcon />
                  <span className="card-title">Clinical Details </span>
                </div>
                <EditIcon
                  onClick={handleClinicalDetailsDialog}
                  className="btn-icon"
                />
              </div>
              <div className="clinical-data__clinical-details__list">
                <div className="clinical-data__clinical-details__list__container">
                  <div className="list-title">Diagnosis</div>
                  <div className="list-details">
                    {patientClinicalDetails?.data.diagnosis}
                  </div>
                </div>
                <div className="clinical-data__clinical-details__list__container">
                  <div className="list-title">Consultant</div>
                  <div className="list-details">
                    {patientClinicalDetails?.data.doctor_full_name}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isPending ? <DocumentSkeleton /> : <Documents />}
          {isPending ? <AllergySkeleton /> : <Allergies />}
          <div className="clinical-data__medical-history col-span-3 row-span-3 row-start-3">
            <div className="card-title-container-sec-row">
              <BookmarkIcon />
              <span className="card-title">Medical History</span>
            </div>
            <MedicationHistory patientId={patientId} />
          </div>
          <MedicationList />
        </div>
      </div>
      {clinicalDetailsDialogOpen && (
        <ClinicalDetailsDialog
          patientId={patientId}
          open={clinicalDetailsDialogOpen}
          handleClose={handleClinicalDetailsDialog}
        />
      )}
    </>
  );
};

export default ClinicalDataComponent;
