import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {usePatientQuestionnaire} from './hooks';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import LifestyleQuestionnaire from './LifeStyle/component';
import FamilyQuestionnaire from './family/component';
import DailyLivingAndHospitalExperience from './DailyLivingAndHospitalExperience/component';
import {Box} from '@mui/material';
import MedicalHistoryQuestionnaire from './MedicalHistory';
import {getRoute, RouteSection} from '$utils/route';
import {PatientDetailTab} from '../../../constant';
import {QuestionnaireType} from '$api/patientList/questionnaire';

const Questionnaire: React.FC = () => {
  const location = useLocation();
  const {patientId} = useParams();
  const navigate = useNavigate();
  const currentQuestionnaireType: QuestionnaireType =
    location.state?.type || QuestionnaireType.MedicalHistory;
  const {questionnaire, isLoading, error} = usePatientQuestionnaire({
    patientId: Number(patientId),
    historyType: currentQuestionnaireType,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5;

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      navigate(getRoute(RouteSection.PatientList, patientId), {
        state: {
          activeSubTab: PatientDetailTab.ClinicalData,
        },
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) return <Loading />;
  if (error) return <FetchError />;

  if (currentQuestionnaireType === 'lifestyle') {
    return (
      <LifestyleQuestionnaire
        patientId={Number(patientId)}
        questionnaire={questionnaire}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleBack={handleBack}
      />
    );
  }

  if (currentQuestionnaireType === 'family') {
    return (
      <FamilyQuestionnaire
        patientId={Number(patientId)}
        questionnaire={questionnaire}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleBack={handleBack}
      />
    );
  }

  if (
    currentQuestionnaireType === 'daily_living' ||
    currentQuestionnaireType === 'hospital_experience'
  ) {
    return (
      <DailyLivingAndHospitalExperience
        patientId={Number(patientId)}
        questionnaire={questionnaire}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleBack={handleBack}
      />
    );
  }

  if (currentQuestionnaireType === 'medical') {
    return (
      <MedicalHistoryQuestionnaire
        patientId={Number(patientId)}
        currentPage={currentPage}
        itemsPerPage={12}
        medicalHistory={questionnaire}
        handleBack={handleBack}
        handlePageChange={handlePageChange}
      />
    );
  }

  return <Box>No questionnaire type found</Box>;
};

export default Questionnaire;
