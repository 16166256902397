import toast from '$utils/toast';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  CheckInPatientResponseType,
  PatchCheckInPatientPayloadType,
  patchCheckInPatient,
} from '$api/frontdesk';
import {ToastType} from '$components/Toast/constant';
import {networkErrorToast, technicalGlitchToast} from '$constants/messages';
import queryClient from '$lib/queryClient';

export function useCheckInPatientHook(
  setCheckInPatientModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const {isPending, mutate} = useMutation({
    mutationKey: ['check-in-patient'],
    mutationFn: patchCheckInPatient,
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ['appointment-detail'],
      });
      if (setCheckInPatientModalOpen)
        setCheckInPatientModalOpen(prevState => !prevState);
      return toast(ToastType.Success, 'Check-in patient', data.message);
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<CheckInPatientResponseType>;
        const response = axiosError.response;

        if (response) {
          if (response.status === 500) {
            return technicalGlitchToast();
          }

          if (response.data) {
            const data = response.data;
            return toast(ToastType.Error, 'Check-in patient', data.message);
          }
        }

        if (axiosError.message === 'Network Error') {
          return networkErrorToast();
        }

        return technicalGlitchToast();
      } else {
        technicalGlitchToast();
      }
    },
    onSettled: () => {},
  });

  const sendCheckInPatient = (payload: PatchCheckInPatientPayloadType) => {
    mutate(payload);
  };

  return {
    isPending,
    sendCheckInPatient,
  };
}
