import {PayloadAction} from '@reduxjs/toolkit';
import {AuthState} from '.';
import {UserAuthData} from './types';

export function loadAuthReducer(
  state: AuthState,
  action: PayloadAction<{
    refreshToken: string;
    token: string;
    user: UserAuthData;
  }>
) {
  const {token, user, refreshToken} = action.payload;

  state.refreshToken = refreshToken;
  state.token = token;
  state.user = user;
}

export function resetAuthReducer(state: AuthState) {
  state.token = null;
  state.user = null;
}
