import Box from '@mui/material/Box';
import EyeOpLogo from '$assets/svgs/eye-op.svg?react';
import HospitalLogo from '$assets/svgs/site-text-logo.svg?react';
import HospitalMobileLogo from '$assets/svgs/site-logo.svg?react';
import styles from './styles';
import {useState} from 'react';
import LeaveBookingModal from '../LeaveBookingModal';
import {BookingDataUpdater, BookingView} from '$modules/booking/types';

interface HeaderProps {
  setBookingData?: BookingDataUpdater;
  setActiveView?: React.Dispatch<React.SetStateAction<BookingView>>;
}

function Header(props: HeaderProps) {
  const {setActiveView, setBookingData} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLeave = () => {
    setIsModalOpen(false);
    if (setBookingData && setActiveView) {
      setBookingData(() => ({}));
      setActiveView(BookingView.BookingSelectionView);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <Box sx={styles.headerContainer}>
      <Box>
        <EyeOpLogo className="site-logo" onClick={handleOpen} />
      </Box>
      <Box sx={styles.mobileLogo}>
        <HospitalMobileLogo className="hosptial-mobile-logo" />
      </Box>
      <Box sx={styles.desktopLogo}>
        <HospitalLogo className="hosptial-logo" onClick={handleOpen} />
      </Box>
      {setBookingData && setActiveView && (
        <LeaveBookingModal
          open={isModalOpen}
          onClose={handleClose}
          onLeave={handleLeave}
        />
      )}
    </Box>
  );
}

export default Header;
