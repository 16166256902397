import {Outlet} from 'react-router-dom';
import './styles.scss';

function PatientPlatformLayout() {
  return (
    <div className="h-screen w-screen patient-platform flex">
      <div className="w-full xl:w-1/2 flex">
        <Outlet />
      </div>
      <div className="hidden w-1/2 bg-green-200 xl:!flex cloth-background" />
    </div>
  );
}

export default PatientPlatformLayout;
