import {useNavigate} from 'react-router-dom';
import MedicationHistoryComponent from './component';
import {QuestionnaireType} from '$api/patientList/questionnaire';
import {useMedicalHistory} from '../../hooks';
import {getRoute, RouteSection} from '$utils/route';
import {RoutePath} from '$constants/routes';

interface MedicationHistoryProps {
  patientId: number;
}

function MedicationHistory(props: MedicationHistoryProps) {
  const {patientId} = props;

  const {
    medicalHistory,
    isMedicalHistoryLoading,
    isMedicalHistoryFetchError,
    downloadMedicalHistoryPDF,
  } = useMedicalHistory(patientId);

  const navigate = useNavigate();

  const handleOpenQuestionnaire = (questionnaireType: QuestionnaireType) => {
    navigate(
      getRoute(
        RouteSection.PatientList,
        patientId.toString(),
        RoutePath.staff.patientList.questionnaire
      ),
      {
        state: {
          type: questionnaireType,
        },
      }
    );
  };

  return (
    <MedicationHistoryComponent
      isMedicalHistoryLoading={isMedicalHistoryLoading}
      isMedicalHistoryFetchError={isMedicalHistoryFetchError}
      medicalHistory={medicalHistory}
      onEditIconClick={handleOpenQuestionnaire}
      onDownloadClick={downloadMedicalHistoryPDF}
    />
  );
}

export default MedicationHistory;
