import {
  PostLoginVariables,
  PutForgotPasswordVariables,
  postBookingSignIn,
  putForgotPassword,
  putResetPassword,
  putSetPassword,
  sendOtp,
  validateOtp,
} from '$api/bookingFlow';
import {ToastType} from '$components/Toast/constant';
import {RoutePath} from '$constants/routes';
import {UserRoleName} from '$modules/admin/constant';
import {loadAuth} from '$redux/authSlice';
import {useAppDispatch} from '$redux/hooks';
import {handleErrorResponse} from '$utils/axios';
import LocalStorage from '$utils/localStore';
import {RouteSection, getRoute} from '$utils/route';
import toast from '$utils/toast';
import {useMutation} from '@tanstack/react-query';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

export function usePatientLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-login'],
    mutationFn: postBookingSignIn,
    onSuccess: data => {
      const token = data.data.token;
      const refreshToken = data.data.refresh_token;
      const patientData = data.data.patient;

      const authData = {
        refreshToken: refreshToken,
        token: token,
        user: {
          id: patientData.id,
          fullName: patientData.full_name,
          hospitalId: null,
          email: patientData.email,
          mobileNumber: patientData.mobile_number,
          roleName: UserRoleName.Patient,
          profileUrl: null,
          timezone: null,
        },
      };

      dispatch(loadAuth(authData));
      LocalStorage.setItem(
        LocalStorage.AUTH_USER_DATA,
        JSON.stringify(authData)
      );

      const message = data?.message;
      if (message) toast(ToastType.Success, 'Login success', data.message);
      navigate(
        getRoute(RouteSection.PatientInvite, RoutePath.patient.onBoarding.index)
      );
    },
    onError: error => handleErrorResponse(error, 'Login'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendSignInRequest = (variables: PostLoginVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    sendSignInRequest,
    disableSubmit: isPending || disableSubmit,
    isPending,
  };
}

export function useForgotPassword() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-forgot-password'],
    mutationFn: putForgotPassword,
    onSuccess: data => {
      const message = data?.message;
      if (message) toast(ToastType.Success, 'Forgot password', data.message);
      navigate(getRoute(RouteSection.PatientInvite, RoutePath.patient.login));
    },
    onError: error => handleErrorResponse(error, 'Forgot password'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendForgotPasswordRequest = (variables: PutForgotPasswordVariables) => {
    setDisableSubmit(true);
    mutate(variables);
  };

  return {
    sendForgotPasswordRequest,
    disableSubmit: isPending || disableSubmit,
    isPending,
  };
}

export function usePatientSendOTP() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-send-otp'],
    mutationFn: sendOtp,
    onSuccess: (data, email) => {
      const message = data?.message;
      navigate(
        getRoute(RouteSection.PatientInvite, RoutePath.patient.otpVerification),
        {state: {email}}
      );
      if (message) toast(ToastType.Success, 'OTP sent', data.message);
    },
    onError: error => handleErrorResponse(error, 'OTP failed'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendOtpRequest = (email: string) => {
    setDisableSubmit(true);
    mutate(email);
  };

  return {
    sendOtpRequest,
    disableSubmit: isPending || disableSubmit,
    isPending,
  };
}

export function useVerifyOTP() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate, isPending} = useMutation({
    mutationKey: ['patient-verify-otp'],
    mutationFn: ({email, otp}: {email: string; otp: number}) =>
      validateOtp(email, otp),
    onSuccess: data => {
      const token = data.data.token;
      const refreshToken = data.data.refresh_token;
      const patientData = data.data.patient;

      const authData = {
        refreshToken: refreshToken,
        token: token,
        user: {
          id: patientData.id,
          fullName: patientData.full_name,
          hospitalId: null,
          email: patientData.email,
          mobileNumber: patientData.mobile_number,
          roleName: UserRoleName.Patient,
          profileUrl: null,
          timezone: null,
        },
      };
      dispatch(loadAuth(authData));
      LocalStorage.setItem(
        LocalStorage.AUTH_USER_DATA,
        JSON.stringify(authData)
      );

      navigate(
        getRoute(RouteSection.PatientInvite, RoutePath.patient.createPassword)
      );
    },
    onError: error => handleErrorResponse(error, 'OTP verification failed'),
    onSettled: () => setDisableSubmit(false),
  });

  const sendOtpRequest = (email: string, otp: number) => {
    setDisableSubmit(true);
    mutate({email, otp});
  };

  return {
    sendOtpRequest,
    disableSubmit: isPending || disableSubmit,
    isPending,
  };
}

export function usePatientPassword() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {mutate: mutateResetPassword, isPending: isResetPasswordPending} =
    useMutation({
      mutationKey: ['patient-reset-password'],
      mutationFn: putResetPassword,
      onSuccess: data => {
        const message = data?.message;
        if (message) toast(ToastType.Success, 'Reset password', data.message);
        navigate(getRoute(RouteSection.PatientInvite, RoutePath.patient.login));
      },
      onError: error => handleErrorResponse(error, 'Reset password'),
      onSettled: () => setDisableSubmit(false),
    });

  const {mutate: mutateSetPassword, isPending: isSetPasswordPending} =
    useMutation({
      mutationKey: ['patient-set-password'],
      mutationFn: putSetPassword,
      onSuccess: () => {
        navigate(
          getRoute(
            RouteSection.PatientInvite,
            RoutePath.patient.onBoarding.index
          )
        );
      },
      onError: error => handleErrorResponse(error, 'Create password'),
      onSettled: () => setDisableSubmit(false),
    });

  const sendResetPasswordRequest = (token: string, password: string) => {
    setDisableSubmit(true);
    mutateResetPassword({token, password: password, confirmPassword: password});
  };

  const sendSetPasswordRequest = (password: string) => {
    setDisableSubmit(true);
    mutateSetPassword({password, confirmPassword: password});
  };

  return {
    sendResetPasswordRequest,
    sendSetPasswordRequest,
    disableSubmit:
      isResetPasswordPending || isSetPasswordPending || disableSubmit,
    isPending: isResetPasswordPending || isSetPasswordPending,
  };
}
