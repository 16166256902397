import React from 'react';
import {Radio, RadioGroup, FormControlLabel, TextField} from '@mui/material';
import {useFormik, FormikValues} from 'formik';

import {
  Question,
  QuestionnaireType,
  QuestionType,
} from '$api/patientList/questionnaire';
import './styles.scss';
import {usePatientQuestionnaire} from '../hooks';
import {
  generateInitialValues,
  generateQuestionnairePayload,
  generateValidationSchema,
} from '../utils';
import QuestionnaireHeader from '$components/QuestionnaireHeader';
import QuestionnaireFooter from '$components/QuestionnaireFooter';

interface FamilyQuestionnaireProps {
  patientId: number;
  questionnaire: Question[];
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

const FamilyQuestionnaire: React.FC<FamilyQuestionnaireProps> = ({
  patientId,
  questionnaire,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleBack,
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const questionsToDisplay = questionnaire.slice(startIndex, endIndex);
  const totalPages = Math.ceil(questionnaire.length / itemsPerPage);

  const initialValues = generateInitialValues(questionnaire);
  const validationSchema = generateValidationSchema(questionsToDisplay);

  const {submitPatientQuestionnaire, disableSubmit} = usePatientQuestionnaire({
    patientId,
    historyType: QuestionnaireType.Family,
  });

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    const payload = generateQuestionnairePayload(values);
    submitPatientQuestionnaire(payload);
  };

  const handleFormSubmit = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmitQuestionnaire(values);
    },
  });

  const renderQuestion = (question: Question) => {
    switch (question.question_type) {
      case QuestionType.Radio:
        return (
          <div>
            <RadioGroup
              row
              name={String(question.id)}
              value={formik.values[question.id] || ''}
              onChange={formik.handleChange}
              className="radio-group"
            >
              {question.options?.map((option: string) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </div>
        );
      case QuestionType.Textarea:
        return (
          <div>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder={question.placeholder || ''}
              name={String(question.id)}
              value={formik.values[question.id] || ''}
              onChange={formik.handleChange}
              className="fam-question-text-field"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="questionnairepage-details">
      <QuestionnaireHeader handleBack={handleBack} />
      <div className="questionnairepage-details__content">
        <main className="questionnaire__main">
          <div className="questionnaire__main-heading">
            Family history questionnaire
          </div>
          <form onSubmit={formik.handleSubmit}>
            {questionsToDisplay.map((question: Question, index: number) => (
              <div key={question.id} className="family-page-question-container">
                <h3>{`${startIndex + index + 1}. ${question.question}`}</h3>
                {renderQuestion(question)}
              </div>
            ))}
          </form>
        </main>
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFormSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
      />
    </div>
  );
};

export default FamilyQuestionnaire;
