import {IconButton} from '@mui/material';
import {ToastOptions, toast as toastifyToast} from 'react-toastify';
import ToastXIcon from '$assets/svgs/toast-x.svg?react';
import Toast from '$components/Toast';
import {ToastType} from '$components/Toast/constant';
import NotificationToast from '$components/Toast/NotificationToast';
import {router} from '$lib/router';

function toast(
  type: ToastType,
  title: string,
  message: string | string[],
  id?: string
) {
  const isSmallScreen = window.innerWidth < 1280;
  const options: ToastOptions = {
    toastId: id,
    position: isSmallScreen ? 'top-center' : 'bottom-center',
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
  };

  toastifyToast(<Toast type={type} title={title} message={message} />, options);
}

export function notificationToast(
  title: string,
  message: string,
  time: Date,
  route: string
) {
  const options: ToastOptions = {
    position: 'bottom-right',
    hideProgressBar: true,
    closeButton: ({closeToast}) => (
      <div className="pt-1 pr-1">
        <IconButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            closeToast(e);
          }}
        >
          <ToastXIcon className="text-primary" />
        </IconButton>
      </div>
    ),
    closeOnClick: true,
    onClick: () => router.navigate(route),
  };

  toastifyToast(
    <NotificationToast title={title} message={message} time={time} />,
    options
  );
}

export default toast;
