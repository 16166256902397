import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PencilIcon from '$assets/svgs/pencil.svg?react';
import IconButton from '$components/IconButton';
import {ConsultantSelectionViewChoices} from '../../types';
import styles from './styles';

interface AppointmentCardProps {
  bookingChoices: ConsultantSelectionViewChoices;
  onOpenDialog: () => void;
}

function AppointmentCard(props: AppointmentCardProps) {
  const {bookingChoices, onOpenDialog} = props;

  return (
    <Stack direction="row" spacing={3.5} sx={styles.appointmentCard}>
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="body1">What would you like to book?</Typography>
          <Typography variant="h4" sx={styles.primaryDarkestText}>
            {bookingChoices.booking.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1">
            What type of care are you looking for?
          </Typography>
          <Typography variant="h4" sx={styles.primaryDarkestText}>
            {bookingChoices.appointment.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1">
            What surgery are you interested in?
          </Typography>
          <Typography variant="h4" sx={styles.primaryDarkestText}>
            {bookingChoices.care.title}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <IconButton onClick={onOpenDialog}>
          <PencilIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default AppointmentCard;
