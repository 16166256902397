import {Consultant} from './type';

export const CALENDAR_TIMELINE_PIXEL_MINUTE_RATIO = 6;
export const CALENDAR_TIMELINE_COLUMN_WIDTH =
  30 * CALENDAR_TIMELINE_PIXEL_MINUTE_RATIO;

export enum AppointmentType {
  Pending = 'pending',
  Confirmed = 'confirm',
  ToBeConfirmed = 'to_be_confirmed',
  Complete = 'complete',
  Cancel = 'cancel',
}

export const ALL_CONSULTANT_OPTION: Consultant & {label: string} = {
  id: NaN,
  profileUrl: null,
  fullName: 'All Consultants',
  label: 'All Consultants',
  doctorDetails: null,
};

export const INSURANCE_OPTIONS = ['Self paying', 'Not self paying'];
export const EDIT_BOOKING_INSURANCE_OPTIONS = ['Yes', 'No'];

export const APPOINTMENTLIST_SKELETON_ITEM_COUNT = 6;
export const PENDINGREQUESTS_SKELETON_ITEM_COUNT = 6;
export const RESHEDULEAPP_SKELETON_ITEM_COUNT = 5;
export const ALLERGY_SKELETON_ITEM_COUNT = 2;
export const DOCUMENTS_SKELETON_ITEM_COUNT = 2;
export const MEDICATIONLIST_SKELETON_ITEM_COUNT = 3;
export const PATIENTCARELIST_SKELETONIITEM_COUNT = 4;
export const RECENT_UPDATE_SKELETON_ITEM_COUNT = 3;
export const MEDICATION_HISTORY_SKELETON_ITEM_COUNT = 3;
export const NOTIFICATION_SKELETON_ITEM_COUNT = 6;
