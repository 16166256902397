import {ApiRoute, ConstantApiParamKey} from '$constants/api';
import {AppointmentType} from '$modules/frontdesk/constant';
import {
  AppointmentDetail,
  AppointmentInfo,
  BookingDetails,
  FilterOptions,
  Slot,
} from '$modules/frontdesk/type';
import axiosInstance from '.';
import {FormState} from '$modules/frontdesk/Calendar/OfflineBooking/types';
import {PostCreateAppointmentResponseData} from './bookingFlow';
import store from '$redux/store';
import {PreferredTime} from '$modules/frontdesk/Calendar/EditBooking/types';
import {EditBookingFormData} from '$modules/frontdesk/Calendar/EditBooking/schema';
import {isNumber} from '$utils/number';

export interface PatchConfirmBookingPayload {
  appointmentId: number;
  doctorId: number;
  endTime: Date;
  startTime: Date;
  preferredTime: string;
  date: Date;
  confirmAt: Date;
}

export interface PatchConfirmBookingResponseData {
  success: true;
  message: string;
}

export interface PatchConfirmBookingErrorResponseData {
  success: false;
  message: string;
}

export async function patchConfirmBooking(payload: PatchConfirmBookingPayload) {
  const state = store.getState();
  const token = state.auth.token;
  const {
    appointmentId,
    doctorId,
    startTime,
    endTime,
    date,
    confirmAt,
    preferredTime,
  } = payload;

  const response = await axiosInstance.patch<PatchConfirmBookingResponseData>(
    `${ApiRoute.Appointment}/${appointmentId}/confirm`,
    {
      appointment: {
        doctor_id: doctorId,
        end_time: endTime.toISOString(),
        start_time: startTime.toISOString(),
        preferred_time: preferredTime,
        date: date.toISOString(),
        confirm_at: confirmAt.toISOString(),
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
}

export interface PatchRescheduleBookingPayload {
  appointmentId: number;
  startTime: Date;
  endTime: Date;
  preferredTime: string;
  date: Date;
}

export interface PatchRescheduleBookingResponseData {
  success: true;
  message: string;
}

export interface PatchRescheduleBookingErrorResponseData {
  success: true;
  message: string;
}

export async function patchRescheduleBooking(
  payload: PatchRescheduleBookingPayload
) {
  const state = store.getState();
  const token = state.auth.token;
  const {appointmentId, startTime, endTime, date, preferredTime} = payload;

  const response =
    await axiosInstance.patch<PatchRescheduleBookingResponseData>(
      `${ApiRoute.Appointment}/${appointmentId}/reschedule`,
      {
        appointment: {
          end_time: endTime.toISOString(),
          start_time: startTime.toISOString(),
          preferred_time: preferredTime,
          date: date.toISOString(),
        },
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

  return response.data;
}

export interface GetBookingSlotResponseData {
  data: {
    slots: {
      start_time: string;
      end_time: string;
    }[];
    slots_start_time: string;
    slots_end_time: string;
  };
}

export interface GetBookingSlotParams {
  hospitalId?: number;
  doctorId?: number;
  date?: Date;
  preferredTime?: string;
  subCareTypeId?: number;
  appointmentTypeId?: number;
}

export async function getBookingSlots(
  params: GetBookingSlotParams
): Promise<{slots: Slot[]; slotsStartTime: Date; slotsEndTime: Date}> {
  const {
    date,
    preferredTime,
    hospitalId,
    doctorId,
    subCareTypeId,
    appointmentTypeId,
  } = params;

  const selectedPreferredTime = preferredTime || undefined;

  const state = store.getState();
  const token = state.auth.token;
  const hasAllPrams = date && hospitalId;

  if (!hasAllPrams)
    return {
      slots: [],
      slotsStartTime: new Date(),
      slotsEndTime: new Date(),
    };

  const response = await axiosInstance.get<GetBookingSlotResponseData>(
    ApiRoute.AvailableSlots,
    {
      params: {
        hospital_id: hospitalId,
        doctor_id: isNumber(doctorId) ? doctorId : undefined,
        date: date.toISOString(),
        preferred_time: selectedPreferredTime,
        sub_care_type_id: subCareTypeId,
        appointment_type_id: appointmentTypeId,
      },
      headers: {
        Authorization: token,
      },
    }
  );

  const responseData = response.data.data;
  const responseSlots = responseData.slots || [];

  const slots = responseSlots.map(slot => ({
    startTime: new Date(slot.start_time),
    endTime: new Date(slot.end_time),
  }));

  const startTime = new Date(responseData.slots_start_time);
  const endTime = new Date(responseData.slots_end_time);

  return {
    slots: slots,
    slotsStartTime: startTime,
    slotsEndTime: endTime,
  };
}

interface BookingDetailApiResponse {
  success: boolean;
  data: {
    appointment: {
      id: number;
      date: string;
      start_time: string;
      end_time: string;
      amount: number | null;
      preferred_time: string;
      reason: string;
      payment_mode: string;
      status: string;
      self_booking: boolean;
      self_paying: boolean;
      membership_no: string;
      check_in_time: string | null;
      check_out_time: string | null;
      referred_by_physician: boolean;
      patient_profile_exist: boolean;
      confirm_at: string | null;
      country_code: string;
      contact_email: string;
      contact_mobile_number: string;
      preferred_point_of_contact: string;
      patient: {
        id: number;
        password_digest: string;
        first_name: string;
        last_name: string;
        full_name: string;
        dob: string;
        uniq_code: string | null;
        prefix: string;
        country_code: string;
        mobile_number: string;
        email: string;
        gender: string;
        marital_status: string | null;
        status: string;
        preferred_point_of_contact: string;
        deleted_at: string | null;
        created_at: string;
        updated_at: string;
        address: {
          id: number;
          line1: string;
          line2: string | null;
          city: string;
          state: string | null;
          country: string;
          postcode: string;
          addressable_type: string;
          addressable_id: number;
          deleted_at: string | null;
          created_at: string;
          updated_at: string;
          city_name: string;
          country_name: string;
        };
      };
      care_taker: {
        id: number;
        first_name: string;
        last_name: string;
        mobile_number: string;
        email: string;
        country_code: string;
        relationship: string;
        patient_id: number;
        deleted_at: string | null;
        created_at: string;
        updated_at: string;
      };
      doctor: {
        id: number;
        full_name: string;
        password_digest: string | null;
        profile_url: string | null;
        country_code: string;
        mobile_number: string;
        email: string | null;
        role_id: number;
        deleted_at: string | null;
        created_at: string;
        updated_at: string;
      };
      insurance: {
        id: number;
        name: string;
      };
      appointment_type: {
        id: number;
        title: string;
        description: string;
        image: string;
        is_active: boolean;
        is_enable: boolean;
        created_at: string;
        updated_at: string;
      };
      booking_type: {
        id: number;
        title: string;
        description: string;
        image: string;
        is_active: boolean;
        is_enable: boolean;
        created_at: string;
        updated_at: string;
      };
      care_type: {
        id: number;
        title: string;
        description: string;
        is_active: boolean;
        is_enable: boolean;
        created_at: string;
        updated_at: string;
      };
      sub_care_type: {
        id: number;
        title: string;
      };
      operation_theatre: {
        id: number;
        name: string;
      };
    };
  };
  message: string;
}

export async function getBookingDetails(id: number): Promise<{
  success: boolean;
  data: BookingDetails;
  message: string;
}> {
  const state = store.getState();
  const token = state.auth.token;
  const response = await axiosInstance.get<BookingDetailApiResponse>(
    ApiRoute.Appointment + `/${id}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

interface FilterApiResponse {
  success: boolean;
  data: {
    appointment_type: {
      id: number;
      title: string;
      display_name: string;
      description: string;
      is_active: boolean;
      is_enable: boolean;
    }[];
    consultant: {
      id: number;
      profile_url: string | null;
      full_name: string;
    }[];
    status: {
      id: number;
      name: string;
    }[];
  };
  message: string;
}

export async function getFilterOptions(): Promise<{
  success: boolean;
  data: FilterOptions;
  message: string;
}> {
  const state = store.getState();
  const token = state.auth.token;
  const response = await axiosInstance.get<FilterApiResponse>(
    ApiRoute.FilterOptions,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface GetAppointmentListResponseData {
  success: boolean;
  data: {
    appointments: {
      id: number;
      start_time: string;
      end_time: string;
      date: string;
      status: AppointmentType;
      appointment_type_name: string;
      created_at: string;
      preferred_time: string;
      patient_id: number;
      patient_name: string;
      doctor_name: string;
      check_in_time: string | null;
      check_out_time: string | null;
    }[];
    total_pages: number;
    total_records: number;
  };
  message: boolean;
}

export interface GetAppointmentListParams {
  doctorId?: number[];
  appointmentType?: number[];
  status?: string[];
  fromDate?: Date;
  toDate?: Date;
  search?: string;
  perPage?: number;
  pageNumber?: number;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
  patientId?: number;
  newPatient?: boolean;
}

export async function getAppointmentList(
  params: GetAppointmentListParams
): Promise<{
  appointments: AppointmentInfo[];
  totalPages: number;
  totalRecords: number;
}> {
  const state = store.getState();
  const token = state.auth.token;
  const response = await axiosInstance.get<GetAppointmentListResponseData>(
    ApiRoute.Appointment,
    {
      params: {
        doctor_id: params.doctorId,
        appointment_type: params.appointmentType,
        status: params.status,
        from_date: params.fromDate?.toISOString(),
        to_date: params.toDate?.toISOString(),
        search: params.search,
        per_page: params.perPage,
        page_no: params.pageNumber,
        order_by: params.orderBy,
        order_direction: params.orderDirection,
        patient_id: params.patientId,
        new_patient: params.newPatient,
      },
      headers: {
        Authorization: token,
      },
    }
  );

  const responseData = response.data.data;
  const responseAppointments = responseData?.appointments || [];

  return {
    appointments: responseAppointments.map(appointment => {
      return {
        appointment: {
          id: appointment.id,
          startTime: new Date(appointment.start_time),
          endTime: new Date(appointment.end_time),
          date: new Date(appointment.date),
          status: appointment.status,
          type: appointment.appointment_type_name,
          createdAt: appointment.created_at,
          preferredTime: appointment.preferred_time,
          patientId: appointment.patient_id,
          patientName: appointment.patient_name,
          doctorName: appointment.doctor_name,
          checkInTime: appointment.check_in_time,
          checkOutTime: appointment.check_out_time,
        },
      };
    }),
    totalPages: responseData.total_pages,
    totalRecords: responseData.total_records,
  };
}

export interface GetAppointmentDetailsResponseData {
  data: {
    appointment: {
      id: number;
      date: string;
      start_time: string;
      end_time: string;
      status: AppointmentType;
      preferred_time: string;
      patient: {
        id: number;
        first_name: string;
        last_name: string;
      };
      doctor: {
        id: number;
        full_name: string;
        profile_url: string;
      };
      appointment_type: {
        id: number;
        title: string;
      };
      care_type: {
        id: number;
        title: string;
      };
      sub_care_type: {
        id: number;
        title: string;
      };
      operation_theatre: {
        id: number;
        name: string;
      };
    };
  };
  message: string;
}

export interface GetAppointmentDetailsParams {
  appointmentId: number;
}

export async function getAppointmentDetails(
  params: GetAppointmentDetailsParams
): Promise<AppointmentDetail> {
  const state = store.getState();
  const token = state.auth.token;
  const {appointmentId} = params;

  const response = await axiosInstance.get<GetAppointmentDetailsResponseData>(
    `${ApiRoute.Appointment}/${appointmentId}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  const {appointment} = response.data.data;
  const {
    patient,
    doctor,
    appointment_type,
    care_type,
    sub_care_type,
    operation_theatre,
  } = appointment;

  return {
    appointment: {
      id: appointment?.id,
      date: new Date(appointment?.date),
      startTime: appointment?.start_time
        ? new Date(appointment?.start_time)
        : null,
      endTime: appointment?.end_time ? new Date(appointment?.end_time) : null,
      status: appointment?.status,
      perferredTime: appointment?.preferred_time,
    },
    patient: {
      id: patient?.id,
      firstName: patient?.first_name,
      lastName: patient?.last_name,
    },
    doctor: {
      id: doctor?.id,
      fullName: doctor?.full_name,
      profileUrl: doctor?.profile_url,
    },
    appointmentType: {
      id: appointment_type?.id,
      title: appointment_type?.title,
    },
    subCareType: {
      id: sub_care_type?.id,
      title: sub_care_type?.title,
    },
    careType: {
      id: care_type?.id,
      title: care_type?.title,
    },
    operationTheatre: {
      id: operation_theatre?.id,
      name: operation_theatre?.name,
    },
  };
}

interface GetAvailableSlotResponseData {
  message: string;
  data: {
    slots: {
      start_time: string;
      end_time: string;
    }[];
  };
}

export async function getAvailableSlots(
  doctorId: number,
  date: string,
  preferred_time: string,
  subCareTypeId: number,
  appointmentTypeId: number
): Promise<{
  message: string;
  data: {
    slots: {
      start_time: string;
      end_time: string;
    }[];
  };
}> {
  const state = store.getState();
  const token = state.auth.token;
  const hospitalId = state.auth.user?.hospitalId;
  const response = await axiosInstance.get<GetAvailableSlotResponseData>(
    ApiRoute.AvailableSlots,
    {
      params: {
        hospital_id: hospitalId,
        doctor_id: doctorId,
        date: date,
        preferred_timez: preferred_time,
        sub_care_type_id: subCareTypeId,
        appointment_type_id: appointmentTypeId,
      },
      headers: {
        Authorization: token,
      },
    }
  );

  return {
    message: response.data.message,
    data: response.data.data,
  };
}

export interface Patients {
  id: number;
  fullName: string;
  dob: string;
  uniqueCode: string;
  mobileNumber: string;
  email: string;
}

export interface GetPatientListResponseData {
  success: boolean;
  data: {
    patients: {
      id: number;
      full_name: string;
      dob: string;
      uniq_code: string;
      mobile_number: string;
      email: string;
    }[];
  };
  message: string;
}

export async function getPatientList(
  search?: string
): Promise<{data: Patients[]}> {
  const state = store.getState();
  const user = state.auth?.user;
  const hospitalId = user?.hospitalId;
  const response = await axiosInstance.get<GetPatientListResponseData>(
    `${ApiRoute.Patients}/search`,
    {
      params: {
        hospital_id: hospitalId,
        search,
      },
    }
  );
  const patients = response.data.data?.patients || [];
  return {
    data: patients.map(patient => ({
      id: patient.id,
      fullName: patient.full_name,
      dob: patient.dob,
      uniqueCode: patient.uniq_code,
      mobileNumber: patient.mobile_number,
      email: patient.email,
    })),
  };
}

export async function postOfflineCreateAppointment(body: FormState) {
  const state = store.getState();
  const hospitalId = state.auth.user?.hospitalId;
  const token = state.auth.token;
  const selfPaying = body.appointment.self_paying;

  if (hospitalId) body.appointment.hospital_id = hospitalId;

  if (selfPaying) {
    delete body.appointment.insurance_id;
    delete body.appointment.membership_no;
  }

  const response = await axiosInstance.post<PostCreateAppointmentResponseData>(
    ApiRoute.Appointment,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
}

interface GetCancellationReasonResponseData {
  success: boolean;
  data: {
    id: number;
    name: string;
  }[];
}

export async function getCancellationReason(): Promise<{
  success: boolean;
  data: {
    id: number;
    name: string;
  }[];
}> {
  const response = await axiosInstance.get<GetCancellationReasonResponseData>(
    ApiRoute.Constant,
    {
      params: {
        key: ConstantApiParamKey.BookingCancellation,
      },
    }
  );

  return {
    success: response.data.success,
    data: response.data.data,
  };
}

export interface PatchCancelAppointmentPayloadType {
  cancellationReason: number;
  otherCancellationReason: string;
  appointmentId: number;
  informPatient: boolean;
}

export interface CancelAppointmentResponseType {
  success: boolean;
  errors: string[];
  message: string;
}

export async function patchCancelAppointment(
  payload: PatchCancelAppointmentPayloadType
): Promise<CancelAppointmentResponseType> {
  const {
    appointmentId,
    cancellationReason,
    otherCancellationReason,
    informPatient,
  } = payload;

  const response = await axiosInstance.patch<CancelAppointmentResponseType>(
    `${ApiRoute.Appointment}/${appointmentId}/cancel`,
    {
      appointment: {
        cancellation_reason: cancellationReason,
        other_cancellation_reason: otherCancellationReason,
        inform_patient: informPatient,
      },
    }
  );

  return response.data;
}

export interface PatchCheckInPatientPayloadType {
  check_in_time: string;
  check_out_time: string;
  appointmentId: number;
}

export interface CheckInPatientResponseType {
  success: boolean;
  errors: string[];
  message: string;
}

export async function patchCheckInPatient(
  payload: PatchCheckInPatientPayloadType
): Promise<CheckInPatientResponseType> {
  const state = store.getState();
  const token = state.auth.token;
  const {appointmentId, check_in_time, check_out_time} = payload;

  const response = await axiosInstance.patch<CheckInPatientResponseType>(
    `${ApiRoute.Appointment}/${appointmentId}/check_in`,
    {
      appointment: {
        check_in_time,
        check_out_time,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
}

interface GetOperationTheatreResponseData {
  success: boolean;
  data: {
    id: number;
    name: string;
  }[];
  message: string;
}

interface GetOperationTheatrePayload {
  date?: Date;
  startTime?: Date;
  endTime?: Date;
}

export async function getOperationTheatre(
  payload: GetOperationTheatrePayload
): Promise<{
  success: boolean;
  data: {
    id: number;
    name: string;
  }[];
  message: string;
}> {
  const {date, startTime, endTime} = payload;
  const response = await axiosInstance.get<GetOperationTheatreResponseData>(
    ApiRoute.OperationTheatre,
    {
      params: {
        date: date,
        start_time: startTime,
        end_time: endTime,
      },
    }
  );

  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface GetPreferredTimeResponse {
  success: boolean;
  data: PreferredTime[];
  message: string;
}

export async function getPreferredTime(): Promise<GetPreferredTimeResponse> {
  const response = await axiosInstance.get<GetPreferredTimeResponse>(
    ApiRoute.Constant,
    {
      params: {
        key: ConstantApiParamKey.PreferredTime,
      },
    }
  );
  return {
    success: response.data.success,
    data: response.data.data,
    message: response.data.message,
  };
}

export interface PutAppointmentVariables {
  appointmentId: number;
  body: EditBookingFormData;
}

export interface PutAppointmentResponseData {
  success: boolean;
  data: BookingDetails;
  message: string;
}

export async function putAppointment(payload: PutAppointmentVariables) {
  const {appointmentId, body} = payload;
  const response = await axiosInstance.put<PutAppointmentResponseData>(
    ApiRoute.Appointment + `/${appointmentId}`,
    body
  );

  return response.data;
}

export interface PostInvitePatientResponseData {
  message: string;
}

export interface PostInvitePayloadProps {
  resource_type: string;
  email: string;
}

export async function postInvitePatient(payload: PostInvitePayloadProps) {
  const {resource_type, email} = payload;
  const response = await axiosInstance.post<PostInvitePatientResponseData>(
    ApiRoute.Inviation,
    {
      invitation: {
        resource_type,
        email,
      },
    }
  );

  return response.data;
}
