import React from 'react';
import {Radio, RadioGroup, FormControlLabel, TextField} from '@mui/material';
import {useFormik, FormikValues} from 'formik';
import {
  Question,
  QuestionConditionType,
  QuestionnaireType,
  QuestionType,
} from '$api/patientList/questionnaire';
import './styles.scss';
import {usePatientQuestionnaire} from '../hooks';
import {
  generateInitialValues,
  generateQuestionnairePayload,
  generateValidationSchema,
  getErrorMessage,
  getSubQuestionnaireId,
} from '../utils';
import QuestionnaireHeader from '$components/QuestionnaireHeader';
import QuestionnaireFooter from '$components/QuestionnaireFooter';

interface LifestyleQuestionnaireProps {
  patientId: number;
  questionnaire: Question[];
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleBack: () => void;
}

const LifestyleQuestionnaire: React.FC<LifestyleQuestionnaireProps> = ({
  patientId,
  questionnaire,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleBack,
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const questionsToDisplay = questionnaire.slice(startIndex, endIndex);
  const totalPages = Math.ceil(questionnaire.length / itemsPerPage);

  const initialValues = generateInitialValues(questionnaire);
  const validationSchema = generateValidationSchema(questionsToDisplay);

  const {submitPatientQuestionnaire, disableSubmit} = usePatientQuestionnaire({
    patientId,
    historyType: QuestionnaireType.Lifestyle,
  });

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    const payload = generateQuestionnairePayload(values);
    submitPatientQuestionnaire(payload);
  };

  const handleFormSubmit = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmitQuestionnaire(values);
    },
  });

  const renderQuestion = (question: Question) => {
    switch (question.question_type) {
      case QuestionType.Radio:
        return (
          <div>
            <RadioGroup
              row
              name={`${question.id}`}
              value={formik.values[`${question.id}`] || ''}
              onChange={formik.handleChange}
              className="lifestyle-radio-group"
            >
              {question.options?.map((option: string) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            {question.sub_questions &&
              question.sub_questions.length > 0 &&
              question.sub_questions.map(subQuestion => {
                const shouldShowSubQuestion =
                  (formik.values[question.id.toString()] &&
                    subQuestion.condition === QuestionConditionType.All) ||
                  subQuestion.condition === formik.values[`${question.id}`];
                if (!shouldShowSubQuestion) return null;
                return (
                  <div className="lifestyle-sub-question-container">
                    <p>{subQuestion.question}</p>
                    <TextField
                      multiline
                      placeholder={subQuestion.placeholder || ''}
                      name={getSubQuestionnaireId({question, subQuestion})}
                      value={
                        formik.values[
                          getSubQuestionnaireId({question, subQuestion})
                        ] || ''
                      }
                      onChange={formik.handleChange}
                      className="lifestyle-text-field"
                    />
                    <p className="text-red-600 justify-center items-center">
                      {getErrorMessage(
                        formik.errors[
                          getSubQuestionnaireId({question, subQuestion})
                        ]
                      )}
                    </p>
                  </div>
                );
              })}
          </div>
        );
      default:
        return (
          <div>
            <TextField
              multiline
              name={`${question.id}`}
              value={formik.values[`${question.id}`] || ''}
              onChange={formik.handleChange}
              className="lifestyle-text-field"
              placeholder={question.placeholder || ''}
            />
            {formik.errors[question.id.toString()] && (
              <p className="text-red-600 justify-center items-center mt-2">
                {getErrorMessage(formik.errors[question.id.toString()])}
              </p>
            )}
          </div>
        );
    }
  };

  return (
    <div className="lifestyle-questionnaire">
      <QuestionnaireHeader handleBack={handleBack} />
      <div className="lifestyle-questionnaire__content">
        <main className="lifestyle-questionnaire__main">
          <div className="lifestyle-questionnaire__main-heading">
            Lifestyle questionnaire
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="lifestyle-questionnaire-grid"
          >
            <div className="question-row">
              {questionsToDisplay
                .slice(0, 2)
                .map((question: Question, index: number) => (
                  <div
                    key={question.id}
                    className="lifestyle-question-container"
                  >
                    <h3>{`${startIndex + index + 1}. ${question.question}`}</h3>
                    {renderQuestion(question)}
                  </div>
                ))}
            </div>
            <div className="question-row">
              {questionsToDisplay
                .slice(2, 4)
                .map((question: Question, index: number) => (
                  <div
                    key={question.id}
                    className="lifestyle-question-container"
                  >
                    <h3>{`${startIndex + index + 3}. ${question.question}`}</h3>
                    {renderQuestion(question)}
                  </div>
                ))}
            </div>
          </form>
        </main>
      </div>
      <QuestionnaireFooter
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleSubmit={handleFormSubmit}
        isFormValid={formik.isValid}
        disableSubmit={disableSubmit}
      />
    </div>
  );
};

export default LifestyleQuestionnaire;
