import {Button} from '@mui/material';
import {MuiOtpInput} from 'mui-one-time-password-input';
import FrontdeskButton from '$components/FrontdeskButton';
import Header from '../Header';

interface OTPVerficationComponentProps {
  otpCode?: number;
  continueDisabled: boolean;
  continueLoading: boolean;
  onContinueClick: () => void;
  onOTPChange: (code: string) => void;
  validateOTPChar: (text: string) => boolean;
}

function OTPVerficationComponent(props: OTPVerficationComponentProps) {
  const {
    otpCode,
    continueDisabled,
    continueLoading,
    onContinueClick,
    onOTPChange,
    validateOTPChar,
  } = props;

  return (
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="mb-8 px-6">
        <h1 className="font-bold text-2xl mb-3 text-secondary">
          Welcome to Eye Op patient platform!
        </h1>
        <p className="font-normal text-base text-tertiary">
          We’ll send you a verification code at your email. Please type here the
          verification number.
        </p>
      </div>
      <form className="flex-1 flex flex-col px-6">
        <div className="flex-1">
          <MuiOtpInput
            value={otpCode?.toString()}
            onChange={onOTPChange}
            validateChar={validateOTPChar}
          />
          <Button className="font-normal text-base text-tertiary underline mt-6 cursor-pointer select-none no-tap-highlight normal-case">
            Resend verification number
          </Button>
        </div>
        <div className="pb-8">
          <FrontdeskButton
            title="Continue"
            className="w-full !p-3 !font-semibold"
            disabled={continueDisabled}
            loading={continueLoading}
            onClick={onContinueClick}
          />
        </div>
      </form>
    </div>
  );
}

export default OTPVerficationComponent;
