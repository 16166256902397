import {type SxProps} from '@mui/material';

import theme from '$modules/booking/theme';

const styles: Record<string, SxProps> = {
  card: {
    display: 'flex',
    gap: 3,
    cursor: 'pointer',
    padding: theme.spacing(3),
    background: theme.palette.common.white,
    borderRadius: '0.5rem',
    boxShadow: theme.shadows[1],
    border: '2px solid transparent',
    borderColor: 'transaprent',

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 4),
    },

    '& .booking-card-icon': {
      '& svg': {
        width: '2.5rem',
        height: '2.5rem',
        color: theme.palette.primary.main,

        [theme.breakpoints.up('lg')]: {
          width: '3.75rem',
          height: '3.75rem',
        },
      },
    },

    '& .booking-card-icon.disabled': {
      '& svg': {
        color: theme.palette.text.primary,
      },
    },

    '&:hover, &:focus, &.active': {
      boxShadow: theme.shadows[2],
      border: '2px solid',
      borderColor: theme.palette.primary.main,
    },

    '& .disabled': {
      opacity: '0.6',
      color: 'unset',
    },
  },
  title: {
    fontSize: '1.125rem',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  },
};

export default styles;
