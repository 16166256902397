import {useEffect} from 'react';
import {BookingView} from '../types';
import {Box, Typography} from '@mui/material';
import {transitionScreenStyles} from '../styles';
import useHospitalInfo from '../../../hooks/useHospitalInfo';
import Loading from '$components/Loading';

interface TransitionViewProps {
  setActiveView: (view: BookingView) => void;
}

function TransitionView(props: TransitionViewProps) {
  const {setActiveView} = props;

  const {hospitalInfo, isLoading} = useHospitalInfo();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveView(BookingView.BookingSelectionView);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [setActiveView]);

  const navigateScreen = () => {
    setActiveView(BookingView.BookingSelectionView);
  };

  if (isLoading)
    return (
      <Box sx={transitionScreenStyles.loadingContainer}>
        <Loading />
      </Box>
    );

  return (
    <Box
      onClick={navigateScreen}
      style={{
        backgroundImage: `url(${hospitalInfo?.backgroundImageURL})`,
      }}
      sx={transitionScreenStyles.container}
    >
      <Box sx={transitionScreenStyles.gradientLayer}>
        <img src={hospitalInfo?.logoURL} height="10%" />
        <Box marginTop="3rem">
          <Typography sx={transitionScreenStyles.title}>
            {hospitalInfo?.title}
          </Typography>
          <Typography sx={transitionScreenStyles.description}>
            {hospitalInfo?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TransitionView;
