import {useFormik} from 'formik';
import RegisterComponent from './component';
import {PatientRegisterFormData, registerPatientFormSchema} from './schema';
import {usePatientSendOTP} from '../hooks';

function Register() {
  const {sendOtpRequest, disableSubmit, isPending} = usePatientSendOTP();

  const handleSubmit = (data: PatientRegisterFormData) => {
    sendOtpRequest(data.email);
  };

  const formik = useFormik<PatientRegisterFormData>({
    initialValues: {
      email: '',
    },
    validationSchema: registerPatientFormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <RegisterComponent
      disableSubmit={disableSubmit}
      isContinueLoading={isPending}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
    />
  );
}

export default Register;
