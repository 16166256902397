import {useState} from 'react';
import {useFormik} from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppointmentSummaryTile from '../components/AppointmentSummaryTile';
import BookingStepper from '../components/BookingStepper';
import PatientCarerDetailForm from '../components/PatientCarerDetailForm';
import Footer from '../components/Footer';
import styles, {patientCarerDetailsViewStyles} from '../styles';
import {
  BookingDataUpdater,
  BookingStepperStage,
  BookingView,
  PatientCarerDetailsViewChoices,
} from '../types';
import {
  PatientCarerDetailsFormData,
  PatientCarerDetailsValidatedFormData,
  patientCarerDetailFormSchema,
} from '../components/PatientCarerDetailForm/schema';
import useHospitalInfo from '../../../hooks/useHospitalInfo';
import Loading from '$components/Loading';
import FetchError from '$components/FetchError';
import useValidateNHSNumber from '../../../hooks/useValidateNHSNumber';
import {useValidatePostcode} from '../../booking/hooks';

interface PatientCarerDetailsViewProps {
  bookingChoices: PatientCarerDetailsViewChoices;
  setActiveView: (view: BookingView) => void;
  setBookingChoices: BookingDataUpdater;
}

function PatientCarerDetailsView(props: PatientCarerDetailsViewProps) {
  const {bookingChoices, setActiveView, setBookingChoices} = props;

  const patientCarerDetails = bookingChoices.patientCarerDetails;
  const patientDetails = patientCarerDetails?.patient;
  const carerDetails = patientCarerDetails?.carer;

  const {hospitalInfo, isLoading} = useHospitalInfo();

  const [isFormTouched, setIsFormTouched] = useState(false);

  const handleNHSValidationSuccess = () => {
    const values = formik.values as PatientCarerDetailsValidatedFormData;
    handleSubmit(values);
  };

  const {
    validateNHSNumber,
    isLoading: isValidateNHSNumberLoading,
    isError: isValidateNHSError,
  } = useValidateNHSNumber(handleNHSValidationSuccess);

  const handlePostcodeValidationSuccess = () => {
    const values = formik.values as PatientCarerDetailsValidatedFormData;
    handleSubmit(values);
  };

  const {
    validatePostcode,
    isLoading: isValidatePostcodeLoading,
    isError: isPostcodeError,
  } = useValidatePostcode(handlePostcodeValidationSuccess);

  const handleSubmit = (values: PatientCarerDetailsValidatedFormData) => {
    const payload: PatientCarerDetailsValidatedFormData = {
      patient: values.patient,
      isCarerDifferent: values.isCarerDifferent,
    };

    if (values.isCarerDifferent) {
      payload.carer = values.carer;
    }

    setBookingChoices(draft => {
      draft.patientCarerDetails = payload;
    });

    setActiveView(BookingView.GPOrOpticianReferralView);
  };

  const handleFormikSubmit = (vals: PatientCarerDetailsFormData) => {
    const values = vals as PatientCarerDetailsValidatedFormData;

    const {nhsNumber, postcode, country} = values.patient;

    if (postcode && country) {
      validatePostcode(postcode, country);
      if (nhsNumber && !patientDetails?.nhsNumber) {
        validateNHSNumber(nhsNumber);
      }
    } else {
      handleSubmit(values);
    }
  };

  const formik = useFormik<PatientCarerDetailsFormData>({
    initialValues: {
      patient: patientDetails || {
        nhsNumber: NaN,
        title: '',
        firstName: '',
        lastName: '',
        gender: '',
        dob: null,
        address: '',
        address2: undefined,
        postcode: '',
        city: '',
        country: 'GB',
      },
      isCarerDifferent: patientCarerDetails?.isCarerDifferent,
      carer: {
        firstName: carerDetails?.firstName,
        lastName: carerDetails?.lastName,
        email: carerDetails?.email,
        phoneNumber: carerDetails?.phoneNumber || NaN,
        relationship: carerDetails?.relationship,
      },
    },
    validationSchema: patientCarerDetailFormSchema,
    onSubmit: handleFormikSubmit,
  });

  const handleBackClick = () => {
    setActiveView(BookingView.RegisterWithStoneygateView);
  };

  const handleContinueClick = () => {
    formik.submitForm();
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    setIsFormTouched(true);
  };

  let isContinueDisabled = false;
  if (!isValidateNHSNumberLoading && isValidateNHSError) {
    isContinueDisabled = true;
  }
  if (!isValidatePostcodeLoading && isPostcodeError && !isFormTouched) {
    isContinueDisabled = true;
  }

  if (isLoading) return <Loading />;
  if (!hospitalInfo) return <FetchError />;

  return (
    <Box sx={styles.sectionWrapperWithStepper} flexDirection="column" gap={5}>
      <Stack gap={5} sx={styles.section}>
        <Box>
          <BookingStepper activeStage={BookingStepperStage.PatientDetails} />
        </Box>
        <Box>
          <Typography variant="h2" sx={styles.subHeading}>
            Personal Details
          </Typography>
          <Typography
            variant="body1"
            sx={patientCarerDetailsViewStyles.subTitle}
          >
            Please fill in the details below to continue with the booking
            process
          </Typography>
        </Box>

        <PatientCarerDetailForm
          timezone={hospitalInfo.timezone}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={handleFieldChange}
          setTouched={formik.setTouched}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
        />
      </Stack>
      <Box sx={styles.footerSection}>
        <AppointmentSummaryTile choices={bookingChoices} />
        <Footer
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
          disableContinue={isContinueDisabled}
          continueLoading={
            isValidatePostcodeLoading || isValidateNHSNumberLoading
          }
        />
      </Box>
    </Box>
  );
}

export default PatientCarerDetailsView;
