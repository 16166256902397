import {FormikErrors, FormikTouched} from 'formik';
import {Button} from '@mui/material';
import EyeIcon from '$assets/svgs/eye.svg?react';
import EyeOffIcon from '$assets/svgs/eye-off.svg?react';
import FrontdeskButton from '$components/FrontdeskButton';
import Input from '$components/Input';
import Header from '../Header';
import {PatientLoginFormData} from './schema';
import {useState} from 'react';

interface LoginComponentProps {
  onForgotPassword: () => void;
  disableSubmit: boolean;
  isSignInLoading: boolean;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  values: PatientLoginFormData;
  errors: FormikErrors<PatientLoginFormData>;
  touched: FormikTouched<PatientLoginFormData>;
}

function LoginComponent(props: LoginComponentProps) {
  const {
    onForgotPassword,
    disableSubmit,
    isSignInLoading,
    handleBlur,
    handleChange,
    onSubmit,
    values,
    errors,
    touched,
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="mb-8 px-6">
        <h1 className="font-bold text-2xl mb-3 text-secondary">
          Welcome to Eye Op patient platform!
        </h1>
        <p className="font-normal text-base text-tertiary">
          Use the email and password you registered with during the booking
          process.
        </p>
      </div>
      <form className="flex-1 flex flex-col px-6">
        <div className="flex-1 flex flex-col gap-6">
          <div>
            <label className="mb-2 font-normal text-base text-tertiary">
              Email
            </label>
            <Input
              name="email"
              placeholder="Email address"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.email}
              error={Boolean(errors.email)}
              helperText={errors.email && touched.email ? errors.email : ''}
            />
          </div>
          <div>
            <label className="font-normal text-base text-tertiary">
              Password
            </label>
            <Input
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              endIcon={showPassword ? <EyeIcon /> : <EyeOffIcon />}
              endIconClick={handleClickShowPassword}
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.password}
              error={Boolean(errors.password)}
              helperText={
                errors.password && touched.password ? errors.password : ''
              }
            />
          </div>
          <div>
            <Button
              disabled={disableSubmit}
              className="normal-case primary-dark-color font-semibold underline"
              onClick={onForgotPassword}
            >
              Forgot password?
            </Button>
          </div>
        </div>
        <div className="pb-8">
          <FrontdeskButton
            title="Continue"
            disabled={disableSubmit}
            loading={isSignInLoading}
            className="w-full !p-3 !font-semibold"
            onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
}

export default LoginComponent;
